const isEmpty = (object: Object): boolean => {
  for (let key in object) {
    if (object.hasOwnProperty(key)) {
      return false;
    }
  }

  return true;
};

const unique = <T>(list: T[], callbackFn: Function): T[] =>
  // Put ts ignore because find returned value can't be undefined because id is from Set of the same array
  //@ts-ignore
  Array.from(new Set(list.map((element) => callbackFn(element)))).map((id) =>
    list.find((element) => id === callbackFn(element))
  );

export { isEmpty, unique };
