import { Account } from "./account";

export enum SocketTopic {
  Attribute = "attribute:set",
  Connect = "connect",
  Seen = "seen",
  Message = "message",
  Typing = "typing",
  RealtimeCancel = "rt-cancel",
  RealtimeAccept = "rt-accept",
  RealtimeReject = "rt-reject",
  RealtimeCall = "rt-call",
  RealtimeRinging = "rt-ringing",
  RealtimeUnavailable = "rt-unavailable",
}

export interface SocketMessage {
  account_id?: number;
  message: string;
  timestamp: number;
  user_id: number;
  conversation: {
    conversation_id: number;
    is_note: boolean;
  };
}

export interface SocketAttribute {
  user_id: number;
  name: string;
  value: string;
}

export interface SocketTyping {
  client: Account | undefined;
  conversation: {
    conversation_id: number;
    user_id: number;
    is_note: boolean;
  };
  is_typing: "y" | "n";
  typing_preview: string;
}

export interface SocketSeen {
  conversation: {
    conversation_id: number;
    user_id: number;
  };
  seen_date: number;
}

export interface SocketRealtimeCall {
  account: Account;
  url: string;
  conversation_id: number;
  user: {
    user_id: number;
  };
}

export interface SocketRealtimeCancel extends SocketRealtimeCall {}

export interface SocketRealtimeAccept extends SocketRealtimeCall {}

export interface SocketRealtimeReject extends SocketRealtimeCall {}

export interface SocketRealtimeRinging extends SocketRealtimeCall {}
