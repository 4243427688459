const availableLocales = [
  "ca",
  "cs",
  "da",
  "de",
  "fr",
  "en",
  "es",
  "id",
  "it",
  "nl",
  "pl",
  "pt",
  "pt-BR",
  "ro",
  "ru",
  "sk",
];

const autodetectLocale = (fallback: string): string => {
  const locale = navigator.language; // refer to https://www.metamodpro.com/browser-language-codes
  const primaryLocale = locale.substring(0, 2);

  const fullMatch = availableLocales.indexOf(locale) !== -1;
  const primaryMatch = availableLocales.indexOf(primaryLocale) !== -1;

  if (fullMatch) {
    return locale;
  } else if (primaryMatch) {
    return primaryLocale;
  } else {
    return fallback;
  }
};

export { autodetectLocale };
