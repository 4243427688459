import styled from "styled-components";

const PoweredByContainer = styled.div`
  width: 100% !important;
  bottom: 0 !important;
  display: flex !important;
  align-items: flex-end !important;
  color: white !important;
  justify-content: center !important;
  margin: 0 !important;
  padding: 0 !important;
`;

const PoweredBy = styled.div`
  display: flex !important;
  width: 100% !important;
  -webkit-box-pack: center !important;
  justify-content: center !important;
`;

const Link = styled.a`
  display: inline-block !important;
  font-size: 10px !important;
  color: white !important;
  padding: 0 !important;
  border-radius: 16px !important;
  text-decoration: none !important;
  opacity: 0.6 !important;
  transition: opacity 80ms ease-in-out !important;
  text-align: center !important;

  &:hover {
    opacity: 1 !important;
    transition: opacity 80ms ease-in-out !important;
  }
`;

export { PoweredByContainer, PoweredBy, Link };
