import styled from "styled-components";
import { FadeInDown } from "ui/Widget/animation.style";

const Wrapper = styled.div`
  position: absolute !important;
  top: 0 !important;
  display: block !important;
  width: 100% !important;
`;

const Alert = styled(FadeInDown)`
  margin: 0 16px !important;
  background: #ff1e1e !important;
  padding: 8px !important;
  border-radius: 18px !important;
  color: #ffffff !important;
  text-align: center !important;
  box-shadow: 0 8px 14px #00000033 !important;
  font-weight: 600 !important;
`;

export { Wrapper, Alert };
