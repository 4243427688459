import { createSideEffect } from "sonic";
import AppTypes from "AppTypes";
import { User } from "app-types/ping";
import { WidgetLogic } from "../index";

interface SendPayload {
  attribute: string;
  value: string;
  conversationId?: number;
}

const send: AppTypes.AppSideEffectCreator<SendPayload, User> = createSideEffect(
  "profiling/send",
  async (context, sendPayload: SendPayload) => {
    const response = await context.dependencies.api.profiling.send(
      sendPayload.attribute,
      sendPayload.value,
      sendPayload.conversationId
    );

    if (!response.error) {
      await context.dispatch(WidgetLogic.setUserStateUpdater(response));
    }

    return response;
  }
);

export default {
  send,
};
