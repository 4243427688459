import React, { FunctionComponent, useEffect, useRef } from "react";
import { Ping } from "app-types/ping";
import { Widget } from "app-types/widget";
import { SonicDispatchProp } from "sonic";
import { getPath } from "utils/url";
import { StatsLogic } from "logic";
import { utcNow } from "utils/date";
import { StatsPayload } from "logic/stats";
import { ApplicationState } from "state";
import { connect } from "react-redux";

interface OwnProps {}

interface StateProps {
  widget: Widget;
  ping: Ping;
}

type Props = StateProps & OwnProps & SonicDispatchProp;

const Stats: FunctionComponent<Props> = (props) => {
  const launched = useRef<boolean>(false);

  /**
     This code is not being used because we advice to customers to put customerly.load() on change page in SPAs.
     This code updates stats also on path changes in SPAs.

     This function add a proxy for pushState which is used in React to change path.


     window.history.pushState = new Proxy(window.history.pushState, {
        apply: (target: any, thisArg: any, argArray?: any) => {
            target.apply(thisArg, argArray);
            props.dispatch(StatsLogic.updateStats({
                path: getPath(),
                time: utcNow()
            } as StatsPayload));
            return;
        },
    });


     This event is called when a user click back or next in the browser.

     window.onpopstate = () => {
        props.dispatch(StatsLogic.updateStats({
            path: getPath(),
            time: utcNow()
        } as StatsPayload));
    }

     */

  useEffect(() => {
    launched.current = false;
  }, [props.widget.hotReloadIteration]);

  useEffect(() => {
    if (!launched.current) {
      launched.current = true;
      props.dispatch(
        StatsLogic.updateStats({
          path: getPath(),
          time: utcNow(),
        } as StatsPayload)
      );
    }
  });

  return null;
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  ping: state.ping,
  widget: state.widget,
});

export default connect(mapStateToProps)(Stats);
