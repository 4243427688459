import React, { FunctionComponent } from "react";
import { Notification as NotificationDiv } from "./index.style";

interface OwnProps {
  number: number;
}

interface StateProps {}

type Props = StateProps & OwnProps;

const Notification: FunctionComponent<Props> = (props) => {
  return (
    <React.Fragment>
      {props.number > 0 && <NotificationDiv>{props.number}</NotificationDiv>}
    </React.Fragment>
  );
};

export default Notification;
