import React, { FunctionComponent } from "react";
import { utcNow } from "utils/date";
import { useTranslation } from "react-i18next";
import If from "../If";

enum Unit {
  Second = "second",
  Minute = "minute",
  Hour = "hour",
  Day = "day",
  Week = "week",
  Month = "month",
  Year = "year",
}

enum Suffix {
  Ago = "Ago",
  FromNow = "FromNow",
}

const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;
const MONTH = DAY * 30;
const YEAR = DAY * 365;

/** The props of the `TimeAgo` component */
export interface TimeAgoProps {
  /** The Date to display. Timestamp of the data in seconds */
  date: number;
}

const TimeAgo: FunctionComponent<TimeAgoProps> = (props) => {
  const { t } = useTranslation();

  const now = utcNow();
  const diffSeconds = now - props.date;
  const futureDate = diffSeconds < 0;
  const suffix = futureDate ? Suffix.FromNow : Suffix.Ago;

  const [value, unit] =
    diffSeconds < MINUTE
      ? [Math.round(diffSeconds), Unit.Second]
      : diffSeconds < HOUR
      ? [Math.round(diffSeconds / MINUTE), Unit.Minute]
      : diffSeconds < DAY
      ? [Math.round(diffSeconds / HOUR), Unit.Hour]
      : diffSeconds < WEEK
      ? [Math.round(diffSeconds / DAY), Unit.Day]
      : diffSeconds < MONTH
      ? [Math.round(diffSeconds / WEEK), Unit.Week]
      : diffSeconds < YEAR
      ? [Math.round(diffSeconds / MONTH), Unit.Month]
      : [Math.round(diffSeconds / YEAR), Unit.Year];
  const justNow = value < 30 && unit === Unit.Second;

  return (
    <React.Fragment>
      <If condition={justNow}>{t(`TimeAgo.justNow`)}</If>
      <If condition={!justNow}>
        {t(`TimeAgo.${unit}`, { count: value })} {t(`TimeAgo.suffix${suffix}`)}
      </If>
    </React.Fragment>
  );
};

export default TimeAgo;
