import { Subscription } from "rxjs";
import {
  getMessageFromTriggerActionMessage,
  TriggerAction,
  TriggerActionMessage,
  TriggerEventCssSelector,
  TriggerEventCssSelectorAction,
  TriggerEventLeaveIntent,
  TriggerEventWidgetLoad,
} from "app-types/trigger";
import { Predicate, PredicateMeta } from "app-types/predicate";
import { compute as predicateCompute } from "./predicate";
import { Account } from "app-types/account";
import { setTriggerFakeMessage } from "./local-storage/fake-message-local-storage";
import { WidgetLogic } from "logic";
import { SideEffectContext } from "sonic";
import { ApplicationState } from "state";
import i18n from "i18next";
import { isDocumentValid, isDOMElementValid, isWindowValid } from "./dom";

class WidgetLoadEvent {
  subscription: Subscription;
  event: TriggerEventWidgetLoad;
  conditions: Predicate;
  predicateMeta: PredicateMeta;

  constructor(
    event: TriggerEventWidgetLoad,
    conditions: Predicate,
    predicateMeta: PredicateMeta
  ) {
    this.subscription = new Subscription();
    this.event = event;
    this.conditions = conditions;
    this.predicateMeta = predicateMeta;
  }

  promise: () => Promise<void> = () =>
    new Promise<void>((resolve, reject) => {
      const id = setTimeout(() => {
        if (predicateCompute(this.conditions, this.predicateMeta)) {
          resolve();
        } else {
          reject();
        }
      }, this.event.delay * 1000);
      this.subscription.add(() => {
        clearTimeout(id);
      });
    });

  cancel: () => void = () => this.subscription.unsubscribe();
}

class LeaveIntentEvent {
  subscription: Subscription;
  event: TriggerEventLeaveIntent;
  conditions: Predicate;
  predicateMeta: PredicateMeta;

  constructor(
    event: TriggerEventLeaveIntent,
    conditions: Predicate,
    predicateMeta: PredicateMeta
  ) {
    this.subscription = new Subscription();
    this.event = event;
    this.conditions = conditions;
    this.predicateMeta = predicateMeta;
  }

  promise: () => Promise<void> = () =>
    new Promise<void>((resolve, reject) => {
      const exitIntent = (e: MouseEvent) => {
        if (!e.relatedTarget) {
          if (predicateCompute(this.conditions, this.predicateMeta)) {
            resolve();
          } else {
            this.subscription.unsubscribe();
            reject();
          }
        }
      };
      if (isWindowValid()) {
        window.addEventListener("mouseout", exitIntent);
        this.subscription.add(() => {
          window.removeEventListener("mouseout", exitIntent);
        });
      } else {
        reject();
      }
    });

  cancel: () => void = () => this.subscription.unsubscribe();
}

class CssSelectorEvent {
  subscription: Subscription;
  event: TriggerEventCssSelector;
  conditions: Predicate;
  predicateMeta: PredicateMeta;

  constructor(
    event: TriggerEventCssSelector,
    conditions: Predicate,
    predicateMeta: PredicateMeta
  ) {
    this.subscription = new Subscription();
    this.event = event;
    this.conditions = conditions;
    this.predicateMeta = predicateMeta;
  }

  promise: () => Promise<void> = () =>
    new Promise<void>((resolve, reject) => {
      let listenerType: "mouseover" | "click";
      switch (this.event.action) {
        case TriggerEventCssSelectorAction.Hover:
          listenerType = "mouseover";
          break;
        case TriggerEventCssSelectorAction.Click:
        default:
          listenerType = "click";
          break;
      }

      let timeout: ReturnType<typeof setTimeout> | null;

      const action = () => {
        timeout = setTimeout(() => {
          if (predicateCompute(this.conditions, this.predicateMeta)) {
            resolve();
          } else {
            reject();
          }
        }, this.event.delay * 1000);
      };

      if (isDocumentValid()) {
        let element = document.querySelector(this.event.selector);
        if (element && isDOMElementValid(element)) {
          element.addEventListener(listenerType, action);

          this.subscription.add(() => {
            element && element.removeEventListener(listenerType, action);
            timeout && clearTimeout(timeout);
          });
        } else {
          reject();
        }
      } else {
        reject();
      }
    });

  cancel: () => void = () => this.subscription.unsubscribe();
}

class MessageAction {
  trigger_id: number;
  sender: Account;
  conversation_id: number;
  action: TriggerActionMessage;
  subscription: Subscription;
  context: SideEffectContext<ApplicationState, any>;
  hotReloadIterationInitial?: number;

  constructor(
    trigger_id: number,
    sender: Account,
    conversation_id: number,
    action: TriggerAction,
    context: SideEffectContext<ApplicationState, any>,
    hotReloadIterationInitial?: number
  ) {
    this.trigger_id = trigger_id;
    this.sender = sender;
    this.conversation_id = conversation_id;
    this.action = action;
    this.subscription = new Subscription();
    this.context = context;
    this.hotReloadIterationInitial = hotReloadIterationInitial;
  }

  promise: () => Promise<void> = () =>
    new Promise<void>((resolve, reject) => {
      const id = setTimeout(() => {
        const language = i18n.language;
        const triggerMessage = this.action as TriggerActionMessage;
        const localizedContent = triggerMessage.contents.filter(
          (content) => content.locale == language.substring(0, 2).toLowerCase()
        );
        const content =
          localizedContent.length > 0
            ? localizedContent[0].content
            : triggerMessage.default_content;
        const message = getMessageFromTriggerActionMessage(
          content,
          this.sender,
          this.conversation_id
        );

        if (
          this.hotReloadIterationInitial ===
          this.context.getState().widget.hotReloadIteration
        ) {
          this.context.dispatch(
            WidgetLogic.setUnreadMessageStateUpdater(message)
          );
          setTriggerFakeMessage(this.conversation_id, this.trigger_id, message);
          resolve();
        } else {
          reject();
        }
      }, this.action.delay * 1000);

      this.subscription.add(() => {
        clearTimeout(id);
      });
    });

  cancel: () => void = () => this.subscription.unsubscribe();
}

export { WidgetLoadEvent, LeaveIntentEvent, CssSelectorEvent, MessageAction };
