import styled from "styled-components";
import { ContainerModal } from "../index.style";

const zIndexGif = 10;

interface GifContainerProps {
  textareaHeight: number;
}

interface GifElementProps {
  imageUrl: string;
}

const GifContainer = styled(ContainerModal)<GifContainerProps>`
  z-index: ${zIndexGif} !important;
  bottom: 0 !important;
  height: 60% !important;
  box-shadow: 0px -10px 20px 0px #00000014 !important;
  margin-bottom: ${(props) => props.textareaHeight}px !important;

  * {
    z-index: 11 !important;
  }
`;

const GifInnerContainer = styled.div`
  position: relative !important;
`;

const GifSearch = styled.div`
  height: 50px !important;
  border-bottom: 1px solid #cecece !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  z-index: ${zIndexGif + 1} !important;

  svg {
    position: absolute !important;
    top: 13px !important;
    left: 16px !important;

    path {
      fill: #c2c2c2 !important;
    }
  }

  input {
    font-size: 14px !important;
    width: calc(100% - 48px) !important;
    border: 0 !important;
    padding: 16px 0 16px 48px !important;
  }

  input[type="text"]:focus {
    box-shadow: none !important;
    border: none !important;
  }
`;

const GifResultContainer = styled.div`
  position: relative !important;
  top: 51px !important;
  overflow: auto !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
`;

const GifResult = styled.div`
  padding: 16px !important;
  overflow: auto !important;
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
`;

const GifElementContainer = styled.span`
  cursor: pointer !important;
`;

const GifElement = styled.div<GifElementProps>`
  width: 160px !important;
  height: 100px !important;
  background-size: cover !important;
  margin: 2px !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  background: ${(props) => `url('${props.imageUrl}')`} center !important;
`;

export {
  GifContainer,
  GifInnerContainer,
  GifSearch,
  GifResultContainer,
  GifResult,
  GifElementContainer,
  GifElement,
};
