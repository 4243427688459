import React, { FunctionComponent } from "react";
import {
  BubbleContainer,
  TypingMessage,
  TypingMessageName,
} from "../index.style";
import Avatar from "components/Avatar";
import Icon, { IconType } from "components/Icon";
import { useTranslation } from "react-i18next";
import { Ping } from "app-types/ping";
import { Widget } from "app-types/widget";
import { ApplicationState } from "state";
import { connect } from "react-redux";

interface OwnProps {
  accountId?: number;
  name?: string;
}

interface StateProps {
  ping: Ping;
  widget: Widget;
}

type Props = StateProps & OwnProps;

const TypingBubble: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Avatar accountId={props.accountId} size={35} />

      <BubbleContainer isAccount={true}>
        <TypingMessage>
          <Icon
            type={IconType.Typing}
            fill={props.widget.accentColor}
            size={45}
          />
        </TypingMessage>
        <TypingMessageName>
          {t("Widget.Messenger.Conversation.Compose.body.isTyping", {
            account: props.name,
          })}
        </TypingMessageName>
      </BubbleContainer>
    </React.Fragment>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  ping: state.ping,
  widget: state.widget,
});

export default connect(mapStateToProps)(TypingBubble);
