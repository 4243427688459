import { createSideEffect, createStateUpdater } from "sonic";
import { ApplicationState } from "state";
import { Screen, UserInformation, Widget } from "app-types/widget";
import { Ping, User } from "app-types/ping";
import AppTypes from "AppTypes";
import { CallbackLogic, ConversationLogic, WidgetLogic } from "./index";
import { Survey } from "app-types/survey";
import { Message } from "app-types/message";
import { getUnreadFakeMessages } from "utils/fake-message";
import {
  deleteLocalStorageQueues,
  getAttributesQueue,
  getEventsQueue,
} from "utils/local-storage/api-queues-local-storage";

const toggle: AppTypes.AppSideEffectCreator<void, void> = createSideEffect(
  "widget/toggle",
  async (context) => {
    const state = context.getState();

    const isClosed = !state.widget.open;

    if (isClosed) {
      await context.dispatch(WidgetLogic.open(Screen.Home));
    } else {
      await context.dispatch(WidgetLogic.close());
    }

    await context.dispatch(WidgetLogic.discardAllNotifications());

    await context.dispatch(
      WidgetLogic.setNotificationsMessagesStateUpdater([])
    );
  }
);

const open: AppTypes.AppSideEffectCreator<Screen, void> = createSideEffect(
  "widget/open",
  async (context, screen) => {
    await context.dispatch(
      WidgetLogic.setWidgetStateUpdater({
        ...context.getState().widget,
        open: true,
        screen: screen,
      })
    );

    await context.dispatch(
      WidgetLogic.setPingStateUpdater({
        ...context.getState().ping,
        unread: {
          ...context.getState().ping.unread,
          surveys: [],
        },
      })
    );

    await context.dispatch(
      CallbackLogic.call({
        function: context.getState().widget.callbacks.onChatOpened,
      })
    );
  }
);

const close: AppTypes.AppSideEffectCreator<void, void> = createSideEffect(
  "widget/close",
  async (context) => {
    const state = context.getState();

    await context.dispatch(
      WidgetLogic.setWidgetStateUpdater({
        ...state.widget,
        open: false,
        screen: Screen.Home,
      })
    );

    await context.dispatch(
      WidgetLogic.setPingStateUpdater({
        ...state.ping,
        unread: {
          ...state.ping.unread,
          surveys: [],
        },
      })
    );

    await context.dispatch(
      CallbackLogic.call({ function: state.widget.callbacks.onChatClosed })
    );
  }
);

const show: AppTypes.AppSideEffectCreator<void, void> = createSideEffect(
  "widget/show",
  async (context) => {
    const state = context.getState();

    await context.dispatch(
      WidgetLogic.setWidgetStateUpdater({
        ...state.widget,
        visible: true,
      })
    );
  }
);

const hide: AppTypes.AppSideEffectCreator<void, void> = createSideEffect(
  "widget/hide",
  async (context) => {
    const state = context.getState();

    await context.dispatch(
      WidgetLogic.setWidgetStateUpdater({
        ...state.widget,
        visible: false,
      })
    );
  }
);

const setWidgetStateUpdater = createStateUpdater(
  "widget/set",
  (state: ApplicationState, widget: Widget) => {
    return {
      ...state,
      widget,
    };
  }
);

const setPingStateUpdater = createStateUpdater(
  "widget/ping",
  (state: ApplicationState, ping: Ping) => {
    return {
      ...state,
      ping,
    };
  }
);

const setUnreadSurveysStateUpdater = createStateUpdater(
  "survey/ping/unread/surveys",
  (state: ApplicationState, surveys: Survey[]) => {
    return {
      ...state,
      ping: {
        ...state.ping,
        unread: {
          ...state.ping.unread,
          surveys: surveys,
        },
      },
    };
  }
);

const setUnreadMessageStateUpdater = createStateUpdater(
  "message/ping/unread/messages",
  (state: ApplicationState, message: Message) => {
    return {
      ...state,
      ping: {
        ...state.ping,
        unread: {
          ...state.ping.unread,
          messages: [...state.ping.unread.messages, message],
        },
      },
      widget: {
        ...state.widget,
        notifications: {
          ...state.widget.notifications,
          messages: [...state.widget.notifications.messages, message],
        },
      },
    };
  }
);

const setNotificationsMessagesStateUpdater = createStateUpdater(
  "widget/notifications/messages",
  (state: ApplicationState, messages: Message[]) => {
    return {
      ...state,
      widget: {
        ...state.widget,
        notifications: {
          ...state.widget.notifications,
          messages: messages,
        },
      },
    };
  }
);

const setUserStateUpdater = createStateUpdater(
  "widget/user",
  (state: ApplicationState, user: User) => {
    return {
      ...state,
      ping: {
        ...state.ping,
        user,
      },
    };
  }
);

const setHotReloadIterationStateUpdater = createStateUpdater(
  "widget/hotReloadIteration",
  (state: ApplicationState, hotReloadIteration: number) => {
    return {
      ...state,
      widget: {
        ...state.widget,
        hotReloadIteration: hotReloadIteration,
      },
    };
  }
);

/** Loads initial data from backend */
const getPing: AppTypes.AppSideEffectCreator<
  UserInformation,
  Ping
> = createSideEffect("widget/ping/get", async (context, userInformation) => {
  const attributesQueue = getAttributesQueue();
  const eventsQueue = getEventsQueue();

  userInformation.attributes = {
    ...(userInformation.attributes || {}),
    ...(attributesQueue || {}),
  };

  userInformation.events = [
    ...(userInformation.events || []),
    ...(eventsQueue || []),
  ];

  const ping = await context.dependencies.api.ping.getAll({
    ...userInformation,
    last_page_viewed: window.location.href,
  });

  if (ping.access_token != null) {
    context.dependencies.api.ping.apiClient.accessToken = ping.access_token;
  }

  ping.unread.messages = [
    ...ping.unread.messages,
    ...getUnreadFakeMessages(),
  ].sort((a: Message, b: Message) => (a.sent_date < b.sent_date ? 1 : -1));

  await context.dispatch(setPingStateUpdater(ping));

  deleteLocalStorageQueues(); // Removing attributes and events passed to ping from localstorage

  return ping;
});

const discardAllNotifications: AppTypes.AppSideEffectCreator<
  void,
  void
> = createSideEffect("widget/discardAllNotifications", async (context) => {
  const state = context.getState();

  const conversationIds = state.widget.notifications.messages.map(
    (message) => message.conversation_id
  );
  const uniqueConversationIds = conversationIds.filter(
    (id, i) => conversationIds.indexOf(id) === i
  );

  await context.dispatch(
    ConversationLogic.discard({ conversationIds: uniqueConversationIds })
  );
});

export default {
  open,
  close,
  toggle,
  show,
  hide,
  getPing,
  discardAllNotifications,
  setUserStateUpdater,
  setWidgetStateUpdater,
  setPingStateUpdater,
  setUnreadSurveysStateUpdater,
  setUnreadMessageStateUpdater,
  setNotificationsMessagesStateUpdater,
  setHotReloadIterationStateUpdater,
};
