import ApiClient from "./api-client";
import { jsonToMessage, Message } from "app-types/message";
import { AttachmentPayload } from "logic/messenger/message";
import { Conversation, jsonToConversation } from "app-types/conversation";
import { getCookie, setCookie } from "utils/cookie";
import { newLinesToBr } from "utils/string";

export interface SendResponse {
  conversation: Conversation;
  message: Message;
  timestamp: number;
}

export interface SendFakeConversationResponse {
  conversation: Conversation;
  timestamp: number;
}

class MessageApi {
  constructor(private apiClient: ApiClient) {}

  /** Returns all the messages */
  public getAll = async (
    conversationId: number,
    messagesBeforeId?: number,
    perPage?: number
  ): Promise<Message[]> => {
    const response = await this.apiClient.post("/messages/retrieve", {
      conversation_id: conversationId,
      messages_before_id: messagesBeforeId,
      per_page: perPage,
      lead_hash: getCookie("customerly_lead_hash"),
    });

    return response.map((message: any) => jsonToMessage(message));
  };

  /** Returns all the messages after a defined timestamp for a given conversation id */
  public getNews = async (
    conversationId: number,
    timestamp: number
  ): Promise<Message[]> => {
    const response = await this.apiClient.post("/messages/news", {
      timestamp: timestamp,
      lead_hash: getCookie("customerly_lead_hash"),
    });

    return response
      .filter(
        (message: any) =>
          message.conversation_id === conversationId || conversationId == null
      )
      .map((message: any) => jsonToMessage(message));
  };

  /** Send a message */
  public send = async (
    conversationId: number | undefined,
    message: string,
    attachments: AttachmentPayload[],
    fakeConversation: Conversation | undefined
  ): Promise<SendResponse> => {
    message = newLinesToBr(message);

    const response = await this.apiClient.post("/messages/send", {
      conversation_id: conversationId,
      message: message,
      attachments: attachments,
      lead_hash: getCookie("customerly_lead_hash"),
      opened_from_page: window.location.href,
      fake_conversation: fakeConversation,
    });
    const conversation: Conversation = jsonToConversation(
      response.conversation
    );
    const sentMessage: Message = jsonToMessage(response.message);

    setCookie("customerly_lead_hash", response.lead_hash);

    return {
      conversation: conversation,
      message: sentMessage,
      timestamp: response.timestamp,
    } as SendResponse;
  };

  /** See a message */
  public seen = async (
    conversationId: number,
    conversationMessageId: number,
    timestamp: number
  ): Promise<void> => {
    await this.apiClient.post("/messages/seen", {
      conversation_id: conversationId,
      conversation_message_id: conversationMessageId,
      seen_date: timestamp,
    });
  };
}

export default MessageApi;
