import React, { FunctionComponent } from "react";
import { connect } from "react-redux";
import { Widget } from "app-types/widget";
import { ApplicationState } from "state";
import { Article } from "app-types/knowledge";
import { Ping } from "app-types/ping";
import Avatar from "components/Avatar";
import Modal from "components/Modal";
import {
  Article as ArticleDiv,
  AvatarCreator,
  Body,
  Metadata,
  NameCreator,
  Title,
  TitleLink,
} from "./index.style";
import { useTranslation } from "react-i18next";

interface OwnProps {
  selectedArticle: Article;
  showModal: boolean;
  onClose: Function;
}

interface StateProps {
  widget: Widget;
  ping: Ping;
}

type Props = StateProps & OwnProps;

const ArticleModal: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  let articleUrl =
    props.selectedArticle &&
    `${props.ping.knowledge.url}/${props.selectedArticle.collection.slug}/${props.selectedArticle.slug}`;

  return (
    <Modal
      title={"Knowledge Base"}
      showModal={props.showModal}
      onClose={props.onClose}
    >
      {props.selectedArticle && (
        <ArticleDiv>
          <Title>
            <TitleLink target="_blank" rel="noopener" href={articleUrl}>
              {props.selectedArticle.title}
            </TitleLink>
          </Title>
          <Body
            dangerouslySetInnerHTML={{ __html: props.selectedArticle.body }}
          />
          <Metadata>
            <AvatarCreator>
              <Avatar accountId={props.selectedArticle.account.account_id} />
            </AvatarCreator>
            <NameCreator>
              {t("Widget.Messenger.Knowledge.ArticleModal.writtenBy", {
                name: props.selectedArticle.account.name,
              })}
            </NameCreator>
          </Metadata>
        </ArticleDiv>
      )}
    </Modal>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  widget: state.widget,
  ping: state.ping,
});

export default connect(mapStateToProps)(ArticleModal);
