import {
  getOrNewStatsObject,
  setStatsObject,
} from "utils/local-storage/stats-local-storage";
import { emptyStatsTypeObject, StatsPage } from "app-types/stats";
import { SESSION_DURATION } from "constants/base";

const updatePage = (path: string, time: number) => {
  const statsObject = getOrNewStatsObject();

  /** Set Total stats */

  if (!statsObject.total.first_view) {
    statsObject.total.first_view = time;
  }

  statsObject.total.last_view = time;

  statsObject.total.views = statsObject.total.views
    ? statsObject.total.views + 1
    : 1;

  const localTotalPage = statsObject.total.pages[path];

  if (localTotalPage) {
    statsObject.total.pages[path] = updateStatsPage(localTotalPage, time);
  } else {
    statsObject.total.pages[path] = getNewStatsPage(time);
  }

  /** Set Session stats */

  const lastSessionView = statsObject.session.last_view;
  if (lastSessionView && time - lastSessionView > SESSION_DURATION) {
    statsObject.session = emptyStatsTypeObject;
  }

  if (!statsObject.session.first_view) {
    statsObject.session.first_view = time;
  }

  statsObject.session.last_view = time;

  statsObject.session.views = statsObject.session.views
    ? statsObject.session.views + 1
    : 1;

  const localSessionPage = statsObject.session.pages[path];

  if (localSessionPage) {
    statsObject.session.pages[path] = updateStatsPage(localSessionPage, time);
  } else {
    statsObject.session.pages[path] = getNewStatsPage(time);
  }

  setStatsObject(statsObject);
};

const updateStatsPage = (page: StatsPage, time: number): StatsPage => {
  return {
    ...page,
    views: (page.views += 1),
    last_view_at: page.current_view_at,
    current_view_at: time,
  };
};

const getNewStatsPage = (time: number): StatsPage => {
  return {
    views: 1,
    last_view_at: undefined,
    current_view_at: time,
  } as StatsPage;
};

export { updatePage };
