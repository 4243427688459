import {
  FakeConversation,
  FakeConversationType,
  TriggerFakeConversation,
} from "app-types/fakeMessage";
import {
  getItem,
  getKeysStartingWith,
  removeItem,
  setItem,
} from "./local-storage";
import {
  LOCALSTORAGE_FAKE_MESSAGE,
  LOCALSTORAGE_FAKE_MESSAGE_CONVERSATIONS,
} from "constants/local-storage";
import { Message } from "app-types/message";

const setTriggerFakeMessage = (
  conversationId: number,
  trigger_id: number,
  message: Message
): void => {
  let fakeConversation = getFakeConversation(conversationId);
  if (fakeConversation) {
    fakeConversation.messages.push(message);
  } else {
    fakeConversation = {
      conversation_id: conversationId,
      messages: [message],
      trigger_id: trigger_id,
      fake_conversation_type: FakeConversationType.Trigger,
      unread: 1,
    } as TriggerFakeConversation;
  }
  setFakeConversation(conversationId, fakeConversation);
};

const removeFakeConversation = (conversationId: number): void => {
  return removeItem(
    `${LOCALSTORAGE_FAKE_MESSAGE}/${LOCALSTORAGE_FAKE_MESSAGE_CONVERSATIONS}/${conversationId}`
  );
};

const getTriggerIdFromTriggerFakeConversation = (
  conversationId?: number
): number | undefined => {
  if (conversationId) {
    try {
      const fakeConversation = getFakeConversation(
        conversationId
      ) as TriggerFakeConversation;
      return fakeConversation.trigger_id;
    } catch (error) {
      return undefined;
    }
  } else {
    return undefined;
  }
};

const getFakeConversation = (
  conversationId: number
): FakeConversation | undefined => {
  return getItem(
    `${LOCALSTORAGE_FAKE_MESSAGE}/${LOCALSTORAGE_FAKE_MESSAGE_CONVERSATIONS}/${conversationId}`
  );
};

const setFakeConversation = (
  conversationId: number,
  fakeConversation: FakeConversation
): void => {
  setItem(
    `${LOCALSTORAGE_FAKE_MESSAGE}/${LOCALSTORAGE_FAKE_MESSAGE_CONVERSATIONS}/${conversationId}`,
    fakeConversation
  );
};

const getFakeConversations = (): FakeConversation[] => {
  const fakeConversations: FakeConversation[] = [];
  const keys = getKeysStartingWith(
    `${LOCALSTORAGE_FAKE_MESSAGE}/${LOCALSTORAGE_FAKE_MESSAGE_CONVERSATIONS}`
  ).map((key) =>
    parseInt(
      key.replace(
        `${LOCALSTORAGE_FAKE_MESSAGE}/${LOCALSTORAGE_FAKE_MESSAGE_CONVERSATIONS}/`,
        ""
      )
    )
  );
  for (const key of keys) {
    const fakeConversation = getFakeConversation(key);
    if (fakeConversation) {
      fakeConversations.push(fakeConversation);
    }
  }
  return fakeConversations;
};

export {
  setTriggerFakeMessage,
  removeFakeConversation,
  getTriggerIdFromTriggerFakeConversation,
  getFakeConversation,
  setFakeConversation,
  getFakeConversations,
};
