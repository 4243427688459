import { createSideEffect, createStateUpdater } from "sonic";
import AppTypes from "AppTypes";
import { Conversation } from "app-types/conversation";
import {
  discardFakeConversation,
  getConversationsFromFakeConversations,
} from "utils/fake-message";
import { isEmpty } from "utils/object";

export interface DiscardPayload {
  conversationIds: number[];
}

const setConversationsStateUpdater = createStateUpdater(
  "conversations/set",
  (state: AppTypes.AppState, conversations: Conversation[]) => {
    return {
      ...state,
      widget: {
        ...state.widget,
        cards: {
          ...state.widget.cards,
          conversation: {
            ...state.widget.cards.conversation,
            conversations: conversations,
          },
        },
      },
    };
  }
);

const setSelectedConversationStateUpdater = createStateUpdater(
  "conversations/set",
  (state: AppTypes.AppState, conversation: Conversation | undefined) => {
    return {
      ...state,
      widget: {
        ...state.widget,
        cards: {
          ...state.widget.cards,
          conversation: {
            ...state.widget.cards.conversation,
            selected: conversation,
          },
        },
      },
    };
  }
);

const getAll: AppTypes.AppSideEffectCreator<void, void> = createSideEffect(
  "getAll",
  async (context) => {
    try {
      const user = context.getState().ping.user;

      let conversations: Conversation[] = [];
      if (!isEmpty(user)) {
        conversations = await context.dependencies.api.conversation.getAll();
      }

      const fakeConversations = getConversationsFromFakeConversations(
        user.crmhero_user_id
      );
      conversations = conversations.concat(fakeConversations);

      conversations.sort((a: Conversation, b: Conversation) =>
        a.last_message_date < b.last_message_date ? 1 : -1
      );

      await context.dispatch(setConversationsStateUpdater(conversations));
    } catch (error) {
      await context.dispatch(setConversationsStateUpdater(error));
    }
  }
);

const getHydratedAll = async (context: any) => {
  if (context.getState().widget.cards.conversation.conversations.length === 0) {
    await context.dispatch(getAll());
  }

  if (context.getState().apps instanceof Error) {
    throw context.getState().widget.cards.conversation.conversations;
  }

  return context.getState().widget.cards.conversation.conversations || [];
};

const discard: AppTypes.AppSideEffectCreator<
  DiscardPayload,
  void
> = createSideEffect(
  "conversation/discard",
  async (context, seenPayload: DiscardPayload) => {
    try {
      const realConversationIds = seenPayload.conversationIds.filter(
        (id) => id > 0
      );
      const fakeConversationIds = seenPayload.conversationIds.filter(
        (id) => id <= 0
      );

      if (realConversationIds.length > 0) {
        return await context.dependencies.api.conversation.discard(
          realConversationIds
        );
      }

      if (fakeConversationIds.length > 0) {
        fakeConversationIds.forEach((conversationId) =>
          discardFakeConversation(conversationId)
        );
      }
    } catch (error) {}
  }
);

export default {
  getHydratedAll,
  getAll,
  setConversationsStateUpdater,
  setSelectedConversationStateUpdater,
  discard,
};
