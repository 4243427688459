import {
  Condition,
  ConditionOfficeHours,
  ConditionOfficeHoursOperation,
  ConditionPage,
  ConditionPageOperation,
  ConditionPlatform,
  ConditionPlatformOperation,
  ConditionStats,
  ConditionStatsOperation,
  ConditionTargetFrontend,
  Operation,
  OperationOperator,
  Predicate,
  PredicateMeta,
  PredicateType,
} from "app-types/predicate";
import { DevError } from "errors/dev-error";
import {
  checkHostnameExpression,
  checkPathExpression,
  getParams,
  getPath,
} from "./url";
import { Ping } from "app-types/ping";
import { isWorkingHour } from "./office-hour";
import { getStatsObject } from "utils/local-storage/stats-local-storage";
import { utcNow } from "./date";
import { isDesktop, isMobile } from "./platform";
import { isEmpty } from "./object";
import { getDomain } from "utils/domain";

export const compute = (
  predicate: Predicate,
  predicateMeta: PredicateMeta
): boolean => {
  if (isEmpty(predicate)) {
    return true;
  } else {
    switch (predicate.type) {
      case PredicateType.Condition:
        return conditionCompute(predicate as Condition, predicateMeta);
      case PredicateType.Operation:
        return operationCompute(predicate as Operation, predicateMeta);
      default:
        throw new DevError(
          "Predicate type " + predicate.type + " does not exist"
        );
    }
  }
};

const conditionCompute = (
  condition: Condition,
  predicateMeta: PredicateMeta
): boolean => {
  switch (condition.target) {
    case ConditionTargetFrontend.Page:
      return conditionPageCompute(condition as ConditionPage);
    case ConditionTargetFrontend.Stats:
      return conditionStatsCompute(condition as ConditionStats);
    case ConditionTargetFrontend.OfficeHours:
      return conditionOfficeHoursCompute(
        condition as ConditionOfficeHours,
        predicateMeta
      );
    case ConditionTargetFrontend.Platform:
      return conditionPlatformCompute(
        condition as ConditionPlatform,
        predicateMeta
      );
    default:
      throw new DevError(
        "Condition target " + condition.target + " does not exist"
      );
  }
};

const conditionPageCompute = (condition: ConditionPage): boolean => {
  switch (condition.operation) {
    case ConditionPageOperation.TimeMoreThanSeconds:
      const pageMore = getStatsObject()?.total.pages[getPath()];
      return !!(
        pageMore &&
        utcNow() - pageMore.current_view_at > parseInt(condition.value)
      );
    case ConditionPageOperation.TimeLessThanSeconds:
      const pageLess = getStatsObject()?.total.pages[getPath()];
      return !!(
        pageLess &&
        utcNow() - pageLess.current_view_at < parseInt(condition.value)
      );
    case ConditionPageOperation.UrlParameterIs:
      return condition.key
        ? getParams().get(condition.key) === condition.value
        : false;
    case ConditionPageOperation.UrlParameterIsNot:
      return condition.key
        ? getParams().get(condition.key) !== condition.value
        : false;
    case ConditionPageOperation.UrlIs:
      return checkPathExpression(getPath(), condition.value);
    case ConditionPageOperation.UrlIsNot:
      return !checkPathExpression(getPath(), condition.value);
    case ConditionPageOperation.UrlContains:
      return getPath().includes(condition.value);
    case ConditionPageOperation.UrlNotContains:
      return !getPath().includes(condition.value);
    case ConditionPageOperation.DomainIs:
      return checkHostnameExpression(getDomain(), condition.value);
    case ConditionPageOperation.DomainIsNot:
      return !checkHostnameExpression(getDomain(), condition.value);
    default:
      throw new DevError(
        "Operation " + condition.operation + " does not exist"
      );
  }
};

const conditionStatsCompute = (condition: ConditionStats): boolean => {
  const statsObject = getStatsObject();

  if (statsObject) {
    switch (condition.operation) {
      case ConditionStatsOperation.SessionPageViewsGreaterThanX:
        const pageSessionGreater = statsObject.session.pages[getPath()];

        if (pageSessionGreater) {
          return pageSessionGreater.views > parseInt(condition.value);
        } else {
          return false;
        }
      case ConditionStatsOperation.SessionPageViewsLessThanX:
        const pageSessionLess = statsObject.session.pages[getPath()];

        if (pageSessionLess) {
          return pageSessionLess.views < parseInt(condition.value);
        } else {
          return true;
        }
      case ConditionStatsOperation.TotalPageViewsGreaterThanX:
        const pageTotalGreater = statsObject.total.pages[getPath()];

        if (pageTotalGreater) {
          return pageTotalGreater.views > parseInt(condition.value);
        } else {
          return false;
        }
      case ConditionStatsOperation.TotalPageViewsLessThanX:
        const pageTotalLess = statsObject.total.pages[getPath()];

        if (pageTotalLess) {
          return pageTotalLess.views < parseInt(condition.value);
        } else {
          return true;
        }
      case ConditionStatsOperation.SessionViewsGreaterThanX:
        const sessionViewsGreater = statsObject.session.views;

        if (sessionViewsGreater) {
          return sessionViewsGreater > parseInt(condition.value);
        } else {
          return false;
        }
      case ConditionStatsOperation.SessionViewsLessThanX:
        const sessionViewsLess = statsObject.session.views;

        if (sessionViewsLess) {
          return sessionViewsLess < parseInt(condition.value);
        } else {
          return true;
        }
      case ConditionStatsOperation.TotalViewsGreaterThanX:
        const totalViewsGreater = statsObject.total.views;

        if (totalViewsGreater) {
          return totalViewsGreater > parseInt(condition.value);
        } else {
          return false;
        }
      case ConditionStatsOperation.TotalViewsLessThanX:
        const totalViewsLess = statsObject.total.views;

        if (totalViewsLess) {
          return totalViewsLess < parseInt(condition.value);
        } else {
          return true;
        }
      default:
        throw new DevError(
          "Operation " + condition.operation + " does not exist"
        );
    }
  } else {
    return false;
  }
};

const conditionOfficeHoursCompute = (
  condition: ConditionOfficeHours,
  predicateMeta: PredicateMeta
): boolean => {
  switch (condition.operation) {
    case ConditionOfficeHoursOperation.Is:
      return isWorkingHour(
        predicateMeta.office_hours_next,
        predicateMeta.reply_time
      );
    case ConditionOfficeHoursOperation.IsNot:
      return !isWorkingHour(
        predicateMeta.office_hours_next,
        predicateMeta.reply_time
      );
    default:
      throw new DevError(
        "Operation " + condition.operation + " does not exist"
      );
  }
};

const conditionPlatformCompute = (
  condition: ConditionPlatform,
  predicateMeta: PredicateMeta
): boolean => {
  switch (condition.operation) {
    case ConditionPlatformOperation.DeviceIsMobile:
      return isMobile();
    case ConditionPlatformOperation.DeviceIsDesktop:
      return isDesktop();
    default:
      throw new DevError(
        "Operation " + condition.operation + " does not exist"
      );
  }
};

const operationCompute = (
  operation: Operation,
  predicateMeta: PredicateMeta
): boolean => {
  let result: boolean | null = null;
  operation.predicates.forEach((predicate: Predicate) => {
    let predicateResult = null;
    switch (predicate.type) {
      case PredicateType.Condition:
        predicateResult = conditionCompute(
          predicate as Condition,
          predicateMeta
        );
        break;
      case PredicateType.Operation:
        predicateResult = operationCompute(
          predicate as Operation,
          predicateMeta
        );
        break;
      default:
        throw new DevError(
          "Predicate type " + predicate.type + " does not exist"
        );
    }
    if (result != null) {
      switch (operation.operator) {
        case OperationOperator.And:
          result = result && predicateResult;
          break;
        case OperationOperator.Or:
          result = result || predicateResult;
          break;
        default:
          throw new DevError(
            "Operation operator " + operation.operator + " does not exist"
          );
      }
    } else {
      result = predicateResult;
    }
  });

  return result != null ? result : false;
};

export const getPredicateMeta = (ping: Ping): PredicateMeta => {
  return {
    office_hours_next: ping.app_config.office_hours_next,
    reply_time: ping.app_config.reply_time,
  } as PredicateMeta;
};
