import { WidgetPosition } from "app-types/widget";

const DEFAULT_BOTTOM_POSITION = 18;
const DEFAULT_SIDE_POSITION = 16;

const getWidgetBottomPositionDesktop = (position: WidgetPosition) => {
  if (!position || !position.desktop || !position.desktop.bottom) {
    return DEFAULT_BOTTOM_POSITION;
  }

  return position.desktop.bottom;
};

const getWidgetBottomPositionMobile = (position: WidgetPosition) => {
  if (!position || !position.mobile || !position.mobile.bottom) {
    return DEFAULT_BOTTOM_POSITION;
  }

  return position.mobile.bottom;
};

const getWidgetSidePositionDesktop = (position: WidgetPosition) => {
  if (!position || !position.desktop || !position.desktop.side) {
    return DEFAULT_SIDE_POSITION;
  }

  return position.desktop.side;
};

const getWidgetSidePositionMobile = (position: WidgetPosition) => {
  if (!position || !position.mobile || !position.mobile.side) {
    return DEFAULT_SIDE_POSITION;
  }

  return position.mobile.side;
};

export {
  getWidgetBottomPositionDesktop,
  getWidgetBottomPositionMobile,
  getWidgetSidePositionDesktop,
  getWidgetSidePositionMobile,
};
