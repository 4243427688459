import React, { FunctionComponent, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { Widget } from "app-types/widget";
import { Survey, SurveyType } from "app-types/survey";
import { ApplicationState } from "state";
import Header from "./Header";
import { SonicDispatchProp } from "sonic";
import { Ping } from "app-types/ping";
import SurveyButtons from "./Components/Buttons";
import SurveyTextarea from "./Components/Textarea";
import SurveyRadioButtons from "./Components/RadioButtons";
import SurveyStars from "./Components/Stars";
import SurveyNumeric from "./Components/Numeric";
import { SurveyLogic } from "logic";
import {
  CloseContainer,
  Survey as SurveyDiv,
  SurveyBody,
  SurveyContainer,
  SurveyThankyou,
} from "./index.style";
import { unreadSurveySelector } from "selectors/survey";
import SurveySelect from "./Components/Select";
import SurveyTextbox from "./Components/Textbox";
import { fadeInUpAnimationName } from "../../animation.style";
import { Card } from "../../Messenger/index.style";
import PoweredBy from "./PoweredBy";
import If from "components/If";
import { Close, Notification } from "../index.style";
import Icon, { IconType } from "components/Icon";
import { useTranslation } from "react-i18next";
import SurveyScale from "./Components/Scale";
import { AudioType, play } from "utils/audio";

interface OwnProps {
  survey: Survey;
}

interface StateProps {
  widget: Widget;
  ping: Ping;
}

type Props = StateProps & OwnProps & SonicDispatchProp;

const SurveyNotification: FunctionComponent<Props> = (props) => {
  const unreadSurvey = useSelector(unreadSurveySelector);
  const isThankyouMessage =
    [
      SurveyType.Buttons,
      SurveyType.Select,
      SurveyType.Scale,
      SurveyType.Textarea,
      SurveyType.Textbox,
      SurveyType.RadioButtons,
      SurveyType.Stars,
      SurveyType.Numeric,
    ].indexOf(props.survey.next_question.type) === -1;
  const { t } = useTranslation();

  const getSurveyComponent = () => {
    switch (props.survey.next_question.type) {
      case SurveyType.Buttons:
        return <SurveyButtons survey={props.survey} onReject={onReject} />;
      case SurveyType.Select:
        return <SurveySelect survey={props.survey} onReject={onReject} />;
      case SurveyType.Textarea:
        return <SurveyTextarea survey={props.survey} onReject={onReject} />;
      case SurveyType.Textbox:
        return <SurveyTextbox survey={props.survey} onReject={onReject} />;
      case SurveyType.RadioButtons:
        return <SurveyRadioButtons survey={props.survey} onReject={onReject} />;
      case SurveyType.Stars:
        return (
          <SurveyStars
            min={1}
            max={5}
            value={4}
            survey={props.survey}
            onReject={onReject}
          />
        );
      case SurveyType.Numeric:
        return <SurveyNumeric survey={props.survey} onReject={onReject} />;
      case SurveyType.Scale:
        return <SurveyScale survey={props.survey} onReject={onReject} />;
      default:
        return null;
    }
  };

  const onReject = async () => {
    await props.dispatch(SurveyLogic.reject(props.survey.survey_id));
  };

  const onClose = async () => {
    await props.dispatch(SurveyLogic.close());
  };

  const onDiscard = async () => {
    if (!isThankyouMessage) {
      onReject();
    } else {
      onClose();
    }
  };

  useEffect(() => {
    play(AudioType.Receive);
  });

  return (
    <CSSTransition
      in={props.ping.unread.surveys.length > 0 && !props.widget.open}
      timeout={1000}
      classNames={fadeInUpAnimationName}
      appear
      unmountOnExit
    >
      <If
        condition={
          !isThankyouMessage ||
          (isThankyouMessage && unreadSurvey.thankyou_text !== "")
        }
      >
        <Notification>
          <CloseContainer>
            <Close onClick={onDiscard}>
              <Icon type={IconType.CloseFilled} fill="#000" size={20} />
              {!isThankyouMessage && (
                <span>
                  {t("Widget.Notification.SurveyNotification.discard")}
                </span>
              )}
              {isThankyouMessage && (
                <span>{t("Widget.Notification.SurveyNotification.close")}</span>
              )}
            </Close>
          </CloseContainer>

          <SurveyDiv>
            <SurveyContainer>
              {!isThankyouMessage && <Header survey={props.survey} />}
              <Card>
                <SurveyBody>
                  {getSurveyComponent() || (
                    <SurveyThankyou
                      dangerouslySetInnerHTML={{
                        __html: unreadSurvey.thankyou_text,
                      }}
                    />
                  )}
                </SurveyBody>
              </Card>

              <If condition={props.ping.app_config.branded_widget}>
                <PoweredBy />
              </If>
            </SurveyContainer>
          </SurveyDiv>
        </Notification>
      </If>
    </CSSTransition>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  widget: state.widget,
  ping: state.ping,
});

export default connect(mapStateToProps)(SurveyNotification);
