import styled from "styled-components";
import { darken, lighten } from "utils/color";

const WidgetContainer = styled.div`
  *,
  *:before,
  *:after {
    background-attachment: scroll !important;
    background-color: transparent !important;
    background-image: none !important;
    background-position: 0 0 !important;
    background-repeat: repeat !important;
    border-color: currentcolor !important;
    border-style: none !important;
    border-width: medium !important;
    bottom: auto !important;
    clear: none !important;
    clip: auto !important;
    color: inherit !important;
    counter-increment: none !important;
    counter-reset: none !important;
    cursor: auto !important;
    direction: inherit !important;
    display: block !important;
    float: none !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC",
      "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial,
      sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: inherit !important;
    font-style: inherit !important;
    font-variant: normal !important;
    font-weight: inherit !important;
    height: auto !important;
    left: auto !important;
    letter-spacing: normal !important;
    line-height: inherit !important;
    list-style-type: inherit !important;
    list-style-position: outside !important;
    list-style-image: none !important;
    margin: 0 !important;
    max-height: none !important;
    max-width: none !important;
    min-height: 0 !important;
    min-width: 0 !important;
    outline: 0 !important;
    overflow: auto !important;
    padding: 0 !important;
    position: static !important;
    quotes: "" "" !important;
    right: auto !important;
    table-layout: auto !important;
    text-align: inherit !important;
    text-decoration: inherit !important;
    text-indent: 0 !important;
    text-transform: none !important;
    text-rendering: auto !important;
    top: auto !important;
    unicode-bidi: normal !important;
    vertical-align: baseline !important;
    visibility: inherit !important;
    white-space: normal !important;
    width: auto !important;
    word-spacing: normal !important;
    z-index: auto !important;
    -webkit-background-origin: padding !important;
    -moz-background-origin: padding !important;
    -ms-background-origin: padding-box !important;
    -o-background-origin: padding-box !important;
    background-origin: padding-box !important;
    -webkit-background-clip: border !important;
    -moz-background-clip: border !important;
    background-clip: border-box !important;
    -webkit-background-size: auto !important;
    -moz-background-size: auto !important;
    -o-background-size: auto !important;
    background-size: auto !important;
    -moz-border-image: -moz--compass-list(none) !important;
    -webkit-border-image: -owg--compass-list(none) !important;
    -webkit-border-image: -webkit--compass-list(none) !important;
    -o-border-image: -o--compass-list(none) !important;
    border-image: none !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
    border-radius: 0 !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    -webkit-box-sizing: content-box !important;
    -moz-box-sizing: content-box !important;
    box-sizing: content-box !important;
    column-count: auto !important;
    column-gap: normal !important;
    column-rule: medium none #000 !important;
    column-width: auto !important;
    column-span: 1 !important;
    -webkit-text-fill-color: currentcolor !important;
    -webkit-tap-highlight-color: transparent !important;
    -moz-tap-highlight-color: transparent !important;
    -ms-tap-highlight-color: transparent !important;
    -o-tap-highlight-color: transparent !important;
    font-feature-settings: normal !important;
    overflow-x: visible !important;
    overflow-y: visible !important;
    -webkit-hyphens: manual !important;
    -moz-hyphens: manual !important;
    -ms-hyphens: manual !important;
    hyphens: manual !important;
    -webkit-hyphenate-character: "â€" !important;
    hyphenate-character: "â€" !important;
    -webkit-perspective: none !important;
    -moz-perspective: none !important;
    -ms-perspective: none !important;
    -o-perspective: none !important;
    perspective: none !important;
    -webkit-perspective-origin: 50% 50% !important;
    -moz-perspective-origin: 50% 50% !important;
    -ms-perspective-origin: 50% 50% !important;
    -o-perspective-origin: 50% 50% !important;
    perspective-origin: 50% 50% !important;
    -webkit-backface-visibility: visible !important;
    -moz-backface-visibility: visible !important;
    -ms-backface-visibility: visible !important;
    -o-backface-visibility: visible !important;
    backface-visibility: visible !important;
    text-shadow: none !important;
    -webkit-transition: all 0s ease !important;
    -webkit-transition-delay: 0s !important;
    -moz-transition: all 0s ease 0s !important;
    -o-transition: all 0s ease 0s !important;
    transition: all 0s ease 0s !important;
    word-break: normal !important;
  }

  line-height: 1.4em !important;
  direction: ltr !important;
  text-align: left !important;
  color: #282828 !important;
  font-style: normal !important;
  font-weight: 400 !important;
  text-decoration: none !important;
  list-style-type: disc !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC",
    "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  background: #fff;
  font-size: 14px;

  h1 {
    font-size: 2.3em !important;
    line-height: 1.2em !important;
  }

  h2 {
    font-size: 1.5em !important;
  }

  h3 {
    font-size: 1.17em !important;
  }

  h4 {
    font-size: 1em !important;
  }

  h5 {
    font-size: 0.83em !important;
  }

  a {
    text-decoration: none !important;
    cursor: pointer !important;
    display: inline !important;
    outline: 0 !important;
    -webkit-transition: color 0.3s ease !important;
    -moz-transition: color 0.3s ease !important;
    -ms-transition: color 0.3s ease !important;
    -o-transition: color 0.3s ease !important;
    transition: color 0.3s ease !important;
  }

  /**
        img.emoji is present because Twemoji parse emojis in Wordpress and transform text in images, from version 5.7.
        
        Resources:
        - https://github.com/twitter/twemoji
        - https://github.com/WordPress/WordPress/blob/master/wp-includes/js/wp-emoji.js
        - https://github.com/WordPress/WordPress/blob/master/wp-includes/js/wp-emoji-release.min.js
    */

  img.emoji {
    height: 1em !important;
    width: 1em !important;
    margin: 0 0.05em 0 0.1em !important;
    vertical-align: -0.1em !important;
    display: inline-block !important;
  }
`;

const Ul = styled.ul`
  list-style: none !important;
  margin: 0 !important;
  padding: 0 !important;

  li {
    margin: 0 !important;
    padding: 0 !important;
  }
`;

const InputText = styled.input.attrs({
  type: "text",
})`
  -webkit-transition: box-shadow 0.3s ease-in-out, border 0.3s ease-in-out !important;
  -moz-transition: box-shadow 0.3s ease-in-out, border 0.3s ease-in-out !important;
  -ms-transition: box-shadow 0.3s ease-in-out, border 0.3s ease-in-out !important;
  -o-transition: box-shadow 0.3s ease-in-out, border 0.3s ease-in-out !important;
  outline: none !important;
  border: 1px solid #dddddd !important;

  &:focus {
    box-shadow: 0 0 5px ${(props) => props.theme.accentColor} !important;
    border: 1px solid ${(props) => props.theme.accentColor} !important;
  }

  &:disabled {
    background: #ececec !important;
  }
`;

const Range = styled.input.attrs({
  type: "range",
})`
  -webkit-appearance: none !important; /* Hides the slider so that custom slider can be made */
  width: 100% !important; /* Specific width is required for Firefox. */
  background: transparent !important; /* Otherwise white in Chrome */

  &::-webkit-slider-thumb {
    -webkit-appearance: none !important;
  }

  &:focus {
    outline: none !important; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  &::-ms-track {
    width: 100% !important;
    cursor: pointer !important;

    /* Hides the slider so custom styles can be added */
    background: transparent !important;
    border-color: transparent !important;
    color: transparent !important;
  }
`;

const Button = styled.button`
  cursor: pointer !important;
  display: flex !important;
  background: ${(props) => props.theme.accentColor} center !important;
  margin: 0 auto !important;
  border-radius: 8px !important;
  color: white !important;
  font-size: 16px !important;
  align-items: center !important;
  border: 0 !important;
  justify-content: left !important;
  padding: 0 !important;
  outline: none !important;

  * {
    cursor: pointer !important;
  }

  &:disabled {
    box-shadow: initial !important;
    cursor: initial !important;
    background: #e8e8e8 center !important;

    * {
      cursor: initial !important;
    }
  }

  &:hover:enabled {
    box-shadow: 0 6px 20px 0 #00000038 !important;
    background: ${(props) => darken(props.theme.accentColor, 20)}
      radial-gradient(
        circle,
        transparent 1%,
        ${(props) => darken(props.theme.accentColor, 20)} 1%
      )
      center/15000% !important;
    transition: background 500ms ease-in-out, box-shadow 500ms ease-in-out !important;
  }

  &:active:enabled {
    background-color: ${(props) =>
      lighten(props.theme.accentColor, 20)} !important;
    background-size: 100% !important;
    transition: background 0s !important;
  }
`;

const HeaderButton = styled.div`
  width: 48px !important;
  height: 48px !important;
  box-sizing: border-box !important;
  display: flex !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  transform: translateZ(0px) !important;
  padding: 8px !important;
  margin: 0 auto !important;
  border-radius: 50% !important;
  background-color: rgba(0, 0, 0, 0) !important;
  transition: background-color 200ms ease-in-out !important;

  * {
    cursor: pointer !important;
  }

  svg {
    margin: 0 auto !important;
    width: 40px !important;
    height: 40px !important;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1) !important;
    transition: background-color 200ms ease-in-out !important;
  }
`;

export { WidgetContainer, InputText, Ul, Range, Button, HeaderButton };
