import React, { FunctionComponent } from "react";
import { InAppMessage } from "../index.style";
import { useTranslation } from "react-i18next";
import { ApplicationState } from "state";
import { connect } from "react-redux";

interface OwnProps {
  isAccount: boolean;
}

interface StateProps {}

type Props = StateProps & OwnProps;

const KnowledgeAutoSearchStaticBubble: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <InAppMessage isAccount={true}>
        {t(
          "Widget.Messenger.Conversation.Compose.Bubble.KnowledgeBaseAutoSearchStaticBubble.foundArticles"
        )}
      </InAppMessage>
    </React.Fragment>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  widget: state.widget,
});

export default connect(mapStateToProps)(KnowledgeAutoSearchStaticBubble);
