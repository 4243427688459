import React, { FunctionComponent, useState } from "react";
import { Widget } from "app-types/widget";
import { SonicDispatchProp } from "sonic";
import { ApplicationState } from "state";
import { connect } from "react-redux";
import { Ping } from "app-types/ping";
import Icon, { IconType } from "components/Icon";
import Modal from "components/Modal";
import Iframe from "components/Iframe";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardButton,
  CardButtonContainer,
  CardButtonIcon,
  CardButtonTitle,
  CardHeader,
  CardTitle,
} from "../index.style";

interface OwnProps {}

interface StateProps {
  widget: Widget;
  ping: Ping;
}

type Props = StateProps & OwnProps & SonicDispatchProp;

const Calendly: FunctionComponent<Props> = (props) => {
  const [iframeUrl, setIframeUrl] = useState("");
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const handleCloseModal = () => setShowModal(false);

  const onBookMeeting = () => {
    setIframeUrl(
      `${props.ping.app_config.calendly_url}?hide_event_type_details=1`
    );
    setShowModal(true);
  };

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardTitle>
            {t("Widget.Messenger.Calendly.header.scheduleDemo")}
          </CardTitle>
        </CardHeader>

        <CardButtonContainer>
          <CardButton type="button" onClick={() => onBookMeeting()}>
            <CardButtonIcon>
              <Icon type={IconType.Calendar} size={24} fill="#fff" />
            </CardButtonIcon>
            <CardButtonTitle>
              {t("Widget.Messenger.Calendly.header.bookMeeting")}
            </CardButtonTitle>
          </CardButton>
        </CardButtonContainer>
      </Card>

      <Modal
        title={"Book a Meeting"}
        showModal={showModal}
        onClose={handleCloseModal}
      >
        <Iframe url={iframeUrl} />
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  widget: state.widget,
  ping: state.ping,
});

export default connect(mapStateToProps)(Calendly);
