import React, { FunctionComponent } from "react";
import CSSTransition from "react-transition-group/CSSTransition";
import Icon, { IconType } from "../Icon";
import {
  Body,
  Close,
  Container,
  Header,
  InnerWrapper,
  Modal as ModalDiv,
  Scrollable,
  Title,
} from "./index.style";
import { HeaderButton } from "ui/Widget/index.style";
import { coverUpAnimationName } from "ui/Widget/animation.style";

/** The props of the `Spinner` component */
export interface ModalProps {
  /** A condition that, when met, make so that the children of the component are rendered. Otherwise, it shows the spinner*/
  showModal: boolean;
  onClose: Function;
  title: string;
}

/**
 * Simple spinner component that allows to show the spinner if condition is not met, children otherwise
 */
const Modal: FunctionComponent<ModalProps> = (props) => {
  return (
    <CSSTransition
      in={props.showModal}
      timeout={400}
      classNames={coverUpAnimationName}
      appear
      unmountOnExit
    >
      <ModalDiv>
        <InnerWrapper>
          <Container>
            <Header>
              <Title>{props.title}</Title>
              <Close onClick={() => props.onClose()}>
                <HeaderButton>
                  <Icon type={IconType.Close} fill="#fff" size={40} />
                </HeaderButton>
              </Close>
            </Header>

            <Scrollable>
              <Body>{props.children}</Body>
            </Scrollable>
          </Container>
        </InnerWrapper>
      </ModalDiv>
    </CSSTransition>
  );
};

export default Modal;
