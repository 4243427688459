type Identifier = string;
type OptionalVideo = Video | undefined;
type OptionalIdentifier = Identifier | undefined;

enum Provider {
  Youtube = "youtube",
  Vimeo = "vimeo",
}

interface Video {
  provider: Provider;
  identifier: Identifier;
}

const parseVideoFromUrl = (url: string): OptionalVideo => {
  // - Supported YouTube URL formats:
  //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
  //   - http://youtu.be/My2FRPA3Gf8
  //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
  // - Supported Vimeo URL formats:
  //   - http://vimeo.com/25451551
  //   - http://player.vimeo.com/video/25451551
  // - Also supports relative URLs:
  //   - //player.vimeo.com/video/25451551

  const regex = url.match(
    /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/
  );

  let provider: Provider;
  let identifier: string;

  if (regex && regex.length > 5) {
    const isYoutube = regex[3].indexOf("youtu") > -1;
    const isVimeo = regex[3].indexOf("vimeo") > -1;

    identifier = regex[6];
    if (isYoutube) {
      provider = Provider.Youtube;
    } else if (isVimeo) {
      provider = Provider.Vimeo;
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }

  return {
    provider: provider,
    identifier: identifier,
  } as Video;
};

const isVimeo = (url: string): boolean => {
  const video: OptionalVideo = parseVideoFromUrl(url);

  return !!video && video.provider === Provider.Vimeo;
};

const isYoutube = (url: string): boolean => {
  const video: OptionalVideo = parseVideoFromUrl(url);

  return !!video && video.provider === Provider.Youtube;
};

const getVimeoIdentifier = (url: string): OptionalIdentifier => {
  const video: OptionalVideo = parseVideoFromUrl(url);

  return !!video && video.provider === Provider.Vimeo
    ? video.identifier
    : undefined;
};

const getYoutubeIdentifier = (url: string): OptionalIdentifier => {
  const video: OptionalVideo = parseVideoFromUrl(url);

  return !!video && video.provider === Provider.Youtube
    ? video.identifier
    : undefined;
};

export { isVimeo, getVimeoIdentifier, isYoutube, getYoutubeIdentifier };
