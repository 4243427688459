const darkColor = "#0A0B0C";
const lightColor = "#FFFFFF";

const getRGB = (color: any): [number, number, number] => {
  let test;

  if (color && color.constructor === Array && color.length === 3) {
    return [parseInt(color[0]), parseInt(color[1]), parseInt(color[2])];
  }

  test = /rgb\(\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*\)/.exec(
    color
  );
  if (test) {
    return [parseInt(test[1]), parseInt(test[2]), parseInt(test[3])];
  }

  test = /rgb\(\s*([0-9]+(?:\.[0-9]+)?)%\s*,\s*([0-9]+(?:\.[0-9]+)?)%\s*,\s*([0-9]+(?:\.[0-9]+)?)%\s*\)/.exec(
    color
  );
  if (test) {
    return [
      parseFloat(test[1]) * 2.55,
      parseFloat(test[2]) * 2.55,
      parseFloat(test[3]) * 2.55,
    ];
  }

  test = /#([a-fA-F0-9]{2})([a-fA-F0-9]{2})([a-fA-F0-9]{2})/.exec(color);
  if (test) {
    return [
      parseInt(test[1], 16),
      parseInt(test[2], 16),
      parseInt(test[3], 16),
    ];
  }

  test = /#([a-fA-F0-9])([a-fA-F0-9])([a-fA-F0-9])/.exec(color);
  if (test) {
    return [
      parseInt(test[1] + test[1], 16),
      parseInt(test[2] + test[2], 16),
      parseInt(test[3] + test[3], 16),
    ];
  }

  return [0, 0, 0];
};

const getLuminance = (color: string): number => {
  let [red, green, blue] = getRGB(color);

  if (!red || !green || !blue) {
    return 0;
  }

  return 0.2126 * red + 0.7152 * green + 0.0722 * blue;
};

const isLight = (color: string): boolean => {
  return getLuminance(color) < 160;
};

const getContrastColor = (color: string): string => {
  return isLight(color) ? lightColor : darkColor;
};

function lightenDarkenColor(color: string, luminance: number): string {
  color = String(color).replace(/[^0-9a-f]/gi, "");
  if (color.length < 6) {
    color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2];
  }

  luminance = luminance || 0;
  if (luminance > 1 || luminance < -1) {
    luminance = luminance / 100;
  }

  let rgb = "#",
    c;
  for (let i = 0; i < 3; i++) {
    c = parseInt(color.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * luminance), 255)).toString(16);
    rgb += ("00" + c).substr(c.length);
  }

  return rgb;
}

const convertToRGB = (color?: string): string | undefined => {
  return color !== undefined && color.charAt(0) !== "#" ? `#${color}` : color;
};

const isHex = (color?: string): boolean => {
  const colorWithHashtag = convertToRGB(color);
  const isHexColor = new RegExp("^#(?:[0-9a-fA-F]{3}){1,2}$");

  if (colorWithHashtag == null) {
    return false;
  }

  return !!isHexColor.test(colorWithHashtag);
};

const lighten = (color: string, amount: number): string => {
  return lightenDarkenColor(color, Math.abs(amount));
};

const darken = (color: string, amount: number): string => {
  return lightenDarkenColor(color, -Math.abs(amount));
};

const opacitizer = (color: string, amount: number): string => {
  let [red, green, blue] = getRGB(color);

  return `${red}, ${green}, ${blue}, ${amount / 100}`;
};

export {
  darkColor,
  lightColor,
  convertToRGB,
  isHex,
  getRGB,
  getLuminance,
  getContrastColor,
  darken,
  opacitizer,
  lighten,
};
