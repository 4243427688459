import { createSideEffect, createStateUpdater } from "sonic";
import AppTypes from "AppTypes";
import { Gif } from "app-types/gif";

const setGifsStateUpdater = createStateUpdater(
  "gifs/set",
  (state: AppTypes.AppState, gifs: Gif[]) => {
    return {
      ...state,
      widget: {
        ...state.widget,
        cards: {
          ...state.widget.cards,
          compose: {
            ...state.widget.cards.compose,
            gifs: gifs,
          },
        },
      },
    };
  }
);

const getAll: AppTypes.AppSideEffectCreator<string, void> = createSideEffect(
  "messages/getAll",
  async (context, keyword: string) => {
    try {
      const messages = await context.dependencies.api.gif.getAll(keyword);

      await context.dispatch(setGifsStateUpdater(messages));
    } catch (error) {
      await context.dispatch(setGifsStateUpdater(error));
    }
  }
);

export default {
  getAll,
};
