import React, { FunctionComponent, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Widget } from "app-types/widget";
import { ApplicationState } from "state";
import { SonicDispatchProp } from "sonic";
import { Ping } from "app-types/ping";
import { Survey } from "app-types/survey";

import Loop from "components/Loop";
import {
  SurveyActionBar,
  SurveySubmitButton,
  SurveySubmitButtonText,
} from "../../index.style";

import { SurveyRadio, SurveyRadioIcon, SurveyRadioLabel } from "./index.style";
import SurveyBasic from "../index";
import { SurveyLogic } from "logic";
import { SurveyQuestionType } from "logic/survey";
import { useTranslation } from "react-i18next";
import Icon, { IconType } from "components/Icon";
import If from "components/If";
import Spinner from "components/Spinner";

interface OwnProps {
  survey: Survey;
  onReject: Function;
}

interface StateProps {
  widget: Widget;
  ping: Ping;
}

type Props = StateProps & OwnProps & SonicDispatchProp;

const SurveyRadioButtons: FunctionComponent<Props> = (props) => {
  const [selectedChoiceId, setSelectedChoiceId] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [currentQuestionId, setCurrentQuestionId] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    if (currentQuestionId !== props.survey.next_question.survey_question_id) {
      setCurrentQuestionId(props.survey.next_question.survey_question_id);
      setIsLoading(false);
      setSelectedChoiceId(-1);
    }
    // eslint-disable-next-line
  }, [props.survey.next_question]);

  const onClick = async (choiceId: number) => {
    setSelectedChoiceId(choiceId);
  };

  const onSubmit = async () => {
    setIsLoading(true);

    await props.dispatch(
      SurveyLogic.submitSurveyAnswer({
        surveyId: props.survey.survey_id,
        questionId: props.survey.next_question.survey_question_id,
        questionType: SurveyQuestionType.ClosedQuestion,
        choiceId: selectedChoiceId,
      })
    );
  };

  return (
    <SurveyBasic survey={props.survey} dispatch={props.dispatch}>
      <Loop items={props.survey.next_question.choices || []}>
        {(item) => (
          <SurveyRadio
            key={item.survey_choice_id}
            checked={item.survey_choice_id === selectedChoiceId}
            onClick={() => onClick(item.survey_choice_id)}
          >
            <SurveyRadioIcon
              checked={item.survey_choice_id === selectedChoiceId}
            />
            <SurveyRadioLabel>{item.value}</SurveyRadioLabel>
          </SurveyRadio>
        )}
      </Loop>

      <SurveyActionBar>
        <SurveySubmitButton
          onClick={onSubmit}
          disabled={isLoading || selectedChoiceId === -1}
        >
          <Spinner isLoading={isLoading} />
          <If condition={!isLoading}>
            <SurveySubmitButtonText>
              {t("Widget.Notification.SurveyNotification.next")}
            </SurveySubmitButtonText>
            <Icon type={IconType.ArrowRight} size={24} fill="#fff" />
          </If>
        </SurveySubmitButton>
      </SurveyActionBar>
    </SurveyBasic>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  widget: state.widget,
  ping: state.ping,
});

export default connect(mapStateToProps)(SurveyRadioButtons);
