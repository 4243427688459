import { LOCALSTORAGE_NAMESPACE, LS_S } from "constants/local-storage";
import sha256 from "fast-sha256";
import { LocalStorageItem } from "app-types/localStorage";

const getChecksum = (object: any): string => {
  const string = JSON.stringify(object) + LS_S.join();
  const u8 = new TextEncoder().encode(string);
  return btoa(sha256(u8).toString());
};

const checkChecksum = (lsItem: LocalStorageItem) => {
  return getChecksum(lsItem.value) === lsItem.checksum;
};

const getKeysStartingWith = (startingWith: string): string[] => {
  return Object.keys(localStorage)
    .filter((key) =>
      key.startsWith(`${LOCALSTORAGE_NAMESPACE}/${startingWith}`)
    )
    .map((key) => key.replace(`${LOCALSTORAGE_NAMESPACE}/`, ""));
};

const removeItem = (key: string): void => {
  localStorage.removeItem(`${LOCALSTORAGE_NAMESPACE}/${key}`);
};

const setItem = (key: string, object: any) => {
  const value = JSON.stringify(object);
  const checksum = getChecksum(value);
  const itemLs = { value: value, checksum: checksum } as LocalStorageItem;
  localStorage.setItem(
    `${LOCALSTORAGE_NAMESPACE}/${key}`,
    JSON.stringify(itemLs)
  );
};

const getItem = (key: string): any | undefined => {
  const lsString = localStorage.getItem(`${LOCALSTORAGE_NAMESPACE}/${key}`);
  let lsItem = undefined;
  try {
    lsItem = lsString ? (JSON.parse(lsString) as LocalStorageItem) : undefined;
  } catch (e) {}
  let value = undefined;
  if (lsItem && checkChecksum(lsItem)) {
    value = JSON.parse(lsItem.value);
  } else {
    removeItem(key);
  }
  return value;
};

export { getKeysStartingWith, removeItem, setItem, getItem };
