import { getWidgetURL } from "./url";

enum AudioType {
  Receive = "receive.mp3",
  Sent = "sent.mp3",
  Calling = "calling.m4r",
}

const getAssetPath = (type: AudioType): string => {
  return `${getWidgetURL()}/sounds/${type}`;
};

const play = (type: AudioType) => {
  const audio = new Audio(getAssetPath(type));

  audio.play().catch((_) => {});
};

export { AudioType, play };
