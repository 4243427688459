import {
  Attachment,
  getRandomConversationMessageId,
  Message,
} from "app-types/message";
import { isEmpty } from "./object";
import { newLinesToBr, stripHtml } from "./string";
import { utcNow } from "./date";
import { BOT_IDENTIFIER } from "constants/base";
import { AttachmentPayload } from "logic/messenger/message";
import { User } from "app-types/user";
import { Account } from "app-types/account";
import { XmlEntities } from "html-entities";

const isAccount = (message: Message): boolean => {
  return (
    !isEmpty(message.account) &&
    message.account.account_id !== undefined &&
    !isBot(message)
  );
};

const isUser = (message: Message): boolean => {
  return !isEmpty(message.user) && message.user.user_id !== undefined;
};

const isBot = (message: Message): boolean => {
  return (
    !isEmpty(message.account) && message.account.account_id === BOT_IDENTIFIER
  );
};

const isOlderThan = (message: Message, deltaSeconds: number): boolean => {
  return message.sent_date + deltaSeconds < utcNow();
};

const isNewerThan = (message: Message, deltaSeconds: number): boolean => {
  return message.sent_date + deltaSeconds >= utcNow();
};

const isOldConversationRestarted = (
  messages: Message[],
  deltaSeconds: number
): boolean => {
  // Check if there are new user messages but not new account messages
  return (
    messages.filter(
      (message) =>
        isNewerThan(message, deltaSeconds) &&
        isAccount(message) &&
        !isBot(message)
    ).length === 0 &&
    messages.filter(
      (message) => isNewerThan(message, deltaSeconds) && isUser(message)
    ).length > 0
  );
};

const isSameAccountOrBot = (
  current: Message,
  previous: Message | undefined
): boolean => {
  if (previous !== undefined) {
    const isCurrentAccountOrBot = isAccount(current) || isBot(current);
    const isPreviousAccountOrBot = isAccount(previous) || isBot(previous);

    return (
      isPreviousAccountOrBot &&
      isCurrentAccountOrBot &&
      previous.account.account_id === current.account.account_id
    );
  } else {
    return false;
  }
};

const isSameEntity = (
  current: Message,
  previous: Message | undefined
): boolean => {
  if (previous !== undefined) {
    const isCurrentUser = isUser(current);
    const isPreviousUser = isUser(previous);
    const isCurrentAccount = isAccount(current);
    const isPreviousAccount = isAccount(previous);
    const isCurrentBot = isBot(current);
    const isPreviousBot = isBot(previous);

    if (isCurrentUser && isPreviousUser) {
      return true;
    }

    if (isCurrentBot && isPreviousBot) {
      return true;
    }

    if (
      isCurrentAccount &&
      isPreviousAccount &&
      isSameAccountOrBot(current, previous)
    ) {
      return true;
    }

    return false;
  } else {
    return false;
  }
};

const joinMessages = (messages: Message[]): string => {
  return messages
    .map((message: Message) => stripHtml(message.content))
    .join(" ");
};

const abstractify = (message: string): string => {
  if (!message) {
    return "📎 Attachment";
  }

  message = message
    .replace(/<\/[a-zA-Z0-9\s]+>/g, "$& ") // Add spaces after closing tags
    .replace(/<br[\s\/]*>/g, "$& ") // Add spaces after <br/> tags
    .replace(/(<([^>]+)>)/gi, "") // Remove HTML tags
    .replace(/\s+/g, " "); // Replace multiple spaces with single space

  if (!message) {
    return "🖼 Image";
  }

  const entities = new XmlEntities();
  message = entities.decode(message);
  message = message.substr(0, 150);
  message = entities.encode(message);

  return message;
};

const getMessageFromAnonymous = (
  message: string,
  attachments?: AttachmentPayload[]
): Message => {
  return {
    abstract: "",
    account: {} as Account,
    assigner: null,
    assigner_id: null,
    assigner_type: null,
    attachments: (attachments || []).map((attachment) => {
      return {
        size: attachment.filesize,
        name: attachment.filename,
        path: "#",
      } as Attachment;
    }),
    close_conversation: false,
    content: newLinesToBr(message),
    conversation_id: 0,
    conversation_message_id: getRandomConversationMessageId(),
    discarded: false,
    in_app: true,
    mail_raw: null,
    mail_sent: false,
    rich_mail: false,
    rich_mail_link: "",
    rich_mail_token: "",
    seen_date: utcNow(),
    sent_date: utcNow(),
    subject: "",
    type: 0,
    user: {
      user_id: 0,
      name: "Anonymous",
    } as User,
  };
};

const getNotDiscardedMessages = (messages: Message[]) => {
  return messages.filter((message) => !message.discarded);
};

export {
  isAccount,
  isUser,
  isBot,
  isOlderThan,
  isNewerThan,
  isOldConversationRestarted,
  isSameAccountOrBot,
  isSameEntity,
  joinMessages,
  abstractify,
  getMessageFromAnonymous,
  getNotDiscardedMessages,
};
