import React, { FunctionComponent, useState } from "react";
import { Widget } from "app-types/widget";
import Loop from "components/Loop";
import { CallbackLogic, KnowledgeLogic } from "logic";
import If from "components/If";
import { SonicDispatchProp } from "sonic";
import { ApplicationState } from "state";
import { connect } from "react-redux";
import { Ping } from "app-types/ping";
import Icon, { IconType } from "components/Icon";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardHeader,
  Form,
  FormButton,
  FormInputText,
  Link,
} from "../index.style";
import {
  ArticleSearchDescription,
  ArticleSearchItem,
  ArticleSearchList,
  ArticleSearchResult,
  ArticleSearchSeeMore,
  ArticleSearchTitle,
  CardTitleRestricted,
} from "./index.style";
import { Article } from "app-types/knowledge";
import Spinner from "components/Spinner";

interface OwnProps {
  setSelectedArticle: Function;
  setShowModal: Function;
}

interface StateProps {
  widget: Widget;
  ping: Ping;
}

type Props = StateProps & OwnProps & SonicDispatchProp;

const Knowledge: FunctionComponent<Props> = (props) => {
  const maxArticles = 5;
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [lastSearchedKeyword, setLastSearchedKeyword] = useState("");
  const { t } = useTranslation();

  const onSearch = async (e: any) => {
    e.preventDefault();

    setIsLoading(true);
    setIsSearching(true);

    setLastSearchedKeyword(keyword);
    keyword && (await props.dispatch(KnowledgeLogic.getArticles(keyword)));

    setIsLoading(false);
  };

  const openArticle = async (article: Article) => {
    props.setSelectedArticle(article);
    props.setShowModal(true);

    props.dispatch(
      CallbackLogic.call({
        function: props.widget.callbacks.onHelpCenterArticleOpened,
        arguments: [article],
      })
    );
  };

  return (
    <React.Fragment>
      {props.ping.knowledge.url !== null && (
        <Card>
          <CardHeader>
            <CardTitleRestricted>
              {t("Widget.Messenger.Knowledge.header.findAnAnswer")}
            </CardTitleRestricted>
            <Link
              href={props.ping.knowledge.url}
              target="_blank"
              rel="noopener"
            >
              {t("Widget.Messenger.Knowledge.header.openHelpCenter")}
            </Link>
          </CardHeader>

          <Form onSubmit={onSearch}>
            <FormInputText
              type="text"
              value={keyword}
              placeholder={t("Widget.Messenger.Knowledge.body.search")}
              onSubmit={onSearch}
              onChange={(e) => setKeyword(e.target.value)}
            />
            <FormButton onClick={onSearch} value="Go">
              <Icon type={IconType.Search} fill="#ffffff" size={14} />
            </FormButton>
          </Form>

          <If condition={isSearching}>
            <Spinner isLoading={isLoading}>
              <If condition={props.widget.cards.knowledge.articles.length > 0}>
                <ArticleSearchResult>
                  {t("Widget.Messenger.Knowledge.body.searchResultsSuccess", {
                    keyword: lastSearchedKeyword,
                  })}
                </ArticleSearchResult>

                <ArticleSearchList>
                  <Loop
                    items={(props.widget.cards.knowledge.articles || []).slice(
                      0,
                      maxArticles
                    )}
                  >
                    {(item) => (
                      <li
                        onClick={() => openArticle(item)}
                        key={item.knowledge_base_article_id}
                      >
                        <ArticleSearchItem>
                          <ArticleSearchTitle>{item.title}</ArticleSearchTitle>
                          <ArticleSearchDescription>
                            {item.description}
                          </ArticleSearchDescription>
                        </ArticleSearchItem>
                      </li>
                    )}
                  </Loop>

                  <If
                    condition={
                      props.widget.cards.knowledge.articles.length > maxArticles
                    }
                  >
                    <ArticleSearchSeeMore
                      target="_blank"
                      rel="noopener"
                      href={`${props.ping.knowledge.url}?q=${lastSearchedKeyword}`}
                    >
                      <ArticleSearchItem>
                        {t("Widget.Messenger.Knowledge.body.seeMoreResults", {
                          keyword: lastSearchedKeyword,
                        })}
                      </ArticleSearchItem>
                    </ArticleSearchSeeMore>
                  </If>
                </ArticleSearchList>
              </If>

              <If
                condition={props.widget.cards.knowledge.articles.length === 0}
              >
                <ArticleSearchResult>
                  {t("Widget.Messenger.Knowledge.body.searchResultsFailed", {
                    keyword: lastSearchedKeyword,
                  })}
                </ArticleSearchResult>
              </If>
            </Spinner>
          </If>
        </Card>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  widget: state.widget,
  ping: state.ping,
});

export default connect(mapStateToProps)(Knowledge);
