import { Ping } from "app-types/ping";
import {
  LocalTrigger,
  Trigger,
  TriggerActionType,
  TriggerEventCssSelector,
  TriggerEventLeaveIntent,
  TriggerEventType,
  TriggerEventWidgetLoad,
} from "app-types/trigger";
import {
  getLocalTrigger,
  setLocalTrigger,
} from "utils/local-storage/trigger-local-storage";
import * as AppTypes from "AppTypes";
import { createSideEffect } from "sonic";
import { DevError } from "../errors/dev-error";
import { PredicateMeta } from "app-types/predicate";
import { getFakeConversationId } from "utils/fake-message";
import {
  CssSelectorEvent,
  LeaveIntentEvent,
  MessageAction,
  WidgetLoadEvent,
} from "utils/trigger";
import { utcNow } from "utils/date";
import { removeFakeConversation } from "utils/local-storage/fake-message-local-storage";
import { ConversationLogic } from "./index";

const checkSent = (triggers: Trigger[]) => {
  const notSentTriggers: Trigger[] = [];
  for (const trigger of triggers) {
    const localTrigger = getLocalTrigger(trigger.trigger_id);
    if (!(localTrigger && localTrigger.sent && !!!trigger.is_repeatable)) {
      // Check if trigger is already sent and not repeatable
      notSentTriggers.push(trigger);
    }
  }
  return notSentTriggers;
};

const getAvailable = (ping: Ping): Trigger[] => {
  const pingTriggers = ping.triggers;

  return checkSent(pingTriggers as Trigger[]);
};

export interface TriggerComputePayload {
  trigger: Trigger;
  predicateMeta: PredicateMeta;
}

const compute: AppTypes.AppSideEffectCreator<
  TriggerComputePayload,
  void
> = createSideEffect(
  "trigger/compute",
  async (context, triggerComputePayload) => {
    let triggerEvents: (
      | WidgetLoadEvent
      | LeaveIntentEvent
      | CssSelectorEvent
    )[] = [];
    const trigger = triggerComputePayload.trigger;
    const predicateMeta = triggerComputePayload.predicateMeta;
    const hotReloadIterationInitial = context.getState().widget
      .hotReloadIteration;

    for (const event of trigger.events) {
      switch (event.type) {
        case TriggerEventType.WidgetLoad:
          triggerEvents.push(
            new WidgetLoadEvent(
              event as TriggerEventWidgetLoad,
              trigger.conditions,
              predicateMeta
            )
          );
          break;
        case TriggerEventType.LeaveIntent:
          triggerEvents.push(
            new LeaveIntentEvent(
              event as TriggerEventLeaveIntent,
              trigger.conditions,
              predicateMeta
            )
          );
          break;
        case TriggerEventType.CssSelector:
          triggerEvents.push(
            new CssSelectorEvent(
              event as TriggerEventCssSelector,
              trigger.conditions,
              predicateMeta
            )
          );
          break;
        default:
          throw new DevError("TriggerEventType " + event.type + " not exists");
      }
    }
    Promise.any(triggerEvents.map((triggerEvent) => triggerEvent.promise()))
      .then(() => {
        for (const triggerEvent of triggerEvents) {
          triggerEvent.cancel();
        }

        if (
          hotReloadIterationInitial ===
          context.getState().widget.hotReloadIteration
        ) {
          let conversationId: number | undefined;

          const triggerActions = [];
          for (const action of trigger.actions) {
            switch (action.type) {
              case TriggerActionType.Message:
                if (!conversationId) {
                  conversationId = getFakeConversationId();
                }
                triggerActions.push(
                  new MessageAction(
                    trigger.trigger_id,
                    trigger.sender,
                    conversationId,
                    action,
                    context,
                    hotReloadIterationInitial
                  )
                );
                break;
              default:
                throw new DevError(
                  "Action type " + action.type + " does not exist"
                );
            }
          }

          if (conversationId) {
            const localTrigger = getLocalTrigger(trigger.trigger_id);
            if (localTrigger) {
              const oldConversationId = localTrigger.conversation_id;
              if (oldConversationId) {
                removeFakeConversation(oldConversationId);
              }
            }
          }

          Promise.all(
            triggerActions.map((triggerAction) => triggerAction.promise())
          )
            .then(() => {
              const localTrigger = {
                trigger_id: trigger.trigger_id,
                sent: true,
                sent_at: utcNow(),
                conversation_id: conversationId,
              } as LocalTrigger;

              setLocalTrigger(trigger.trigger_id, localTrigger);

              if (conversationId) {
                context.dispatch(ConversationLogic.getAll());
              }
            })
            .catch((error) => {});
        } else {
          return;
        }
      })
      .catch((error) => {});
  }
);

export default {
  getAvailable,
  compute,
};
