import React, { FunctionComponent } from "react";
import { Widget } from "app-types/widget";
import { WidgetLogic } from "logic";
import Icon from "./Icon";
import { SonicDispatchProp } from "sonic";
import Notification from "./Notification";
import { ApplicationState } from "state";
import { connect, useSelector } from "react-redux";
import { Ping } from "app-types/ping";
import { Launcher as LauncherDiv, LauncherContainer } from "./index.style";

interface OwnProps {}

interface StateProps {
  widget: Widget;
  ping: Ping;
}

type Props = StateProps & OwnProps & SonicDispatchProp;

const Launcher: FunctionComponent<Props> = (props) => {
  const selectNumberOfUnreadNotifications = useSelector<
    ApplicationState,
    number
  >((state) => state.ping.unread.messages.length);

  const onClick = async () => {
    await props.dispatch(WidgetLogic.toggle());
  };

  return (
    <LauncherContainer onClick={onClick}>
      <LauncherDiv>
        <Notification number={selectNumberOfUnreadNotifications} />
        <Icon
          accentColor={props.widget.accentColor}
          style={props.ping.app_config.widget_icon}
          contrastColor={props.widget.contrastColor}
          direction={props.widget.direction}
        />
      </LauncherDiv>
    </LauncherContainer>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  ping: state.ping,
  widget: state.widget,
});

export default connect(mapStateToProps)(Launcher);
