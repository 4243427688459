import styled from "styled-components";

export interface CloseProps {
  color: string;
}

export interface ImgProps {
  show: boolean;
}

const MediaPreviewOverlay = styled.div`
  position: fixed !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  z-index: 2147482999 !important;
  background: rgba(0, 0, 0, 0.7) !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  backdrop-filter: blur(5px) !important;
`;

const MediaPreviewImageContainer = styled.div`
  position: fixed !important;
  z-index: 2147482999 !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  border-radius: 8px !important;
  margin: auto;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: white !important;
  padding: 8px !important;
  width: auto !important;
  max-width: 90% !important;
  height: auto !important;
  max-height: 90% !important;
`;

const Close = styled.button`
  position: absolute !important;
  right: 0 !important;
  top: -36px !important;
  cursor: pointer !important;
  background: #f6f6f6 !important;
  padding: 4px 8px !important;
  border-radius: 16px !important;
  border: 1px solid #e6e6e6 !important;
  font-size: 14px !important;
  color: #6f6f6f !important;

  display: flex !important;
  align-items: center !important;

  * {
    cursor: pointer !important;
  }

  svg {
    fill: #bfbfbf !important;
  }

  span {
    margin-left: 4px !important;
    line-height: 20px !important;
  }
`;

const MediaPreviewImage = styled.img<ImgProps>`
  max-width: 100% !important;
  height: auto !important;
  width: auto !important;
  display: ${(props) => (props.show ? "block" : "none")} !important;
`;

export {
  MediaPreviewOverlay,
  MediaPreviewImageContainer,
  Close,
  MediaPreviewImage,
};
