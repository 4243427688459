export enum SurveyType {
  Buttons = 0,
  RadioButtons = 1,
  Select = 2,
  Scale = 3,
  Stars = 4,
  Numeric = 5,
  Textbox = 6,
  Textarea = 7,
}

export interface Survey {
  survey_id: number;
  account_id: number;
  seen_at: number;
  thankyou_text: string;
  next_question: SurveyQuestion;
}

export interface SurveyQuestion {
  survey_question_id: number;
  title: string;
  subtitle: string;
  type: SurveyType;
  choices?: SurveyChoice[];
  limit_from: number;
  limit_to: number;
}

export interface SurveyChoice {
  survey_choice_id: number;
  value: string;
}
