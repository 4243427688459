import { createSelector } from "reselect";
import { ApplicationState } from "state";
import { User } from "app-types/ping";
import { isEmpty } from "utils/object";

const isVisitorSelector = createSelector(
  (state: ApplicationState): User => state.ping.user,
  (user: User) => isEmpty(user)
);

const isLeadSelector = createSelector(
  (state: ApplicationState): User => state.ping.user,
  (user: User) => !isEmpty(user) && !user.is_user
);

const isUserSelector = createSelector(
  (state: ApplicationState): User => state.ping.user,
  (user: User) => !isEmpty(user) && !!user.is_user
);

const firstNameSelector = createSelector(
  (state: ApplicationState): User => state.ping.user,
  (user: User) =>
    !isEmpty(user) && !!user.name && !user.is_name_autogenerated
      ? user.name.split(" ")[0]
      : undefined
);

export { isVisitorSelector, isLeadSelector, isUserSelector, firstNameSelector };
