const jsonToGif = (jsonGif: any): Gif => {
  return {
    name: jsonGif.name,
    imageUrl: jsonGif.img,
    thumbnailUrl: jsonGif.thumb,
  } as Gif;
};

export interface Gif {
  name: string;
  imageUrl: string;
  thumbnailUrl: string;
}

export { jsonToGif };
