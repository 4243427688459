import styled from "styled-components";

const coverUpAnimationName = "an-dFGjcV";
const fadeInUpAnimationName = "an-iVPVYQ";
const fadeInDownAnimationName = "an-QGlCtfFt";

const CoverUp = styled.div`
  &.${coverUpAnimationName}-enter-done,
    &.${coverUpAnimationName}-enter-active,
    &.${coverUpAnimationName}-exit,
    &.${coverUpAnimationName}-exit-done,
    &.${coverUpAnimationName}-exit-active {
    transition: transform 400ms !important;
  }

  &.${coverUpAnimationName}-enter-done, &.${coverUpAnimationName}-enter-active {
    opacity: 1 !important;
    transform: translateY(0px) !important;
  }
`;

const FadeInUp = styled.div`
  &.${fadeInUpAnimationName}-enter-done,
    &.${fadeInUpAnimationName}-enter-active,
    &.${fadeInUpAnimationName}-exit,
    &.${fadeInUpAnimationName}-exit-done,
    &.${fadeInUpAnimationName}-exit-active {
    transition: transform 400ms, opacity 400ms !important;
  }

  &.${fadeInUpAnimationName}-enter-done,
    &.${fadeInUpAnimationName}-enter-active {
    opacity: 1 !important;
    transform: translateY(-20px) !important;

    @media (max-width: 460px) {
      transform: translateY(0px) !important;
    }
  }

  &.${fadeInUpAnimationName}-exit-done, &.${fadeInUpAnimationName}-exit-active {
    opacity: 0 !important;
    transform: translateY(0) !important;
  }
`;

const FadeInDown = styled.div`
  &.${fadeInDownAnimationName}-enter-done,
    &.${fadeInDownAnimationName}-enter-active,
    &.${fadeInDownAnimationName}-exit,
    &.${fadeInDownAnimationName}-exit-done,
    &.${fadeInDownAnimationName}-exit-active {
    transition: transform 400ms, opacity 400ms !important;
  }

  &.${fadeInDownAnimationName}-enter-done,
    &.${fadeInDownAnimationName}-enter-active {
    opacity: 1 !important;
    transform: translateY(20px) !important;

    @media (max-width: 460px) {
      transform: translateY(0px) !important;
    }
  }

  &.${fadeInDownAnimationName}-exit-done,
    &.${fadeInDownAnimationName}-exit-active {
    opacity: 0 !important;
    transform: translateY(0) !important;
  }
`;

export {
  coverUpAnimationName,
  fadeInUpAnimationName,
  fadeInDownAnimationName,
  CoverUp,
  FadeInUp,
  FadeInDown,
};
