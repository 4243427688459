import styled from "styled-components";
import { rotate } from "ui/Widget/keyframes.style";

const Spinner = styled.div`
  margin: 60px auto !important;
  font-size: 3px !important;
  position: relative !important;
  text-indent: -9999em !important;
  border-top: 1.1em solid rgba(0, 0, 0, 0.04) !important;
  border-right: 1.1em solid rgba(0, 0, 0, 0.04) !important;
  border-bottom: 1.1em solid rgba(0, 0, 0, 0.04) !important;
  border-left: 1.1em solid ${(props) => props.theme.accentColor} !important;
  -webkit-transform: translateZ(0) !important;
  -ms-transform: translateZ(0) !important;
  transform: translateZ(0) !important;
  -webkit-animation: ${rotate} 1.1s infinite linear !important;
  animation: ${rotate} 1.1s infinite linear !important;
  border-radius: 50% !important;
  width: 10em !important;
  height: 10em !important;
  display: block !important;

  &:after {
    display: block !important;
    border-radius: 50% !important;
    width: 10em !important;
    height: 10em !important;
  }
`;

export { Spinner };
