import React, { FunctionComponent, useEffect, useRef } from "react";
import { ImageMessage } from "../index.style";
import { MediaPreviewLogic } from "logic";
import { WidgetMediaPreviewType } from "app-types/widget";
import { SonicDispatchProp } from "sonic";
import { ApplicationState } from "state";
import { connect } from "react-redux";

interface OwnProps {
  isAccount: boolean;
  html: string;
  videoIdentifier: string;
}

interface StateProps {}

type Props = StateProps & OwnProps & SonicDispatchProp;

const ImageBubble: FunctionComponent<Props> = (props) => {
  const innerDiv = useRef<HTMLDivElement>(null);

  const onImgClick = async (e: Event) => {
    const target = e.target as HTMLImageElement;

    if (target) {
      await props.dispatch(
        MediaPreviewLogic.open({
          src: target.src,
          type: WidgetMediaPreviewType.Image,
        })
      );
    }
  };

  useEffect(() => {
    if (innerDiv.current) {
      const imgs = innerDiv.current.querySelectorAll("img");

      imgs.forEach((img: HTMLImageElement) => {
        img.addEventListener("click", onImgClick);
      });

      return () => {
        imgs.forEach((img) => {
          img.removeEventListener("click", onImgClick);
        });
      };
    }
  }, [innerDiv]);

  return (
    <ImageMessage
      isAccount={props.isAccount}
      dangerouslySetInnerHTML={{ __html: props.html }}
      ref={innerDiv}
    />
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({});

export default connect(mapStateToProps)(ImageBubble);
