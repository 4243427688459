import ApiClient from "./api-client";
import { User } from "app-types/ping";

const jsonToUser = (jsonUser: any) => {
  return jsonUser as User;
};

class ProfilingApi {
  constructor(private apiClient: ApiClient) {}

  /** Send the profiling data */
  public send = async (
    attribute: string,
    value: string,
    conversationId?: number
  ): Promise<User> => {
    const response = await this.apiClient.post("/profilings", {
      name: attribute,
      value: value,
      conversation_id: conversationId,
    });

    return jsonToUser(response);
  };
}

export default ProfilingApi;
