import React, { UIEventHandler, useEffect, useRef, useState } from "react";
import { ScrollToBottomContainer } from "./index.style";

/** The props of the `DateTime` component */
export interface ScrollToBottomProps<T> {
  /** The function that maps each item to a React Component */
  children: any;
  onScroll: UIEventHandler<T>;
}

let lastHeightScrollToBottom: number = 0;

const ScrollToBottom: <T>(
  props: ScrollToBottomProps<T> & React.HTMLAttributes<HTMLDivElement>
) => React.ReactElement<ScrollToBottomProps<T>> = ({
  children,
  onScroll: onScrollCustom,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [wasStickToBottom, setWasStickToBottom] = useState<boolean>(true);

  const onScroll = (e: any) => {
    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
      setWasStickToBottom(true);
    } else {
      setWasStickToBottom(false);
    }

    onScrollCustom(e);
  };

  useEffect(() => {
    if (wasStickToBottom) {
      if (containerRef.current !== null) {
        if (containerRef.current.scrollHeight !== lastHeightScrollToBottom) {
          lastHeightScrollToBottom = containerRef.current.scrollHeight;
          containerRef.current.scrollTop = lastHeightScrollToBottom;
        }
      }
    }
  }, [children]);

  return (
    <React.Fragment>
      <ScrollToBottomContainer
        onScroll={onScroll}
        {...props}
        ref={containerRef}
      >
        {children}
      </ScrollToBottomContainer>
    </React.Fragment>
  );
};

export default ScrollToBottom;
