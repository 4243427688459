import { Article } from "./knowledge";
import { Conversation } from "./conversation";
import { Gif } from "./gif";
import { Message } from "./message";

export enum Screen {
  Home = 0,
  ConversationList = 1,
  ConversationCompose = 2,
}

export enum Direction {
  Right = "right",
  Left = "left",
}

export interface Position {
  bottom: number;
  side: number;
}

export interface WidgetPosition {
  mobile: Position;
  desktop: Position;
}

export interface KnowledgeCard {
  articles: Article[];
}

export interface KnowledgeAutoSearch {
  articles: Article[];
}

export interface ConversationCard {
  conversations: Conversation[];
  selected: Conversation | undefined;
}

export interface ComposeCard {
  defaultMessage: string;
  gifs: Gif[];
  knowledge_autosearch: KnowledgeAutoSearch;
}

export interface Cards {
  knowledge: KnowledgeCard;
  conversation: ConversationCard;
  compose: ComposeCard;
}

export interface Callbacks {
  onChatClosed: Function;
  onChatOpened: Function;
  onNewConversation: Function;
  onProfilingQuestionAnswered: Function;
  onProfilingQuestionAsked: Function;
  onLeadGenerated: Function;
  onRealtimeVideoAnswered: Function;
  onRealtimeVideoRejected: Function;
  onHelpCenterArticleOpened: Function;
}

export interface UserInformation extends UserProperty, CompanyProperty {}

export interface UserProperty {
  user_id?: string | number;
  email?: string;
  email_hash?: string;
  name?: string;

  last_page_viewed: string;

  attributes?: any;
  events?: any;

  force_lead?: boolean;
}

export interface CompanyProperty {
  company?: {
    company_id: string | number;
    name?: string;

    attributes?: any;
  };
}

export interface WidgetProperty {
  direction: Direction;
  accentColor: string;
  contrastColor: string;
  autodetectLocale: boolean;
  visible: boolean;
  visibleOnMobile: boolean;
  screenshotAvailable: boolean;
  attachmentsAvailable: boolean;
  singleConversation: boolean;
  position: WidgetPosition;

  callbacks: Callbacks;

  /**
   * @deprecated in favour of accentColor
   */
  color: string;

  /**
   * @deprecated in favour of contrastColor
   */
  text_color: "black" | "white";

  /**
   * @deprecated in favour of widgetVisible
   */
  widget_visible: boolean;

  /**
   * @deprecated in favour of widgetVisibleMobile
   */
  widget_visible_mobile: boolean;

  /**
   * @deprecated in favour of autodetectLanguage
   */
  autodetect_language: boolean;

  /**
   * @deprecated in favour of screenshotAvailable
   */
  screenshot_available: boolean;

  /**
   * @deprecated in favour of attachmentsAvailable
   */
  attachments_available: boolean;
}

export interface WidgetSetting extends WidgetProperty, UserInformation {
  app_id: string;
}

export interface WidgetNotifications {
  messages: Message[];
}

export enum WidgetMediaPreviewType {
  Image = "image",
  Video = "video",
}

export interface WidgetMediaPreview {
  isVisible: boolean;
  src?: string;
  type?: WidgetMediaPreviewType;
}

export interface Widget extends WidgetSetting {
  initialized: boolean;
  debug: boolean;
  hotReloadIteration: number;
  open: boolean;
  cards: Cards;
  locale: string;
  screen: Screen;
  previousScreen: Screen;
  notifications: WidgetNotifications;
  mediaPreview: WidgetMediaPreview;
}
