import { Message } from "./message";
import { Account } from "./account";
import { Survey } from "./survey";
import { Trigger } from "./trigger";

export interface Websocket {
  token: string | undefined;
  endpoint: string;
  port: number;
}

export interface User {
  crmhero_user_id: number;
  name: string;
  email: string;
  is_user: boolean;
  spam: boolean;
  is_name_autogenerated: boolean;
  attributes: any;

  [key: string]: any;
}

export interface Knowledge {
  url: string;
}

export interface App {
  app_id: string;
  name: string;
}

export enum OfficeHourPeriod {
  Everyday = "everyday",
  Weekends = "weekends",
  Weekdays = "weekdays",
  Monday = "monday",
  Tuesday = "tuesday",
  Wednesday = "wednesday",
  Thursday = "thursday",
  Friday = "friday",
  Saturday = "saturday",
  Sunday = "sunday",
}

export enum ReplyTime {
  Minutes = 0,
  Hour = 1,
  Day = 2,
}

export enum IconStyle {
  Smile = 0,
  Default = 1,
  Headphones = 2,
  Dots = 3,
  Lifebuoy = 4,
  QuestionMark = 5,
}

export interface OfficeHour {
  period: OfficeHourPeriod;
  start_time: number;
  end_time: number;
}

export interface OfficeHourNext {
  period: OfficeHourPeriod;
  start_utc: number;
  end_utc: number;
}

export interface AppConfig {
  allow_anonymous_chat: boolean;
  autosearch_enabled: boolean;
  branded_widget: boolean;
  calendly_enabled: boolean;
  calendly_url: string;
  default_language: string;
  inbound_conversations_disabled: boolean;
  office_hours: OfficeHour[];
  office_hours_next: OfficeHourNext | undefined;
  reply_time: ReplyTime.Minutes | ReplyTime.Hour | ReplyTime.Day;
  team_intro: string;
  welcome_message_users: string;
  welcome_message_visitors: string;
  widget_icon: IconStyle;
  widget_color: string;
  widget_position: number;
  widget_privacy_url: string;
  widget_visible_out_office_hour: boolean;
  widget_visible_lead: boolean;
  widget_visible_user: boolean;
  widget_knowledge_base_enabled: boolean;
}

export interface AppState {}

export enum ProfilingType {
  String = 0,
  Number = 1,
  Date = 2,
  Boolean = 3,
  Option = 4,
}

export interface Profiling {
  attribute: string;
  description: string;
  extra?: string;
  hint: string;
  cast: ProfilingType;
  validator?: Function;
  callback?: Function;
}

export interface Conversation {}

export interface Ping {
  access_token: string;
  active_accounts: Account[];
  app: App;
  app_config: AppConfig;
  app_states: AppState[];
  knowledge: Knowledge;
  profilings: Profiling[];
  triggers: Trigger[];
  user: User;
  websocket: Websocket;
  unread: {
    messages: Message[];
    surveys: Survey[];
  };
}
