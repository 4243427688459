import { useEffect, useRef, useState } from "react";

/** This function return a value. This value will be updated every <delay> ms. */
const useThrottle = <T extends {}>(
  /** value: Value that need to be throttled */
  value: T,
  /** delay: The time, in milliseconds, when we will update the returned value */
  delay: number
): T => {
  const [throttledValue, setThrottledValue] = useState(value);
  const lastRun = useRef(Date.now());

  useEffect(() => {
    const handler = setTimeout(() => {
      if (Date.now() - lastRun.current >= delay) {
        lastRun.current = Date.now();
        setThrottledValue(value);
      }
    }, delay - (Date.now() - lastRun.current));

    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line
  }, [value]);

  return throttledValue;
};

export default useThrottle;
