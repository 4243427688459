import ApiClient from "./api-client";
import { Ping } from "app-types/ping";
import { UserInformation } from "app-types/widget";

const jsonToPing = (jsonPing: any) => {
  return jsonPing as Ping;
};

class PingApi {
  constructor(private apiClient: ApiClient) {}

  /** Returns all the ping */
  public getAll = async (userInformation: UserInformation): Promise<Ping> => {
    const response = await this.apiClient.post("/ping", userInformation);

    return jsonToPing(response);
  };
}

export default PingApi;
