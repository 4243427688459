import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { connect, useSelector } from "react-redux";
import { Widget } from "app-types/widget";
import { ApplicationState } from "state";
import { SonicDispatchProp } from "sonic";
import { Ping } from "app-types/ping";
import { ConversationLogic, NavigatorLogic } from "logic";
import Loop from "components/Loop";
import Avatar from "components/Avatar";
import Icon, { IconType } from "components/Icon";
import { isEmpty } from "utils/object";
import TimeAgo from "components/TimeAgo";
import { useTranslation } from "react-i18next";
import {
  Back,
  Header,
  List as ListDiv,
  ListItem,
  ListItemAbstract,
  ListItemAvatar,
  ListItemMessage,
  ListItemName,
  ListItems,
  ListItemsContainer,
  ListItemText,
  ListItemTime,
  NewConversation,
  NewConversationButton,
  NewConversationContainer,
  NewConversationText,
  Title,
} from "./index.style";
import { HeaderButton } from "../../../index.style";
import { ListItemTyping } from "../index.style";
import { SocketContext } from "components/Socket/SocketContext";
import { isVisitorSelector } from "selectors/user";
import { Subscription } from "rxjs";
import Spinner from "components/Spinner";
import { SocketMessage, SocketTyping } from "app-types/socket";
import { AudioType, play } from "utils/audio";
import { Typing } from "app-types/message";
import If from "components/If";

interface OwnProps {}

interface StateProps {
  widget: Widget;
  ping: Ping;
}

type Props = StateProps & OwnProps & SonicDispatchProp;

const List: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  let socketSubscriptions: Subscription[] = [];
  const socket = useContext(SocketContext);

  const [isLoading, setIsLoading] = useState(true);
  const isVisitor = useSelector(isVisitorSelector);
  const [typingOnConversation, setTypingOnConversation] = useState<
    Typing | undefined
  >(undefined);

  const onSocketMessage = (socketMessage: SocketMessage) => {
    play(AudioType.Receive);

    props.dispatch(ConversationLogic.getAll());
  };

  const onSocketTyping = (socketTyping: SocketTyping) => {
    if (socketTyping.client) {
      const isAccount = !!socketTyping.client;
      const isAccountTyping = socketTyping.is_typing === "y";

      if (!isAccount) {
        return;
      }

      if (isAccountTyping) {
        setTypingOnConversation({
          conversationId: socketTyping.conversation.conversation_id,
          account: {
            accountId: socketTyping.client.account_id,
            name: socketTyping.client.name,
          },
        });
      } else {
        setTypingOnConversation(undefined);
      }
    }
  };

  const onLoad = () => {
    const fetchConversations = async () => {
      if (!isVisitor) {
        socketSubscriptions.push(socket.onMessage(onSocketMessage));
        socketSubscriptions.push(socket.onTyping(onSocketTyping));
      }

      if (!isEmpty(props.ping.user)) {
        setIsLoading(true);
        await props.dispatch(ConversationLogic.getAll());
      }

      setIsLoading(false);
    };

    fetchConversations().catch(console.error);
  };

  const onUnload = () => {
    socketSubscriptions.forEach((subscription) => subscription.unsubscribe());
  };

  useEffect(() => {
    onLoad();

    return onUnload;
    // eslint-disable-next-line
  }, []);

  return (
    <ListDiv>
      <Header>
        <Title>
          {t("Widget.Messenger.Conversation.List.header.yourConversations")}
        </Title>
        <Back
          onClick={() => {
            props.dispatch(NavigatorLogic.goToHome());
          }}
        >
          <HeaderButton>
            <Icon type={IconType.Back} fill="#fff" size={40} />
          </HeaderButton>
        </Back>
      </Header>
      <ListItemsContainer>
        <Spinner isLoading={isLoading}>
          <ListItems>
            <Loop items={props.widget.cards.conversation.conversations}>
              {(item) => (
                <ListItem
                  key={item.conversation_id}
                  onClick={() =>
                    props.dispatch(NavigatorLogic.goToConversationCompose(item))
                  }
                >
                  <ListItemAvatar isUnread={item.unread}>
                    {!isEmpty(item.last_account || {}) ? (
                      <Avatar
                        accountId={
                          item.last_account && item.last_account.account_id
                        }
                        size={50}
                      />
                    ) : (
                      <Avatar userId={item.user_id} size={50} />
                    )}
                  </ListItemAvatar>

                  <ListItemMessage>
                    <ListItemText>
                      <ListItemName isUnread={item.unread}>
                        {!isEmpty(item.last_account || {})
                          ? item.last_account && item.last_account.name
                          : t("Widget.Messenger.Conversation.List.body.you")}
                      </ListItemName>
                      <ListItemTime>
                        <TimeAgo date={item.last_message_date} />
                      </ListItemTime>
                    </ListItemText>
                    {(!typingOnConversation ||
                      (typingOnConversation &&
                        typingOnConversation.conversationId !==
                          item.conversation_id)) && (
                      <ListItemAbstract
                        isUnread={item.unread}
                        dangerouslySetInnerHTML={{
                          __html: item.last_message_abstract,
                        }}
                      />
                    )}
                    {!!typingOnConversation &&
                      typingOnConversation.conversationId ===
                        item.conversation_id && (
                        <ListItemTyping>
                          <Icon
                            type={IconType.Typing}
                            fill={props.widget.accentColor}
                            size={15}
                          />
                          {t(
                            "Widget.Notification.MessageNotification.isTyping",
                            {
                              name:
                                typingOnConversation &&
                                typingOnConversation.account.name,
                            }
                          )}
                        </ListItemTyping>
                      )}
                  </ListItemMessage>
                </ListItem>
              )}
            </Loop>
          </ListItems>
        </Spinner>
      </ListItemsContainer>

      <If
        condition={
          !props.widget.singleConversation ||
          (props.widget.singleConversation &&
            props.widget.cards.conversation.conversations.length === 0)
        }
      >
        <NewConversationContainer>
          <NewConversation>
            <NewConversationButton
              onClick={() =>
                props.dispatch(
                  NavigatorLogic.goToConversationCompose(undefined)
                )
              }
            >
              <Icon type={IconType.New} fill="#fff" size={20} />
              <NewConversationText>
                {t("Widget.Messenger.Conversation.List.footer.startNew")}
              </NewConversationText>
            </NewConversationButton>
          </NewConversation>
        </NewConversationContainer>
      </If>
    </ListDiv>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  widget: state.widget,
  ping: state.ping,
});

export default connect(mapStateToProps)(List);
