import ApiClient from "./api-client";
import { Article } from "app-types/knowledge";
import { KNOWLEDGEBASE_AUTOSEARCH_MAX_RESULTS } from "constants/messenger/automatic-message";

const jsonToArticle = (jsonArticle: any) => {
  return jsonArticle as Article;
};

class KnowledgeApi {
  constructor(private apiClient: ApiClient) {}

  /** Returns all the apps */
  public getArticles = async (keyword: string): Promise<Article[]> => {
    const response = await this.apiClient.post(`/knowledge-base/articles`, {
      keyword: keyword,
    });

    return response.map((article: any) => jsonToArticle(article));
  };

  public getArticlesAutoSearch = async (
    keyword: string
  ): Promise<Article[]> => {
    const response = await this.apiClient.post(`/knowledge-base/search`, {
      keyword: keyword,
    });

    return response
      .map((article: any) => jsonToArticle(article))
      .slice(0, KNOWLEDGEBASE_AUTOSEARCH_MAX_RESULTS);
  };
}

export default KnowledgeApi;
