export enum StatsType {
  Total = "total",
  Session = "session",
}

export enum StatsData {
  FirstView = "first_view",
  LastView = "last_view",
  Views = "views",
}

export interface StatsPage {
  views: number;
  current_view_at: number;
  last_view_at?: number;
}

export interface StatsObject {
  total: {
    first_view?: number;
    last_view?: number;
    views?: number;
    pages: { [path: string]: StatsPage };
  };
  session: {
    first_view?: number;
    last_view?: number;
    views?: number;
    pages: { [path: string]: StatsPage };
  };
}

export const emptyStatsTypeObject = {
  first_view: undefined,
  last_view: undefined,
  views: undefined,
  pages: {},
};

export const emptyStatsObject: StatsObject = {
  total: {
    first_view: undefined,
    last_view: undefined,
    views: undefined,
    pages: {},
  },
  session: {
    first_view: undefined,
    last_view: undefined,
    views: undefined,
    pages: {},
  },
};
