import { createSonicMiddleware, createSonicReducer } from "./sonic";
import { applyMiddleware, compose, createStore } from "redux";

import dependencies from "./dependencies";
import { initialState } from "./state";

const middleware = applyMiddleware(createSonicMiddleware(dependencies));
const sonicReducer = createSonicReducer(initialState);

const anyWindow = window as any;

const composeEnhancers =
  anyWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  process.env.NODE_ENV !== "production"
    ? anyWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true })
    : compose;

export const store = createStore(sonicReducer, composeEnhancers(middleware));
