import ApiClient from "./api-client";
import { User } from "app-types/ping";

const jsonToUser = (jsonUser: any) => {
  return jsonUser as User;
};

class EventApi {
  constructor(private apiClient: ApiClient) {}

  /** Send the event data */
  public send = async (event: string): Promise<User> => {
    const response = await this.apiClient.post("/event", {
      name: event,
    });

    return jsonToUser(response);
  };
}

export default EventApi;
