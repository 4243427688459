import React, { FunctionComponent } from "react";

/** The props of the `FileSize` component */
export interface FileSizeProps {
  size: number;
}

const FileSize: FunctionComponent<FileSizeProps> = (props) => {
  let stringSize: string;

  if (props.size < 1024) {
    stringSize = `${props.size} B`;
  } else if (props.size < 1048576) {
    stringSize = `${(props.size / 1024).toFixed(1)} KB`;
  } else if (props.size < 1073741824) {
    stringSize = `${(props.size / 1048576).toFixed(2)} MB`;
  } else {
    stringSize = `${(props.size / 1073741824).toFixed(3)} GB`;
  }

  return <React.Fragment>{stringSize}</React.Fragment>;
};

export default FileSize;
