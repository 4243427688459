import { User } from "./user";
import { Account } from "./account";
import { Profiling } from "./ping";
import { Article } from "./knowledge";
import { utcNowMs } from "utils/date";

const jsonToMessage = (jsonMessage: any): Message => {
  return jsonMessage as Message;
};

const getRandomConversationMessageId = (): number => {
  return Math.floor(Math.random() * 10 ** 6) + utcNowMs();
};

export interface AccountTyping {
  accountId: number;
  name: string;
}

export interface Typing {
  conversationId: number;
  account: AccountTyping;
}

export interface Attachment {
  size: number;
  path: string;
  name: string;
}

export interface Message {
  abstract: string;
  account: Account;
  assigner: null;
  assigner_id: null;
  assigner_type: null;
  attachments: Attachment[];
  close_conversation: boolean;
  content: string;
  conversation_id: number;
  conversation_message_id: number;
  discarded: boolean;
  in_app: boolean;
  mail_raw: null;
  mail_sent: boolean;
  rich_mail: boolean;
  rich_mail_link: string;
  rich_mail_token: string;
  seen_date?: number;
  sent_date: number;
  subject: string;
  type: number;
  user: User;

  profiling?: Profiling;
  article?: Article;
}

export { jsonToMessage, getRandomConversationMessageId };
