import { CliMessage, CliType } from "logic/cli";
import {
  LocalStorageQueues,
  LocalStorageQueuesAttributes,
  LocalStorageQueuesEvents,
} from "app-types/localStorage";
import {
  getItem,
  removeItem,
  setItem,
} from "utils/local-storage/local-storage";
import { LOCALSTORAGE_QUEUES } from "constants/local-storage";
import { utcNow } from "utils/date";

export const getAttributesQueue = (): LocalStorageQueuesAttributes => {
  const localStorageQueues = getLocalStorageQueues();

  return localStorageQueues?.attributes || {};
};

export const getEventsQueue = (): LocalStorageQueuesEvents[] => {
  const localStorageQueues = getLocalStorageQueues();

  return localStorageQueues?.events || [];
};

export const addToQueue = (message: CliMessage) => {
  const localStorageQueues = getLocalStorageQueues();

  const attributes = localStorageQueues?.attributes || {};
  const events = localStorageQueues?.events || [];

  switch (message.type) {
    case CliType.Attribute:
      attributes[message.payload.name] = message.payload.value;
      break;
    case CliType.Event:
      events.push({
        name: message.payload.name,
        date: utcNow(),
      });
      break;
  }

  setLocalStorageQueues({
    attributes,
    events,
  });
};

const setLocalStorageQueues = (queues: LocalStorageQueues) => {
  return setItem(LOCALSTORAGE_QUEUES, queues);
};

const getLocalStorageQueues = (): LocalStorageQueues | null => {
  return getItem(LOCALSTORAGE_QUEUES) as LocalStorageQueues;
};

export const deleteLocalStorageQueues = () => {
  return removeItem(LOCALSTORAGE_QUEUES);
};
