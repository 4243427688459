import { createSideEffect, createStateUpdater } from "sonic";
import { Article } from "app-types/knowledge";
import AppTypes from "AppTypes";

const setArticlesStateUpdater = createStateUpdater(
  "articles/set",
  (state: AppTypes.AppState, articles: Article[]) => {
    return {
      ...state,
      widget: {
        ...state.widget,
        cards: {
          ...state.widget.cards,
          knowledge: {
            ...state.widget.cards.knowledge,
            articles: articles,
          },
        },
      },
    };
  }
);

const getArticles: AppTypes.AppSideEffectCreator<
  string,
  void
> = createSideEffect("getArticles", async (context, keyword: string) => {
  try {
    const articles = await context.dependencies.api.knowledge.getArticles(
      keyword
    );

    await context.dispatch(setArticlesStateUpdater(articles));
  } catch (error) {
    await context.dispatch(setArticlesStateUpdater(error));
  }
});

export default {
  getArticles,
};
