import React, { FunctionComponent } from "react";
import Icon, { IconType } from "components/Icon";
import { useTranslation } from "react-i18next";
import {
  EmailMessage,
  EmailMessageCta,
  EmailMessageIcon,
  EmailMessageText,
} from "../index.style";

interface OwnProps {
  isAccount: boolean;
  url: string;
  onOpenEmail: Function;
}

interface StateProps {}

type Props = StateProps & OwnProps;

const EmailBubble: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <EmailMessage
      isAccount={props.isAccount}
      onClick={() => props.onOpenEmail(props.url)}
    >
      <EmailMessageIcon>
        <Icon type={IconType.Email} fill="#000" size={100} />
      </EmailMessageIcon>
      <EmailMessageText>
        {t(
          "Widget.Messenger.Conversation.Compose.Bubble.EmailBubble.sentViaEmail"
        )}
      </EmailMessageText>
      <EmailMessageCta>
        {t(
          "Widget.Messenger.Conversation.Compose.Bubble.EmailBubble.openEmail"
        )}
      </EmailMessageCta>
    </EmailMessage>
  );
};

export default EmailBubble;
