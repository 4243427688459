import { Message } from "./message";

export type FakeConversation = TriggerFakeConversation;

export enum FakeConversationType {
  Trigger = "trigger",
}

interface FakeConversationAbstract {
  conversation_id: number;
  fake_conversation_type: FakeConversationType;
  messages: Message[];
  unread: number;
}

export interface TriggerFakeConversation extends FakeConversationAbstract {
  trigger_id: number;
}
