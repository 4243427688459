import styled from "styled-components";
import { Range } from "../../../../index.style";

const SurveyRangeContainer = styled.div`
  display: flex !important;
  align-items: center !important;
  margin: 8px 0 !important;
`;

const SurveyRangeLower = styled.div`
  font-size: 20px !important;
  margin: 0 10px !important;
`;
const SurveyRangeUpper = styled.div`
  font-size: 20px !important;
  margin: 0 10px !important;
`;

const SurveyRange = styled(Range)`
  &&::-webkit-slider-thumb {
    -webkit-appearance: none !important;
    border: 1px solid ${(props) => props.theme.accentColor} !important;
    height: 20px !important;
    width: 20px !important;
    border-radius: 50% !important;
    background: #ffffff !important;
    cursor: pointer !important;
    margin-top: -7px !important; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    //box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d !important; /* Add cool effects to your sliders! */
  }

  /* All the same stuff for Firefox */
  &&::-moz-range-thumb {
    //box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d !important;
    border: 1px solid ${(props) => props.theme.accentColor} !important;
    height: 20px !important;
    width: 20px !important;
    border-radius: 50% !important;
    background: #ffffff !important;
    cursor: pointer !important;
  }

  /* All the same stuff for IE */
  &&::-ms-thumb {
    //box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d !important;
    border: 1px solid ${(props) => props.theme.accentColor} !important;
    height: 20px !important;
    width: 20px !important;
    border-radius: 50% !important;
    background: #ffffff !important;
    cursor: pointer !important;
  }

  &::-webkit-slider-runnable-track {
    width: 100% !important;
    height: 8.4px !important;
    cursor: pointer !important;
    //box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d !important;
    background: ${(props) => props.theme.accentColor} !important;
    border-radius: 30px !important;
    border: 0.2px solid ${(props) => props.theme.accentColor} !important;
  }

  &:focus::-webkit-slider-runnable-track {
    background: ${(props) => props.theme.accentColor} !important;
  }

  &::-moz-range-track {
    width: 100% !important;
    height: 8.4px !important;
    cursor: pointer !important;
    //box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d !important;
    background: ${(props) => props.theme.accentColor} !important;
    border-radius: 30px !important;
    border: 0.2px solid ${(props) => props.theme.accentColor} !important;
  }

  &::-ms-track {
    width: 100% !important;
    height: 8.4px !important;
    cursor: pointer !important;
    background: transparent !important;
    border-color: transparent !important;
    border-width: 16px 0 !important;
    color: transparent !important;
  }

  &::-ms-fill-lower {
    background: ${(props) => props.theme.accentColor} !important;
    border: 0.2px solid ${(props) => props.theme.accentColor} !important;
    border-radius: 60px !important;
    //box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d !important;
  }

  &:focus::-ms-fill-lower {
    background: ${(props) => props.theme.accentColor} !important;
  }

  &::-ms-fill-upper {
    background: ${(props) => props.theme.accentColor} !important;
    border: 0.2px solid ${(props) => props.theme.accentColor} !important;
    border-radius: 60px !important;
    //box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d !important;
  }

  &:focus::-ms-fill-upper {
    background: ${(props) => props.theme.accentColor} !important;
  }
`;

const SurveyChoice = styled.span`
  text-align: center !important;
  color: #a7a7a7 !important;
  display: block !important;
`;

export {
  SurveyRangeContainer,
  SurveyRangeUpper,
  SurveyRangeLower,
  SurveyRange,
  SurveyChoice,
};
