import {
  Day,
  getDateFromTimestamp,
  getToday,
  getTomorrow,
  utcNow,
} from "./date";
import { OfficeHourNext, OfficeHourPeriod, ReplyTime } from "app-types/ping";

enum OfficeHourType {
  ReplyInMinutes = 0,
  ReplyInAnHour = 1,
  ReplyInADay = 2,
  DuringWeekends = 3,
  DuringWeekdays = 4,
  WillBackToday = 5,
  WillBackTomorrow = 6,
  WillBackOn = 7,
}

const getOfficeHour = (
  officeHoursNext: OfficeHourNext | undefined,
  replyTime: ReplyTime
): OfficeHourType => {
  const now = utcNow();

  if (officeHoursNext == null || officeHoursNext.start_utc < now) {
    if (replyTime === ReplyTime.Minutes) {
      return OfficeHourType.ReplyInMinutes;
    } else if (replyTime === ReplyTime.Hour) {
      return OfficeHourType.ReplyInAnHour;
    } else {
      return OfficeHourType.ReplyInADay;
    }
  }

  const todayLocal = getToday();
  const tomorrowLocal = getTomorrow();
  const startOfficeLocal = getDateFromTimestamp(officeHoursNext.start_utc);
  const startOfficeLocalDay = startOfficeLocal.getDay();

  let selectedPeriod = undefined;
  switch (officeHoursNext.period) {
    case OfficeHourPeriod.Weekends:
      switch (startOfficeLocalDay) {
        case Day.Saturday:
        case Day.Sunday:
          selectedPeriod = OfficeHourPeriod.Weekends;
          break;
        default:
          selectedPeriod = OfficeHourPeriod.Everyday;
          break;
      }
      break;
    case OfficeHourPeriod.Weekdays:
      switch (startOfficeLocalDay) {
        case Day.Monday:
        case Day.Tuesday:
        case Day.Wednesday:
        case Day.Thursday:
        case Day.Friday:
          selectedPeriod = OfficeHourPeriod.Weekdays;
          break;
        default:
          selectedPeriod = OfficeHourPeriod.Everyday;
          break;
      }
      break;
    default:
      selectedPeriod = OfficeHourPeriod.Everyday;
      break;
  }

  switch (selectedPeriod) {
    case OfficeHourPeriod.Weekends:
      return OfficeHourType.DuringWeekends;
    case OfficeHourPeriod.Weekdays:
      return OfficeHourType.DuringWeekdays;
    case OfficeHourPeriod.Everyday:
    default:
      if (
        startOfficeLocal.getFullYear() === todayLocal.getFullYear() &&
        startOfficeLocal.getMonth() === todayLocal.getMonth() &&
        startOfficeLocal.getDate() === todayLocal.getDate()
      ) {
        return OfficeHourType.WillBackToday;
      } else {
        if (
          startOfficeLocal.getFullYear() === tomorrowLocal.getFullYear() &&
          startOfficeLocal.getMonth() === tomorrowLocal.getMonth() &&
          startOfficeLocal.getDate() === tomorrowLocal.getDate()
        ) {
          return OfficeHourType.WillBackTomorrow;
        } else {
          return OfficeHourType.WillBackOn;
        }
      }
  }
};

const isWorkingHour = (
  officeHoursNext: OfficeHourNext | undefined,
  replyTime: ReplyTime
): boolean => {
  return (
    [
      OfficeHourType.ReplyInMinutes,
      OfficeHourType.ReplyInAnHour,
      OfficeHourType.ReplyInADay,
    ].indexOf(getOfficeHour(officeHoursNext, replyTime)) !== -1
  );
};

export { getOfficeHour, OfficeHourType, isWorkingHour };
