import { getItem, setItem } from "./local-storage/local-storage";

const getKey = (isUser: boolean) => {
  return !isUser ? "welcome-lead/sent" : "welcome-user/sent";
};

const shouldShow = (isUser: boolean): boolean => {
  const localStorageKey = getKey(isUser);

  return !Boolean(getItem(localStorageKey));
};

const markAsRead = (isUser: boolean): void => {
  const localStorageKey = getKey(isUser);

  setItem(localStorageKey, "true");
};

export { shouldShow, markAsRead };
