import { Message } from "app-types/message";

const removeUnreadMessages = (
  messages: Message[],
  conversationId: number,
  conversationMessageId: number
): Message[] => {
  const conversationMessages = messages.filter(
    (message: Message) => message.conversation_id === conversationId
  );
  const indexOfLastMessage = messages.findIndex(
    (message) => message.conversation_message_id === conversationMessageId
  );

  // Shorten the array to last message index (similar to slice operation but better performances)
  conversationMessages.length = indexOfLastMessage + 1;
  const idsToDelete = conversationMessages.map(
    (message) => message.conversation_message_id
  );

  return messages.filter(
    (message) => !idsToDelete.includes(message.conversation_message_id)
  );
};

export { removeUnreadMessages };
