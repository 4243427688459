import React, { FunctionComponent } from "react";
import { InAppMessage, KnowledgeBaseAutoSearchResult } from "../index.style";
import { ApplicationState } from "state";
import { connect } from "react-redux";
import { Article } from "app-types/knowledge";
import {
  ArticleSearchDescription,
  ArticleSearchTitle,
} from "../../../../Knowledge/index.style";
import { CallbackLogic } from "logic";
import { SonicDispatchProp } from "sonic";
import { Widget } from "app-types/widget";

interface OwnProps {
  isAccount: boolean;
  article: Article;
  onSelectedArticle: Function;
  onShowModal: Function;
}

interface StateProps {
  widget: Widget;
}

type Props = StateProps & OwnProps & SonicDispatchProp;

const KnowledgeAutoSearchResultBubble: FunctionComponent<Props> = (props) => {
  const article = props.article;

  const openArticle = async (article: Article) => {
    props.onSelectedArticle(article);
    props.onShowModal(true);

    await props.dispatch(
      CallbackLogic.call({
        function: props.widget.callbacks.onHelpCenterArticleOpened,
        arguments: [article],
      })
    );
  };

  return (
    <React.Fragment>
      <InAppMessage isAccount={true}>
        <KnowledgeBaseAutoSearchResult
          onClick={() => openArticle(article)}
          key={article.knowledge_base_article_id}
        >
          <ArticleSearchTitle>{article.title}</ArticleSearchTitle>
          <ArticleSearchDescription>
            {article.description}
          </ArticleSearchDescription>
        </KnowledgeBaseAutoSearchResult>
      </InAppMessage>
    </React.Fragment>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  widget: state.widget,
});

export default connect(mapStateToProps)(KnowledgeAutoSearchResultBubble);
