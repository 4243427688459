import React, { FunctionComponent, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Widget } from "app-types/widget";
import { ApplicationState } from "state";
import { SonicDispatchProp } from "sonic";
import { Ping } from "app-types/ping";
import { SurveyLogic } from "logic";
import { SurveyQuestionType } from "logic/survey";

import Loop from "components/Loop";
import Icon, { IconType } from "components/Icon";
import SurveyBasic from "../index";
import { Survey } from "app-types/survey";
import {
  SurveyActionBar,
  SurveySubmitButton,
  SurveySubmitButtonText,
} from "../../index.style";
import { SurveyStar, SurveyStarBox } from "./index.style";
import { useTranslation } from "react-i18next";
import If from "components/If";
import Spinner from "components/Spinner";

interface OwnProps {
  min: number;
  max: number;
  value: number;
  survey: Survey;
  onReject: Function;
}

interface StateProps {
  widget: Widget;
  ping: Ping;
}

type Props = StateProps & OwnProps & SonicDispatchProp;

const SurveyStars: FunctionComponent<Props> = (props) => {
  const [score, setScore] = useState(-1);
  const [hover, setHover] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [currentQuestionId, setCurrentQuestionId] = useState(0);

  const { t } = useTranslation();
  const maxCount = props.max - props.min + 1;

  // @ts-ignore
  const values = [...Array(maxCount).keys()];

  useEffect(() => {
    if (currentQuestionId !== props.survey.next_question.survey_question_id) {
      setCurrentQuestionId(props.survey.next_question.survey_question_id);
      setIsLoading(false);
      setScore(-1);
    }
    // eslint-disable-next-line
  }, [props.survey.next_question]);

  const onSelectStar = (index: number) => {
    setScore(index + 1);
  };

  const onSelectHover = (index: number) => {
    setHover(index + 1);
  };

  const getColor = (item: number): string => {
    let value = score;

    if (hover > 0) {
      value = hover;
    }

    return item < value ? "#F4C142" : "#949494";
  };

  const onSubmit = async () => {
    setIsLoading(true);

    await props.dispatch(
      SurveyLogic.submitSurveyAnswer({
        surveyId: props.survey.survey_id,
        questionId: props.survey.next_question.survey_question_id,
        questionType: SurveyQuestionType.OpenQuestion,
        answer: Math.floor(score).toString(),
      })
    );
  };

  return (
    <SurveyBasic survey={props.survey} dispatch={props.dispatch}>
      <SurveyStarBox>
        <Loop items={values}>
          {(item, index) => (
            <SurveyStar
              key={item}
              onClick={(e) => onSelectStar(index)}
              onMouseEnter={() => onSelectHover(index)}
              onMouseLeave={() => onSelectHover(-1)}
            >
              <Icon type={IconType.Star} fill={getColor(item)} size={40} />
            </SurveyStar>
          )}
        </Loop>
      </SurveyStarBox>

      <SurveyActionBar>
        <SurveySubmitButton
          onClick={onSubmit}
          disabled={isLoading || score === -1}
        >
          <Spinner isLoading={isLoading} />
          <If condition={!isLoading}>
            <SurveySubmitButtonText>
              {t("Widget.Notification.SurveyNotification.next")}
            </SurveySubmitButtonText>
            <Icon type={IconType.ArrowRight} size={24} fill="#fff" />
          </If>
        </SurveySubmitButton>
      </SurveyActionBar>
    </SurveyBasic>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  widget: state.widget,
  ping: state.ping,
});

export default connect(mapStateToProps)(SurveyStars);
