import { SonicDispatchProp } from "sonic";
import { Ping } from "app-types/ping";
import React, { FunctionComponent } from "react";
import { ApplicationState } from "state";
import { connect } from "react-redux";
import { Widget } from "app-types/widget";
import { getVimeoIdentifier, getYoutubeIdentifier } from "utils/video";
import { isEmoji } from "utils/emoji";
import GraphemeSplitter from "grapheme-splitter";
import EmojiBubble from "./EmojiBubble";
import VimeoBubble from "./VimeoBubble";
import YoutubeBubble from "./YoutubeBubble";
import EmailBubble from "./EmailBubble";
import InAppBubble from "./InAppBubble";
import KnowledgeAutoSearchStaticBubble from "./KnowledgeAutoSearchStaticBubble";
import KnowledgeBubble from "./KnowledgeBubble";
import ImageBubble from "./ImageBubble";
import { BubbleContainer } from "./index.style";
import ProfilingBubble from "./ProfilingBubble";
import { Message } from "app-types/message";
import {
  KnowledgeAutoSearchResultTypeMessage,
  KnowledgeAutoSearchStaticTypeMessage,
  KnowledgeTypeMessage,
  OfficeHourTypeMessage,
  ProfilingTypeMessage,
} from "app-types/automaticMessage";
import OfficeHourBubble from "./OfficeHourBubble";
import { Conversation } from "app-types/conversation";
import KnowledgeAutoSearchResultBubble from "./KnowledgeAutoSearchResultBubble";
import { isAccount as isAccountUtil } from "utils/message";

interface OwnProps {
  conversation?: Conversation;
  message: Message;
  onOpenEmail: Function;
  onShowModal: Function;
  onSelectedArticle: Function;
}

interface StateProps {
  widget: Widget;
  ping: Ping;
}

type Props = StateProps & OwnProps & SonicDispatchProp;

const Bubble: FunctionComponent<Props> = (props) => {
  const isEmojiMessage = (message: string): boolean => {
    if (message.length === 0) {
      return false;
    }

    const splitter = new GraphemeSplitter();
    const chars = splitter.splitGraphemes(message);

    if (chars.length > 2) {
      return false;
    }

    for (let char of chars) {
      if (!isEmoji(char)) {
        return false;
      }
    }

    return true;
  };

  const isYoutubeMessage = (message: string): boolean => {
    return getYoutubeIdentifier(message) !== undefined;
  };

  const isVimeoMessage = (message: string): boolean => {
    return getVimeoIdentifier(message) !== undefined;
  };

  const isImageMessage = (message: string): boolean => {
    return message === "🖼 Image";
  };

  const isKnowledgeBubble = props.message.type === KnowledgeTypeMessage;
  const isKnowledgeAutoSearchStaticBubble =
    props.message.type === KnowledgeAutoSearchStaticTypeMessage;
  const isKnowledgeAutoSearchResultBubble =
    props.message.type === KnowledgeAutoSearchResultTypeMessage;
  const isProfilingBubble = props.message.type === ProfilingTypeMessage;
  const isOfficeHourBubble = props.message.type === OfficeHourTypeMessage;
  const isAccount = isAccountUtil(props.message);
  const isEmailBubble = !!props.message.rich_mail_link;
  const isInAppBubble =
    !isEmailBubble && !isKnowledgeBubble && !isProfilingBubble;
  const isImageBubble = isImageMessage(props.message.abstract);
  const isVimeoBubble = isVimeoMessage(props.message.abstract);
  const isYoutubeBubble = isYoutubeMessage(props.message.abstract);
  const isEmojiBubble = isEmojiMessage(props.message.abstract);
  const isNormalBubble =
    !isImageBubble && !isEmojiBubble && !isYoutubeBubble && !isVimeoBubble;

  return (
    <BubbleContainer isAccount={isAccount}>
      {isNormalBubble && isInAppBubble && (
        <InAppBubble
          isAccount={isAccount}
          html={props.message.content}
          attachments={props.message.attachments}
        />
      )}
      {isNormalBubble && isEmailBubble && (
        <EmailBubble
          isAccount={isAccount}
          onOpenEmail={props.onOpenEmail}
          url={props.message.rich_mail_link}
        />
      )}
      {isNormalBubble && isKnowledgeBubble && (
        <KnowledgeBubble
          isAccount={isAccount}
          onShowModal={props.onShowModal}
          onSelectedArticle={props.onSelectedArticle}
        />
      )}
      {isNormalBubble && isKnowledgeAutoSearchStaticBubble && (
        <KnowledgeAutoSearchStaticBubble isAccount={isAccount} />
      )}
      {isNormalBubble &&
        isKnowledgeAutoSearchResultBubble &&
        props.message.article && (
          <KnowledgeAutoSearchResultBubble
            isAccount={isAccount}
            article={props.message.article}
            onShowModal={props.onShowModal}
            onSelectedArticle={props.onSelectedArticle}
          />
        )}
      {!isNormalBubble && isEmojiBubble && (
        <EmojiBubble isAccount={isAccount} message={props.message.abstract} />
      )}
      {!isNormalBubble && isImageBubble && (
        // @ts-ignore
        <ImageBubble isAccount={isAccount} html={props.message.content} />
      )}
      {!isNormalBubble && isVimeoBubble && (
        // @ts-ignore
        <VimeoBubble
          isAccount={isAccount}
          // @ts-ignore
          videoIdentifier={getVimeoIdentifier(props.message.abstract)}
          html={props.message.content}
        />
      )}
      {!isNormalBubble && isYoutubeBubble && (
        // @ts-ignore
        <YoutubeBubble
          isAccount={isAccount}
          // @ts-ignore
          videoIdentifier={getYoutubeIdentifier(props.message.abstract)}
          html={props.message.content}
        />
      )}

      {isNormalBubble &&
        isProfilingBubble &&
        props.message.profiling !== undefined && (
          <ProfilingBubble
            profiling={props.message.profiling}
            conversation={props.conversation}
            html={props.message.content}
            attachments={props.message.attachments}
          />
        )}
      {isNormalBubble && isOfficeHourBubble && (
        <OfficeHourBubble
          replyTime={props.ping.app_config.reply_time}
          officeHoursNext={props.ping.app_config.office_hours_next}
          officeHours={props.ping.app_config.office_hours}
        />
      )}
    </BubbleContainer>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  widget: state.widget,
  ping: state.ping,
});

export default connect(mapStateToProps)(Bubble);
