import { createSideEffect, createStateUpdater } from "sonic";
import AppTypes from "AppTypes";
import { ApplicationState } from "state";

interface RegisterPayload {
  domain: string;
  token: string;
}

const setDebugMode = createStateUpdater(
  "debug/mode",
  (state: ApplicationState, isDebugMode: boolean) => {
    return {
      ...state,
      widget: {
        ...state.widget,
        debug: isDebugMode,
      },
    };
  }
);

const registerDebugMode: AppTypes.AppSideEffectCreator<
  RegisterPayload,
  void
> = createSideEffect(
  "register/install",
  async (context, payload: RegisterPayload) => {
    try {
      await context.dispatch(setDebugMode(true));
      await context.dependencies.api.debug.registerInstall(
        payload.token,
        payload.domain
      );
    } catch (error) {}
  }
);

export default {
  registerDebugMode,
};
