import React, { FunctionComponent, useEffect } from "react";
import { SonicDispatchProp } from "sonic";
import { SurveyLogic } from "logic";
import { SurveyBasicContainer } from "./index.style";
import { Survey } from "app-types/survey";

interface OwnProps {
  survey: Survey;
}

type Props = OwnProps & SonicDispatchProp;

const SurveyBasic: FunctionComponent<Props> = (props) => {
  useEffect(() => {
    if (!props.survey.seen_at) {
      props.dispatch(SurveyLogic.seen(props.survey.survey_id));
    }
    // eslint-disable-next-line
  }, []);

  return <SurveyBasicContainer>{props.children}</SurveyBasicContainer>;
};

export default SurveyBasic;
