import { getServerURL } from "utils/url";
import { getCookie, setCookie } from "utils/cookie";

type Method = "GET" | "POST" | "PUT" | "DELETE";

class ApiClient {
  /** The url used to perform the call */
  private static baseURL = getServerURL();

  private readonly appId: string;
  private _accessToken: string;

  constructor() {
    let settings =
      (window.customerly && window.customerly.settings) ||
      window.customerlySettings;

    this.appId = settings.app_id;
    this._accessToken = getCookie("customerly_jwt");
  }

  get accessToken(): string {
    return this._accessToken;
  }

  set accessToken(accessToken: string) {
    setCookie("customerly_jwt", accessToken);

    this._accessToken = accessToken;
  }

  /** Performs a get call */
  public get = (path: string): Promise<any> => {
    return this.performCall(path, "GET");
  };

  /** Performs a post call */
  public post = (path: string, body?: any): Promise<any> => {
    return this.performCall(path, "POST", body);
  };

  /** Performs a put call */
  public put = (path: string, body?: any): Promise<any> => {
    return this.performCall(path, "PUT", body);
  };

  /** Performs a delete call */
  public delete = (path: string): Promise<any> => {
    return this.performCall(path, "DELETE", {});
  };

  /**
   * Performs a call with the given body and method to the given path
   */
  private performCall = async (
    path: string,
    method: Method,
    body: any = {}
  ): Promise<any> => {
    let payload = {
      params: body,
    } as any;

    payload.params.app_id = this.appId;
    if (this.accessToken) {
      payload.access_token = this.accessToken;
    }

    const request = {
      body: payload ? JSON.stringify(payload) : undefined,
      mode: "cors",
      method,
    } as RequestInit;

    const url = `${ApiClient.baseURL}${path}`;
    let response: Response;

    try {
      response = await fetch(url, request);
    } catch (error) {
      throw Error(
        `Failed to fetched from ${url} with method ${method}: ${error}`
      );
    }

    const jsonResponse = await response.json();

    if (!response.ok) {
      const userMessage = jsonResponse.user_message;
      throw Error(userMessage);
    }

    return jsonResponse;
  };
}

export default ApiClient;
