import styled from "styled-components";
import { Close as ModalClose } from "components/Modal/index.style";
import { Spinner } from "components/Spinner/index.style";
import { shake } from "../../../keyframes.style";

const bubbleMaxWidth = 192;
const bubblePaddingLeft = 16;
const bubblePadding = `8px ${bubblePaddingLeft}px`;
const textareaPadding = 16;
const watermarkHeight = 35;

interface IconProps {
  isActive: boolean;
}

interface DropzoneProps {
  isDragging: boolean;
}

interface BubbleProps {
  isAccount: boolean;
}

interface BubbleListContainerProps {
  textareaHeight: number;
  brandedWidget: boolean;
}

const Compose = styled.div`
  background: ${(props) => props.theme.accentColor} !important;
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  border-radius: 8px 8px 0 0 !important;

  ${Spinner} {
    border-left: 1.1em solid ${(props) => props.theme.contrastColor} !important;
  }
`;

const Header = styled.div`
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  min-height: 88px !important;
  color: white !important;
  transition: height 160ms ease-out 0s !important;
  background: ${(props) => props.theme.accentColor} !important;
`;
const Back = styled(ModalClose)``;

const Assigner = styled.div`
  position: absolute !important;
  left: 56px !important;
  display: flex !important;
  padding: 8px !important;
`;
const AssignerImage = styled.div`
  margin-right: 16px !important;
  align-items: center !important;
  display: flex !important;

  img {
    border: 4px solid #ffffff66 !important;
  }
`;

const AssignerMetadata = styled.div`
  max-width: 168px !important;
  flex-direction: column !important;
  justify-content: center !important;
  display: flex !important;
`;

const MultipleAssignerImage = styled(AssignerImage)`
  margin-right: 36px !important;

  img {
    margin-right: -24px !important;
  }
`;

const AssignerTitle = styled.div`
  font-size: 18px !important;
  font-weight: 500 !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
`;
const AssignerDescription = styled.div`
  margin-top: -4px !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
`;

const BubbleListContainer = styled.div<BubbleListContainerProps>`
  background: white !important;
  border-radius: 8px 8px 0 0 !important;
  position: absolute !important;
  top: 64px !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  overflow: auto !important;
  box-shadow: 0 4px 20px 0 #00000054 !important;
  transform: translateY(${(props) => props.theme.widgetHeight}) !important;
  margin-bottom: ${(props) =>
    props.textareaHeight +
    (props.brandedWidget ? watermarkHeight : 0)}px !important;
`;

const BubbleListInnerContainer = styled.div`
  height: 100% !important;
  overflow: auto !important;
`;

const DropzoneInnerContainer = styled.div`
  height: 100% !important;
`;

const LoadingMoreMessageContainer = styled.div`
  ${Spinner} {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
    border-left: 1.1em solid ${(props) => props.theme.accentColor} !important;
  }
`;

const DropzoneHiddenInput = styled.input`
  display: none !important;
`;

const DropzoneBackgroundContainer = styled.div<DropzoneProps>`
  display: ${(props) => (!props.isDragging ? "none" : "flex")} !important;
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  background: white !important;
  opacity: 0.9 !important;
  z-index: 1 !important;
`;

const DropzoneBackground = styled.div`
  display: flex !important;
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  opacity: 0.9 !important;
  z-index: 1 !important;
  align-items: center !important;
  flex-direction: column !important;
  justify-content: center !important;
  margin: 16px !important;
  border-radius: 8px !important;
  border: 2px dashed ${(props) => props.theme.accentColor} !important;

  svg {
    animation: ${shake} 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both infinite !important;
    transform: translate3d(0, 0, 0) !important;
    backface-visibility: hidden !important;
    perspective: 1000px !important;

    path {
      fill: ${(props) => props.theme.accentColor} !important;
    }
  }

  h4 {
    margin-top: 32px !important;
    color: ${(props) => props.theme.accentColor} !important;
  }
`;

const BubbleDivider = styled.div`
  font-size: 12px !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  color: #d8d8d8 !important;
  text-align: center !important;
  overflow: hidden !important;
  padding: 10px !important;
`;

const BubbleItem = styled.div<BubbleProps>`
  margin-left: ${(props) => (props.isAccount ? "8px" : "0")} !important;
  display: ${(props) => (props.isAccount ? "flex" : "block")} !important;
  text-align: ${(props) => (props.isAccount ? "left" : "right")} !important;
`;

const BubbleAvatarContainer = styled.div`
  img {
    width: 35px !important;
    height: 35px !important;
  }
`;

const BubbleFakeAvatar = styled.div`
  display: inline-block !important;
  width: 35px !important;
  height: 35px !important;
`;

const InputContainer = styled.div`
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  min-height: 52px !important;
  box-shadow: 0px -6px 20px 0px #0000000d !important;

  textarea {
    position: absolute !important;
    bottom: ${textareaPadding}px !important;
    left: 0 !important;
    color: #282828 !important;
    resize: none !important;
    outline-offset: -5px !important;
    border-width: initial !important;
    border-style: none !important;
    border-color: initial !important;
    border-image: initial !important;
    box-sizing: border-box !important;
    width: 100% !important;
    height: 100% !important;
    font-size: 14px !important;
    font-weight: normal !important;
    line-height: 26px !important;
    background-color: rgb(255, 255, 255) !important;
    white-space: pre-wrap !important;
    overflow-wrap: break-word !important;
    padding: 0 80px 0 24px !important;
    font-family: "Helvetica Neue", "Apple Color Emoji", Helvetica, Arial,
      sans-serif !important;
    font-style: normal !important;
    letter-spacing: normal !important;
    font-stretch: normal !important;
    text-align: left !important;
    text-align-last: initial !important;
    text-indent: 0 !important;
    text-shadow: none !important;
    text-transform: none !important;
    background-image: none !important;
    min-width: 0 !important;
    font-variant: normal !important;
    text-decoration: none !important;
    margin: 0 !important;
    border-radius: 0 !important;

    overflow: auto !important;
    outline: none !important;

    box-shadow: none !important;

    &:disabled {
      background: #e8e8e8 !important;
    }
  }
`;

const InputInnerContainer = styled.div<{
  textareaHeight: number;
  disabled: boolean;
}>`
  height: ${(props) => props.textareaHeight}px !important;
  background: ${(props) => (props.disabled ? "#e8e8e8" : "#fff")} !important;
  border-top: 1px solid rgb(230, 230, 230) !important;
`;

const InputContainerIconGroup = styled.div`
  background: none !important;
  border: 0 !important;
  cursor: pointer !important;
  padding: 0 !important;
  position: absolute !important;
  right: 8px !important;
  top: 12px !important;
  display: flex !important;
`;

const InputContainerIcon = styled.button<IconProps>`
  margin-right: 8px !important;

  * {
    cursor: pointer !important;
  }

  &:focus {
    outline: none !important;
  }

  &:hover {
    svg {
      path {
        fill: ${(props) => props.theme.accentColor} !important;
        transition: fill 200ms ease-in-out !important;
      }
    }
  }

  svg {
    width: 30px !important;
    height: 35px !important;

    path {
      fill: ${(props) =>
        props.isActive ? props.theme.accentColor : "#d8d8d8"};
      transition: fill 200ms ease-in-out !important;
    }
  }
`;

const InputContainerSend = styled(InputContainerIcon)``;

const InputContainerEmoji = styled(InputContainerIcon)``;

const InputContainerGif = styled(InputContainerIcon)``;

const InputContainerAttachment = styled(InputContainerIcon)``;

const ContainerModal = styled.div`
  position: absolute !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  background-color: white !important;
  border-top: 1px solid rgb(230, 230, 230) !important;
  max-height: 60% !important;
  border-radius: 8px 8px 0 0 !important;
  overflow: auto !important;
`;

export {
  Compose,
  Header,
  Back,
  Assigner,
  AssignerImage,
  AssignerMetadata,
  MultipleAssignerImage,
  AssignerTitle,
  AssignerDescription,
  BubbleListContainer,
  BubbleListInnerContainer,
  DropzoneInnerContainer,
  DropzoneHiddenInput,
  DropzoneBackground,
  DropzoneBackgroundContainer,
  BubbleDivider,
  BubbleItem,
  BubbleAvatarContainer,
  BubbleFakeAvatar,
  LoadingMoreMessageContainer,
  InputContainer,
  InputInnerContainer,
  InputContainerSend,
  InputContainerEmoji,
  InputContainerGif,
  InputContainerIconGroup,
  InputContainerAttachment,
  ContainerModal,
  bubbleMaxWidth,
  bubblePaddingLeft,
  bubblePadding,
  textareaPadding,
};
