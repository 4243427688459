import * as AppTypes from "AppTypes";
import { createSideEffect } from "sonic";
import { updatePage } from "utils/stats";
import { cleanOldLocalStorageStats } from "utils/local-storage/stats-local-storage";

export interface StatsPayload {
  path: string;
  time: number;
}

const updateStats: AppTypes.AppSideEffectCreator<
  StatsPayload,
  void
> = createSideEffect("stats/updateStats", async (context, statsPayload) => {
  cleanOldLocalStorageStats(); // TODO: Remove this before triggers launch
  updatePage(statsPayload.path, statsPayload.time);
});

export default {
  updateStats,
};
