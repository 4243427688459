import { User } from "./user";
import { Account } from "./account";
import { utcNow } from "utils/date";
import { BOT_IDENTIFIER } from "constants/base";
import { getRandomConversationMessageId, Message } from "./message";
import { Profiling } from "./ping";
import { Article } from "./knowledge";

export const KnowledgeTypeMessage = -1;
export const ProfilingTypeMessage = -2;
export const OfficeHourTypeMessage = -3;
export const KnowledgeAutoSearchStaticTypeMessage = -4;
export const KnowledgeAutoSearchResultTypeMessage = -5;

const getKnowledgeMessageAutoSearchStaticMessage = () => {
  return {
    abstract: "",
    account: {
      account_id: BOT_IDENTIFIER,
      name: "Bot",
    } as Account,
    assigner: null,
    assigner_id: null,
    assigner_type: null,
    attachments: [],
    close_conversation: false,
    content: "",
    conversation_id: 0,
    conversation_message_id: getRandomConversationMessageId(),
    discarded: false,
    in_app: true,
    mail_raw: null,
    mail_sent: false,
    rich_mail: false,
    rich_mail_link: "",
    rich_mail_token: "",
    seen_date: utcNow(),
    sent_date: utcNow(),
    subject: "",
    type: KnowledgeAutoSearchStaticTypeMessage,
    user: {} as User,
  } as Message;
};

const getKnowledgeMessageAutoSearchResultMessage = (article: Article) => {
  return {
    abstract: "",
    account: {
      account_id: BOT_IDENTIFIER,
      name: "Bot",
    } as Account,
    article: article,
    assigner: null,
    assigner_id: null,
    assigner_type: null,
    attachments: [],
    close_conversation: false,
    content: "",
    conversation_id: 0,
    conversation_message_id: getRandomConversationMessageId(),
    discarded: false,
    in_app: true,
    mail_raw: null,
    mail_sent: false,
    rich_mail: false,
    rich_mail_link: "",
    rich_mail_token: "",
    seen_date: utcNow(),
    sent_date: utcNow(),
    subject: "",
    type: KnowledgeAutoSearchResultTypeMessage,
    user: {} as User,
  } as Message;
};

const getKnowledgeMessage = () => {
  return {
    abstract: "",
    account: {
      account_id: BOT_IDENTIFIER,
      name: "Bot",
    } as Account,
    assigner: null,
    assigner_id: null,
    assigner_type: null,
    attachments: [],
    close_conversation: false,
    content: "",
    conversation_id: 0,
    conversation_message_id: getRandomConversationMessageId(),
    discarded: false,
    in_app: true,
    mail_raw: null,
    mail_sent: false,
    rich_mail: false,
    rich_mail_link: "",
    rich_mail_token: "",
    seen_date: utcNow(),
    sent_date: utcNow(),
    subject: "",
    type: KnowledgeTypeMessage,
    user: {} as User,
  } as Message;
};

const getOfficeHourMessage = () => {
  return {
    abstract: "",
    account: {
      account_id: BOT_IDENTIFIER,
      name: "Bot",
    } as Account,
    assigner: null,
    assigner_id: null,
    assigner_type: null,
    attachments: [],
    close_conversation: false,
    content: "",
    conversation_id: 0,
    conversation_message_id: getRandomConversationMessageId(),
    discarded: false,
    in_app: true,
    mail_raw: null,
    mail_sent: false,
    rich_mail: false,
    rich_mail_link: "",
    rich_mail_token: "",
    seen_date: utcNow(),
    sent_date: utcNow(),
    subject: "",
    type: OfficeHourTypeMessage,
    user: {} as User,
  } as Message;
};

const getProfilingMessage = (profiling: Profiling) => {
  return {
    abstract: "",
    account: {
      account_id: BOT_IDENTIFIER,
      name: "Bot",
    } as Account,
    assigner: null,
    assigner_id: null,
    assigner_type: null,
    attachments: [],
    close_conversation: false,
    content: "",
    conversation_id: 0,
    conversation_message_id: getRandomConversationMessageId(),
    discarded: false,
    in_app: true,
    mail_raw: null,
    mail_sent: false,
    rich_mail: false,
    rich_mail_link: "",
    rich_mail_token: "",
    seen_date: utcNow(),
    sent_date: utcNow(),
    subject: "",
    type: ProfilingTypeMessage,
    user: {} as User,
    profiling: profiling,
  } as Message;
};

export {
  getKnowledgeMessageAutoSearchStaticMessage,
  getKnowledgeMessageAutoSearchResultMessage,
  getKnowledgeMessage,
  getProfilingMessage,
  getOfficeHourMessage,
};
