import { createSideEffect } from "sonic";
import AppTypes from "AppTypes";

export interface FunctionWithParameter {
  function: Function;
  arguments?: any[];
}

const call: AppTypes.AppSideEffectCreator<
  FunctionWithParameter,
  void
> = createSideEffect(
  "callback/call",
  async (context, callback: FunctionWithParameter) => {
    if (callback && typeof callback.function === "function") {
      if (!callback.arguments) {
        callback.arguments = [];
      }

      callback.function.call(null, ...callback.arguments);
    }
  }
);

export default {
  call,
};
