import { Conversation } from "app-types/conversation";
import {
  getFakeConversation,
  getFakeConversations,
  setFakeConversation,
} from "./local-storage/fake-message-local-storage";
import { FakeConversation } from "app-types/fakeMessage";
import { abstractify } from "./message";
import { Message } from "app-types/message";

const getFakeConversationId = () => {
  return -Math.floor(new Date().getTime() * (Math.random() + 1));
};

const getUnreadFakeMessages = (): Message[] => {
  const fakeConversations = getFakeConversations();
  const messages: Message[] = [];

  fakeConversations.forEach((fakeConversation) => {
    if (!!fakeConversation.unread) {
      fakeConversation.messages.forEach((message) => {
        if (!message.seen_date) {
          messages.push(message);
        }
      });
    }
  });

  return messages;
};

const getConversationsFromFakeConversations = (
  user_id: number
): Conversation[] => {
  const conversations: Conversation[] = [];
  const fakeConversations = getFakeConversations();
  for (const fakeConversation of fakeConversations) {
    conversations.push(
      getConversationFromFakeConversation(fakeConversation, user_id)
    );
  }

  return conversations;
};

const getConversationFromFakeConversation = (
  fakeConversation: FakeConversation,
  user_id: number
): Conversation => {
  const last_message =
    fakeConversation.messages[fakeConversation.messages.length - 1];

  return {
    conversation_id: fakeConversation.conversation_id,
    last_account: last_message.account,
    last_message_abstract: abstractify(last_message.content),
    last_message_date: last_message.sent_date,

    unread: !!fakeConversation.unread,
    user_id: user_id,

    messages: fakeConversation.messages,
  } as Conversation;
};

const seenFakeConversation = (
  conversationId: number,
  conversationMessageId: number,
  timestamp: number
) => {
  const fakeConversation = getFakeConversation(conversationId);

  if (fakeConversation) {
    const messages = fakeConversation.messages;

    if (
      messages[messages.length - 1].conversation_message_id ===
      conversationMessageId
    ) {
      fakeConversation.unread = 0;
    }

    for (let i = 0; i < messages.length; i++) {
      messages[i].seen_date = timestamp;
      if (messages[i].conversation_message_id === conversationMessageId) {
        break;
      }
    }
    setFakeConversation(conversationId, fakeConversation);
  }
};

const discardFakeConversation = (conversationId: number) => {
  const fakeConversation = getFakeConversation(conversationId);

  if (fakeConversation) {
    const messages = fakeConversation.messages;

    for (let i = 0; i < messages.length; i++) {
      if (!messages[i].seen_date) {
        messages[i].discarded = true;
      }
    }

    setFakeConversation(conversationId, fakeConversation);
  }
};

export {
  getFakeConversationId,
  getUnreadFakeMessages,
  getConversationsFromFakeConversations,
  seenFakeConversation,
  discardFakeConversation,
};
