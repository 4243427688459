import styled from "styled-components";
import {
  bubbleMaxWidth,
  bubblePadding,
  bubblePaddingLeft,
} from "../index.style";
import { darken, lighten } from "utils/color";
import { Ul } from "../../../../index.style";
import { Card, Link } from "../../../index.style";
import { Spinner } from "components/Spinner/index.style";
import { CardTitleRestricted } from "../../../Knowledge/index.style";

interface BubbleProps {
  isAccount: boolean;
  isEmpty?: boolean;
}

const BubbleContainer = styled.div<BubbleProps>`
  overflow-wrap: break-word !important;
  position: relative !important;
  display: inline-block !important;
  width: auto !important;
  margin: 2px 16px !important;
  color: ${(props) =>
    props.isAccount ? "#282828" : props.theme.contrastColor} !important;
`;

const EmojiMessage = styled.div<BubbleProps>`
  font-size: 70px !important;
  background: none !important;
  padding: 0 !important;
  line-height: 84px !important;
`;

const TypingMessageName = styled.span`
  color: #757575 !important;
  font-size: 12px !important;
`;

const TypingMessage = styled.div`
  text-align: center !important;
  background: #f5f5f5 !important;
  padding: 0 !important;
  border-radius: 8px !important;
  width: 80px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
`;

const ImageMessage = styled.div<BubbleProps>`
  height: auto !important;
  display: block !important;
  padding: 0 !important;
  border-radius: 8px !important;
  width: ${bubbleMaxWidth + bubblePaddingLeft * 2}px !important;
  border: 0 !important;
  background: transparent !important;

  p {
    margin: 0 !important;
  }

  img {
    width: 100% !important;
    border-radius: 8px !important;
    cursor: pointer !important;
  }
`;

const VideoMessage = styled.div<BubbleProps>`
  padding: 0 !important;
  margin-top: 4px !important;
  border-radius: 8px !important;
  background: transparent !important;
  max-width: ${bubbleMaxWidth + bubblePaddingLeft * 2}px !important;

  iframe {
    border-radius: 8px !important;
    border: none !important;
    background: #282828 !important;
  }
`;

const EmailMessageIcon = styled.div`
  padding: 8px 0 !important;

  svg {
    margin: 0 auto !important;
  }
`;

const EmailMessageText = styled.div`
  padding: 8px 16px !important;
  color: gray !important;
`;

const EmailMessageCta = styled.div`
  padding: 8px 16px !important;
  background-color: #0000000a !important;
  border-radius: 0 0 5px 5px !important;
  -webkit-transition: background-color 100ms linear !important;
  -ms-transition: background-color 100ms linear !important;
  transition: background-color 100ms linear !important;
`;

const EmailMessage = styled.div<BubbleProps>`
  cursor: pointer !important;
  text-align: center !important;
  padding: 0 !important;
  background: white !important;
  border-radius: 8px !important;
  border: 2px solid rgba(0, 0, 0, 0.15) !important;
  transition: border-color 100ms linear !important;

  &:hover {
    border: 2px solid ${(props) => props.theme.accentColor} !important;

    ${EmailMessageIcon} {
      cursor: pointer !important;
      transform: rotateY(360deg) !important;
      transition: rotateY 0.5s ease-in-out !important;
    }

    ${EmailMessageCta} {
      cursor: pointer !important;
      -webkit-transition: background-color 100ms linear !important;
      -ms-transition: background-color 100ms linear !important;
      transition: background-color 100ms linear !important;
      background-color: ${(props) => props.theme.accentColor} !important;
      color: ${(props) => props.theme.contrastColor} !important;
    }
  }

  * {
    cursor: pointer !important;
  }
`;

const InAppMessage = styled.div<BubbleProps>`
  position: relative !important;
  background: ${(props) =>
    props.isAccount ? "#f5f5f5" : props.theme.accentColor} !important;
  color: ${(props) =>
    props.isAccount ? "#282828" : props.theme.contrastColor} !important;
  padding: ${bubblePadding} !important;
  border-radius: 8px !important;
  word-break: break-word !important;
  overflow-wrap: break-word !important;
  max-width: ${bubbleMaxWidth}px !important;
  text-align: left !important;
  display: inline-block !important;

  * {
    word-break: break-word !important;
    overflow-wrap: break-word !important;
  }

  a {
    &:-webkit-any-link {
      text-decoration: underline !important;
      color: ${(props) =>
        props.isAccount
          ? props.theme.accentColor
          : props.theme.contrastColor} !important;
    }

    &:hover {
      text-decoration: none !important;
    }
  }

  p {
    margin: 0 !important;
  }

  img {
    width: ${bubbleMaxWidth}px !important;
    margin: 0 -16px !important;
    cursor: pointer !important;
  }
`;

const ProfilingMessage = styled.div<BubbleProps>`
  position: relative !important;
  background: ${"#f5f5f5"} !important;
  color: ${(props) => props.theme.contrastColor} !important;
  padding: 8px 16px !important;
  border-radius: 8px !important;
  overflow-wrap: break-word !important;
  width: ${bubbleMaxWidth}px !important;
  text-align: left !important;
  display: inline-block !important;

  a:-webkit-any-link {
    color: ${(props) =>
      props.isAccount ? "#282828" : props.theme.contrastColor} !important;
  }

  p {
    margin: 0 !important;
  }

  img {
    width: ${bubbleMaxWidth}px !important;
    margin: 0 -16px !important;
  }
`;

const KnowledgeMessage = styled.div`
  overflow-wrap: break-word !important;
  display: inline-block !important;
  color: #282828 !important;
  background: #f5f5f5 !important;
  position: relative !important;
  padding: 8px 16px !important;
  border-radius: 8px !important;
  width: ${bubbleMaxWidth}px !important;
  text-align: left !important;

  ${Card} {
    background: whitesmoke !important;
    box-shadow: none !important;
    margin-bottom: 0 !important;
    padding: 0 !important;

    ${CardTitleRestricted} {
      width: 100% !important;
    }
  }

  ${Link} {
    display: none !important;
  }

  ${Spinner} {
    border-left: 1.1em solid ${(props) => props.theme.accentColor} !important;
  }
`;

const KnowledgeBaseAutoSearchResult = styled.div`
  cursor: pointer !important;

  * {
    cursor: pointer !important;
  }

  &:hover {
    background: #f7f7f7 !important;
  }
`;

const AttachmentList = styled(Ul)<BubbleProps>`
  max-width: ${bubbleMaxWidth}px !important;
  margin-top: ${(props) => (props.isEmpty ? 0 : 4)}px;
`;

const AttachmentElement = styled.li`
  margin: 0 auto 4px !important;

  &:last-child {
    margin-bottom: 0 !important;
  }
`;

const AttachmentInfo = styled.div`
  text-align: left !important;
  padding: 0 16px !important;
  width: 100% !important;
  overflow: hidden !important;
`;

const AttachmentIcon = styled.div<BubbleProps>`
  background: #0000001c !important;
  border-radius: 8px 0 0 8px !important;
  height: 100% !important;
  width: 40px !important;
  min-width: 40px !important;
  max-width: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  svg {
    path {
      fill: ${(props) =>
        props.isAccount ? "white" : props.theme.contrastColor} !important;
    }
  }
`;

const AttachmentFilename = styled.div<BubbleProps>`
  color: ${(props) =>
    props.isAccount ? "#282828" : props.theme.contrastColor} !important;
  font-size: 14px !important;
  overflow-wrap: break-word !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
`;

const AttachmentFilesize = styled.div<BubbleProps>`
  font-size: 10px !important;
  color: ${(props) =>
    lighten(props.isAccount ? "#282828" : props.theme.contrastColor, 50)};
`;

const AttachmentButton = styled.div<BubbleProps>`
  transition: background 40ms ease-in-out !important;
  cursor: pointer !important;
  display: flex !important;
  background: ${(props) =>
      props.isAccount ? "#f5f5f5" : props.theme.accentColor}
    center !important;
  height: 60px !important;
  width: 100% !important;
  border-radius: 8px !important;
  font-size: 16px !important;
  align-items: center !important;
  border: 0 !important;
  justify-content: left !important;
  padding: 0 !important;
  text-decoration: none !important;

  * {
    cursor: pointer !important;
  }

  &:hover {
    background: ${(props) =>
        darken(props.isAccount ? "#f5f5f5" : props.theme.accentColor, 20)}
      radial-gradient(
        circle,
        transparent 1%,
        ${(props) =>
            darken(props.isAccount ? "#f5f5f5" : props.theme.accentColor, 20)}
          1%
      )
      center/15000% !important;
    transition: background 500ms ease-in-out !important;

    ${AttachmentIcon} {
      svg {
        transform: rotateY(360deg) !important;
        transition: all 0.5s ease-in-out !important;
      }
    }
  }

  &:active {
    background-color: ${(props) =>
      lighten(
        props.isAccount ? "#f5f5f5" : props.theme.accentColor,
        20
      )} !important;
    background-size: 100% !important;
    transition: background 0s !important;
  }
`;

export {
  BubbleContainer,
  TypingMessage,
  TypingMessageName,
  InAppMessage,
  KnowledgeMessage,
  KnowledgeBaseAutoSearchResult,
  AttachmentList,
  AttachmentElement,
  AttachmentButton,
  AttachmentIcon,
  AttachmentInfo,
  AttachmentFilename,
  AttachmentFilesize,
  ImageMessage,
  EmojiMessage,
  VideoMessage,
  EmailMessage,
  EmailMessageCta,
  EmailMessageIcon,
  EmailMessageText,
  ProfilingMessage,
};
