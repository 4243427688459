import { getParams } from "./url";

const getReferrers = (): any => {
  const urlParams = getParams();

  let result: { [id: string]: any } = {};

  if (urlParams.has("fbclid")) {
    result["fbclid"] = urlParams.get("fbclid");
  }

  if (urlParams.has("gclid")) {
    result["gclid"] = urlParams.get("gclid");
  }

  if (urlParams.has("partner_id")) {
    result["partner_id"] = urlParams.get("partner_id");
  }

  if (urlParams.has("utm_campaign")) {
    result["utm_campaign"] = urlParams.get("utm_campaign");
  }

  if (urlParams.has("utm_content")) {
    result["utm_content"] = urlParams.get("utm_content");
  }

  if (urlParams.has("utm_medium")) {
    result["utm_medium"] = urlParams.get("utm_medium");
  }

  if (urlParams.has("utm_source")) {
    result["utm_source"] = urlParams.get("utm_source");
  }

  if (urlParams.has("utm_term")) {
    result["utm_term"] = urlParams.get("utm_term");
  }

  return result;
};

export { getReferrers };
