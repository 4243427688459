import styled from "styled-components";
import { Button } from "../index.style";
import { darken } from "utils/color";
import { pulsate } from "../keyframes.style";

const RealtimeContainer = styled.div`
  position: fixed !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  z-index: 2147482999 !important;
  background: rgba(0, 0, 0, 0.7) !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  backdrop-filter: blur(10px) !important;
`;

const RealtimeCallerName = styled.div`
  font-size: 2em !important;
  margin-top: 20px !important;
  color: white !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  text-align: center !important;
  p {
    margin-top: 10px !important;
    font-size: 0.5em !important;
  }
  @media (max-width: 460px) {
    padding: 0 30px !important;
    font-size: 1.5em !important;
  }
`;

const RealtimeCallerImage = styled.div`
  height: 300px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;

  img {
    width: 200px !important;
    height: 200px !important;
    animation: ${pulsate} 2s infinite;
  }
`;

const RealtimeButtonsContainer = styled.div`
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  margin-top: 10px !important;
`;

const RealtimeButton = styled(Button)`
  height: 48px !important;
  width: auto !important;
  box-shadow: 0 6px 20px 0 #00000021 !important;

  &:hover {
    box-shadow: 0 6px 20px 0 #00000038 !important;
  }
`;

const RealtimeButtonAnswer = styled(RealtimeButton)`
  background: #42ca49 center !important;
  transition: background 500ms ease-in-out, box-shadow 500ms ease-in-out !important;

  &:hover:enabled {
    background: ${darken("#42ca49", 20)}
      radial-gradient(circle, transparent 1%, ${darken("#42ca49", 20)} 1%)
      center/15000% !important;
    transition: background 500ms ease-in-out, box-shadow 500ms ease-in-out !important;
  }
`;

const RealtimeButtonReject = styled(Button)`
  background: #eb3423 center !important;
  transition: background 500ms ease-in-out, box-shadow 500ms ease-in-out !important;
  margin-left: 10px !important;

  &:hover:enabled {
    background: ${darken("#EB3423", 20)}
      radial-gradient(circle, transparent 1%, ${darken("#EB3423", 20)} 1%)
      center/15000% !important;
    transition: background 500ms ease-in-out, box-shadow 500ms ease-in-out !important;
  }
`;

const RealtimeButtonTitle = styled.span`
  padding: 0 16px 0 0 !important;
`;

const RealtimeButtonIcon = styled.span`
  height: 48px !important;
  width: 48px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
`;

export {
  RealtimeContainer,
  RealtimeCallerName,
  RealtimeCallerImage,
  RealtimeButtonsContainer,
  RealtimeButtonAnswer,
  RealtimeButtonReject,
  RealtimeButtonTitle,
  RealtimeButtonIcon,
};
