import { createSideEffect } from "sonic";
import AppTypes from "AppTypes";
import { User } from "app-types/ping";
import { WidgetLogic } from "../index";

interface SendPayload {
  event: string;
}

const send: AppTypes.AppSideEffectCreator<SendPayload, User> = createSideEffect(
  "event/send",
  async (context, sendPayload: SendPayload) => {
    const response = await context.dependencies.api.event.send(
      sendPayload.event
    );

    if (!response.error) {
      await context.dispatch(WidgetLogic.setUserStateUpdater(response));
    }

    return response;
  }
);

export default {
  send,
};
