import styled from "styled-components";

interface WatermarkContainerProps {
  textareaHeight: number;
}

interface LinkProps {
  color: string;
}

const WatermarkContainer = styled.div<WatermarkContainerProps>`
  width: 100% !important;
  height: 25px !important;
  bottom: 0 !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  position: absolute !important;
  display: flex !important;
  align-items: flex-end !important;
  justify-content: center !important;

  background-image: linear-gradient(
    0deg,
    white 85%,
    rgba(255, 255, 255, 0) 95%
  ) !important;
  margin-bottom: ${(props) => props.textareaHeight}px !important;
`;

const WatermarkInnerContainer = styled.div`
  display: flex !important;
  align-items: flex-end !important;
  justify-content: center !important;
  margin: 0 !important;
  opacity: 0.7 !important;
  transition: opacity 80ms ease-in-out !important;

  &:hover {
    opacity: 1 !important;
    transition: opacity 80ms ease-in-out !important;
  }
`;

const Link = styled.a<LinkProps>`
  margin: 0 4px 0 4px !important;
  color: ${(props) => props.color} !important;

  &:hover {
    text-decoration: underline !important;
  }
`;

const Text = styled.p`
  display: flex !important;
  font-size: 12px !important;
  border-radius: 16px !important;
  text-decoration: none !important;
  margin-left: 4px !important;
  color: #a9a9a9 !important;
  cursor: default !important;
`;

export { WatermarkContainer, WatermarkInnerContainer, Link, Text };
