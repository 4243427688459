const isWindowValid = () => {
  return (
    typeof window != undefined &&
    window != null &&
    typeof window.addEventListener === "function"
  );
};

const isDocumentValid = () => {
  return typeof document != undefined && document != null;
};

const isDOMElementValid = (element: any) => {
  return typeof element != undefined && element != null;
};

export { isWindowValid, isDocumentValid, isDOMElementValid };
