const getServerURL = (): string => {
  if (process.env.REACT_APP_BACKEND_URL !== undefined) {
    return process.env.REACT_APP_BACKEND_URL;
  }

  return "https://chat.customerly.io/v2";
};

const getWidgetURL = (): string => {
  if (process.env.REACT_APP_WIDGET_BASE_URL !== undefined) {
    return process.env.REACT_APP_WIDGET_BASE_URL;
  }

  return "https://messenger.customerly.io";
};

const getParams = (): URLSearchParams => {
  const windowUrl = window.location.search;

  return new URLSearchParams(windowUrl);
};

const getPath = (): string => {
  return window.location.pathname;
};

const checkPathExpression = (path: string, expression: string): boolean => {
  const regExPattern = expression
    .replace(/\//g, "\\/")
    .replace(/\./g, "\\.")
    .replace(/\*/g, ".*");
  const regEx = new RegExp(`^${regExPattern}$`);

  return regEx.test(path);
};

const checkHostnameExpression = (path: string, expression: string): boolean => {
  const regExPattern = expression.replace(/\./g, "\\.").replace(/\*/g, ".*");
  const regEx = new RegExp(`^${regExPattern}$`);

  return regEx.test(path);
};

export {
  getServerURL,
  getWidgetURL,
  getParams,
  getPath,
  checkPathExpression,
  checkHostnameExpression,
};
