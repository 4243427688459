import { createSideEffect, createStateUpdater } from "sonic";
import AppTypes from "AppTypes";
import { WidgetMediaPreview, WidgetMediaPreviewType } from "app-types/widget";
import { ApplicationState } from "state";

export interface MediaPayload {
  src: string;
  type: WidgetMediaPreviewType;
}

const setWidgetMediaPreviewStateUpdater = createStateUpdater(
  "widget/mediaPreview",
  (state: ApplicationState, widgetMediaPreview: WidgetMediaPreview) => {
    return {
      ...state,
      widget: {
        ...state.widget,
        mediaPreview: widgetMediaPreview,
      },
    };
  }
);

const open: AppTypes.AppSideEffectCreator<
  MediaPayload,
  void
> = createSideEffect(
  "widget/mediaPreview/open",
  async (context, mediaPayload: MediaPayload) => {
    await context.dispatch(
      setWidgetMediaPreviewStateUpdater({
        isVisible: true,
        src: mediaPayload.src,
        type: mediaPayload.type,
      })
    );
  }
);

const close: AppTypes.AppSideEffectCreator<void, void> = createSideEffect(
  "widget/mediaPreview/open",
  async (context) => {
    await context.dispatch(
      setWidgetMediaPreviewStateUpdater({
        isVisible: false,
        src: undefined,
        type: undefined,
      })
    );
  }
);

export default { open, close };
