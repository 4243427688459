import styled from "styled-components";
import { Button, InputText } from "../index.style";
import { Direction } from "app-types/widget";
import { Spinner } from "components/Spinner/index.style";
import { FadeInUp } from "../animation.style";
import {
  getWidgetBottomPositionDesktop,
  getWidgetSidePositionDesktop,
} from "utils/theme";

const Card = styled.div`
  margin-bottom: 16px !important;
  background: white !important;
  border-radius: 7px !important;
  border: 0 !important;
  height: auto !important;
  padding: 16px !important;
  box-shadow: 0 2px #00000075 !important;
  transition: height 40ms ease-in-out !important;
`;

const CardHeader = styled.div`
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  padding-bottom: 8px !important;
  justify-content: space-between !important;
`;

const CardTitle = styled.div`
  color: #282828 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
`;

const CardButtonContainer = styled.div``;

const CardButton = styled(Button)`
  height: 48px !important;
  width: 100% !important;
  box-shadow: 0 6px 20px 0 #00000021 !important;

  &:hover {
    box-shadow: 0 6px 20px 0 #00000038 !important;
  }
`;

const CardButtonTitle = styled.span``;

const CardButtonIcon = styled.span`
  height: 48px !important;
  width: 48px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
`;

const Link = styled.a`
  cursor: pointer !important;
  color: ${(props) => props.theme.accentColor} !important;
  text-decoration: none !important;
  border: none !important;
  font-size: 14px !important;
  background: none !important;
  -webkit-transition: color 0.3s ease !important;
  -moz-transition: color 0.3s ease !important;
  -ms-transition: color 0.3s ease !important;
  -o-transition: color 0.3s ease !important;
  transition: color 0.3s ease !important;

  &:hover {
    color: #282828 !important;
  }
`;

const Form = styled.form`
  position: relative !important;
`;

const FormInputText = styled(InputText)`
  width: 100% !important;
  height: 48px !important;
  box-sizing: border-box !important;
  font-size: 14px !important;
  box-shadow: rgba(0, 0, 0, 0.07) 0 1px 3px 0 inset !important;
  color: #282828 !important;
  -webkit-appearance: none !important;
  padding: 11px 64px 11px 16px !important;
  border: 1px solid #e1e1e1 !important;
  border-image: initial !important;
  border-radius: 8px !important;
  background: #ffffff !important;
`;

const FormButton = styled(Button)`
  display: block !important;
  cursor: pointer !important;
  border: 0 !important;
  z-index: 0 !important;
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  width: 48px !important;
  border-radius: 0 8px 8px 0 !important;
  background-color: ${(props) => props.theme.accentColor} !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  user-select: none !important;
  transition: background-color 40ms ease-in-out !important;

  svg {
    margin: 0 auto !important;
  }

  ${Spinner} {
    margin: 0 auto !important;
    width: 4em !important;
    height: 4em !important;
  }
`;

const Messenger = styled(FadeInUp)`
  margin: 0 !important;
  overflow: hidden !important;
  background: rgb(2, 0, 36) !important;
  background: ${(props) => props.theme.accentColor} !important;
  display: flex !important;
  flex-direction: column !important;
  height: ${(props) => `calc(
    100% - ${90 + getWidgetBottomPositionDesktop(props.theme.position)}px
  )`} !important;
  -webkit-box-pack: end !important;
  min-height: 200px !important;
  max-height: ${(props) => props.theme.widgetHeight}px !important;
  width: 360px !important;
  border-radius: 16px !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0 0 0 1px, rgba(0, 0, 0, 0.15) 0 5px 30px 0,
    rgba(0, 0, 0, 0.05) 0 3px 3px 0 !important;
  position: fixed !important;
  bottom: ${(props) =>
    60 + getWidgetBottomPositionDesktop(props.theme.position)}px !important;
  z-index: 9999 !important;

  right: ${(props) =>
    props.theme.direction === Direction.Right
      ? `${getWidgetSidePositionDesktop(props.theme.position)}px`
      : "auto"} !important;
  left: ${(props) =>
    props.theme.direction === Direction.Left
      ? `${getWidgetSidePositionDesktop(props.theme.position)}px`
      : "auto"} !important;

  @media (max-width: 460px) {
    z-index: 2147482998 !important;
    width: 100% !important;
    height: 100% !important;
    bottom: 0 !important;
    position: fixed !important;
    border-radius: 0 !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    max-height: 100% !important;
    transform: none !important;
  }
`;

const MessengerContainer = styled.div`
  height: 100% !important;
  overflow: auto !important;
`;

export {
  Card,
  CardHeader,
  CardTitle,
  CardButtonContainer,
  CardButton,
  CardButtonIcon,
  CardButtonTitle,
  Link,
  Form,
  FormInputText,
  FormButton,
  Messenger,
  MessengerContainer,
};
