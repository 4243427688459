import styled from "styled-components";
import { Button } from "../../../../../index.style";
import { Spinner } from "components/Spinner/index.style";
import { darken, lighten } from "utils/color";
import { bubbleMaxWidth } from "../../index.style";

const marginRight = 10;
const negativeButtonColor = "#dadada";

interface CheckboxButtonProps {
  isTrue: boolean;
}

const Description = styled.div`
  padding: 8px 0 !important;
  color: gray !important;

  a {
    color: ${(props) => props.theme.accentColor} !important;
  }
`;

const CheckboxGroup = styled.div`
  display: flex !important;
`;

const CheckboxButton = styled(Button)<CheckboxButtonProps>`
  display: block !important;
  cursor: pointer !important;
  border: 0 !important;
  z-index: 0 !important;
  width: ${bubbleMaxWidth / 2 - marginRight}px !important;
  margin-right: ${marginRight}px !important;
  height: 40px !important;
  border-radius: 8px !important;
  background-color: ${(props) =>
    props.isTrue ? props.theme.accentColor : negativeButtonColor} !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  user-select: none !important;
  transition: background-color 40ms ease-in-out !important;

  svg {
    margin: 0 auto !important;
  }

  &:last-child {
    margin-right: 0 !important;
  }

  &:hover:enabled {
    background-color: ${(props) =>
      props.isTrue
        ? darken(props.theme.accentColor, 20)
        : darken(negativeButtonColor, 20)} !important;
  }

  &:active:enabled {
    background-color: ${(props) =>
      props.isTrue
        ? lighten(props.theme.accentColor, 20)
        : lighten(negativeButtonColor, 20)} !important;
  }

  &:disabled {
    box-shadow: initial !important;
    cursor: initial !important;
    background: ${(props) =>
        props.isTrue
          ? lighten(props.theme.accentColor, 20)
          : lighten(negativeButtonColor, 20)}
      center !important;
  }

  ${Spinner} {
    margin: 0 auto !important;
    width: 4em !important;
    height: 4em !important;
  }
`;

export { Description, CheckboxGroup, CheckboxButton };
