import { createSideEffect } from "sonic";
import AppTypes from "AppTypes";
import { utcNow } from "utils/date";
import { WidgetLogic } from "./index";

export enum SurveyQuestionType {
  OpenQuestion,
  ClosedQuestion,
}

export interface SubmitAnswerPayload {
  surveyId: number;
  questionId: number;
  questionType: SurveyQuestionType;
  answer?: string;
  choiceId?: number;
}

const seen: AppTypes.AppSideEffectCreator<number, void> = createSideEffect(
  "survey/seen",
  async (context, surveyId: number) => {
    try {
      return await context.dependencies.api.survey.seen(surveyId, utcNow());
    } catch (error) {}
  }
);

const reject: AppTypes.AppSideEffectCreator<number, void> = createSideEffect(
  "survey/reject",
  async (context, surveyId: number) => {
    try {
      await context.dependencies.api.survey.reject(surveyId);

      const state = context.getState();

      let surveys = state.ping.unread.surveys.filter(
        (survey) => survey.survey_id !== surveyId
      );

      await context.dispatch(WidgetLogic.setUnreadSurveysStateUpdater(surveys));
    } catch (error) {}
  }
);

const close: AppTypes.AppSideEffectCreator<void, void> = createSideEffect(
  "survey/close",
  async (context) => {
    try {
      await context.dispatch(WidgetLogic.setUnreadSurveysStateUpdater([]));
    } catch (error) {}
  }
);

const submitSurveyAnswer: AppTypes.AppSideEffectCreator<
  SubmitAnswerPayload,
  void
> = createSideEffect(
  "survey/submit",
  async (context, submitAnswerPayload: SubmitAnswerPayload) => {
    try {
      let survey;
      if (
        submitAnswerPayload.questionType === SurveyQuestionType.OpenQuestion
      ) {
        survey = await context.dependencies.api.survey.submitOpenAnswer(
          submitAnswerPayload.surveyId,
          submitAnswerPayload.questionId,
          submitAnswerPayload.answer
        );
      } else {
        survey = await context.dependencies.api.survey.submitClosedAnswer(
          submitAnswerPayload.surveyId,
          submitAnswerPayload.questionId,
          submitAnswerPayload.choiceId
        );
      }

      const state = context.getState();
      let surveys = state.ping.unread.surveys.filter(
        (survey) => survey.survey_id !== submitAnswerPayload.surveyId
      );
      surveys.unshift(survey);

      await context.dispatch(WidgetLogic.setUnreadSurveysStateUpdater(surveys));
    } catch (error) {}
  }
);

export default {
  submitSurveyAnswer,
  seen,
  close,
  reject,
};
