import styled from "styled-components";
import { Button } from "../../index.style";
import { Spinner } from "components/Spinner/index.style";
import { Notification } from "../index.style";
import { Avatar } from "components/Avatar/index.style";
import { Direction } from "app-types/widget";

interface AvatarProps {
  counter: number;
}

const MessageNotification = styled(Notification)`
  width: 360px !important;
`;

const BubbleList = styled.div`
  margin-bottom: 8px !important;
`;

const BubbleContainer = styled.div`
  width: 100% !important;
  display: flex !important;
  flex-direction: ${(props) =>
    props.theme.direction === Direction.Left
      ? "row-reverse"
      : "row"} !important;
  justify-content: flex-end !important;
`;

const AvatarContainer = styled.div`
  width: 20% !important;
  position: relative !important;
  margin: 8px 0 !important;

  ${Avatar} {
    box-shadow: 0 1px 20px 9px #00000017 !important;
  }
`;

const AvatarWrapper = styled.div<AvatarProps>`
  position: absolute !important;
  top: ${(props) =>
    props.counter === 0 || props.counter === 1 ? "0" : "24px"} !important;
  right: ${(props) => {
    if (props.counter === 0) {
      return "24px";
    } else if (props.counter === 1) {
      return "0";
    } else {
      return "16px";
    }
  }} !important;
`;

const Bubble = styled.div`
  background: white !important;
  border-radius: 8px !important;
  box-shadow: 0 1px 20px 9px #00000017 !important;
  margin: 4px 0 !important;
  padding: 16px !important;
  width: calc(80% - 32px) !important;
  cursor: pointer !important;
`;

const Author = styled.div`
  font-size: 0.8em !important;
  color: #9fa8a8 !important;
  cursor: inherit !important;
`;

const AuthorName = styled.span`
  font-weight: bold !important;
  display: inline-block !important;
  cursor: inherit !important;
`;

const Message = styled.div`
  cursor: inherit !important;

  * {
    cursor: inherit !important;
  }

  p {
    margin: 0 !important;
    word-wrap: break-word !important;
  }
  img {
    width: 100% !important;
    border-radius: 8px !important;
  }
  a {
    &:-webkit-any-link {
      text-decoration: underline !important;
      color: ${(props) => props.theme.accentColor} !important;
    }

    &:hover {
      text-decoration: none !important;
    }
  }
`;
const EmailMessage = styled(Message)`
  text-align: center !important;

  svg {
    margin: 0 auto !important;
  }
`;

const Typing = styled.div``;

const ReplyContainer = styled.div`
  float: ${(props) => props.theme.direction} !important;
  width: 80% !important;
`;

const Reply = styled(Button)`
  height: 48px !important;
  width: 100% !important;
  justify-content: center !important;

  ${Spinner} {
    border-left: 1.1em solid ${(props) => props.theme.contrastColor} !important;
    margin: 0 auto !important;
  }
`;

export {
  Author,
  AvatarContainer,
  AvatarWrapper,
  AuthorName,
  BubbleList,
  MessageNotification,
  Message,
  EmailMessage,
  BubbleContainer,
  Bubble,
  Typing,
  Reply,
  ReplyContainer,
};
