import React, { FunctionComponent } from "react";
import { BOT_IDENTIFIER, PICTURES_ENDPOINT } from "constants/base";
import Icon, { IconType } from "../Icon";
import { useSelector } from "react-redux";
import { themeSelector } from "selectors/theme";
import { Avatar as AvatarImg } from "./index.style";

/** The props of the `Avatar` component */
export interface AvatarProps {
  accountId?: number;
  userId?: number;
  teamId?: number;
  size?: number;
  circle?: boolean;
}

const Avatar: FunctionComponent<AvatarProps> = (props) => {
  const theme = useSelector(themeSelector);

  const size = props.size !== undefined ? props.size : 50;
  const circle = props.circle !== undefined ? props.circle : true;
  const isBot = props.accountId === BOT_IDENTIFIER;

  let imageSize = size * 2; // We double the size for retina display
  if (imageSize <= 50) {
    imageSize = 50;
  } else {
    imageSize = 250;
  }

  const baseAccountUrl = `${PICTURES_ENDPOINT}/accounts/${props.accountId}/${imageSize}`;
  const baseUserUrl = `${PICTURES_ENDPOINT}/users/${props.userId}/${imageSize}`;
  const baseTeamUrl = `${PICTURES_ENDPOINT}/teams/${props.teamId}/${imageSize}`;

  return (
    <React.Fragment>
      {isBot && (
        <Icon type={IconType.Bot} fill={theme.accentColor} size={size} />
      )}
      {!isBot && props.accountId && (
        <AvatarImg
          isRounded={circle}
          size={size}
          width={size}
          height={size}
          alt="avatar"
          src={baseAccountUrl}
        />
      )}
      {props.userId && (
        <AvatarImg
          isRounded={circle}
          size={size}
          width={size}
          height={size}
          alt="avatar"
          src={baseUserUrl}
        />
      )}
      {props.teamId && (
        <AvatarImg
          isRounded={circle}
          size={size}
          width={size}
          height={size}
          alt="avatar"
          src={baseTeamUrl}
        />
      )}
    </React.Fragment>
  );
};

export default Avatar;
