import { Account } from "./account";
import { Message } from "./message";

const jsonToConversation = (jsonConversation: any) => {
  return {
    ...jsonConversation,
    messages: [],
  } as Conversation;
};

export interface Conversation {
  conversation_id: number;
  last_account?: Account;
  last_message_abstract: string;
  last_message_date: number;
  unread: boolean;
  user_id: number;

  messages: Message[];
  hasMoreMessages: boolean;
}

export { jsonToConversation };
