import React, {
  createRef,
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { UserInformation, Widget } from "app-types/widget";
import { SonicDispatchProp } from "sonic";
import { Ping, Profiling, ProfilingType } from "app-types/ping";
import {
  AutomaticMessageLogic,
  CallbackLogic,
  ComposeLogic,
  ConversationLogic,
  MessageLogic,
  NavigatorLogic,
  WidgetLogic,
} from "logic";
import Spinner from "components/Spinner";
import Loop from "components/Loop";
import { SocketContext } from "components/Socket/SocketContext";
import useThrottle from "hooks/useThrottle";
import { SocketMessage, SocketTyping } from "app-types/socket";
import If from "components/If";
import Avatar from "components/Avatar";
import { Account } from "app-types/account";
import { Subscription } from "rxjs";
import {
  AttachmentPayload,
  NewsPayload,
  SeenPayload,
  SendPayload,
} from "logic/messenger/message";
import { utcNow } from "utils/date";
import Icon, { IconType } from "components/Icon";
import Modal from "components/Modal";
import EmojiModal from "./EmojiModal";
import GIFModal from "./GIFModal";
import Iframe from "components/Iframe";
import TextareaAutosize from "react-textarea-autosize";
import { ApplicationState } from "state";
import { connect, useSelector } from "react-redux";
import { Message } from "app-types/message";
import DateTime from "components/DateTime";
import Bubble from "./Bubble";
import ScrollToBottom from "components/ScrollToBottom";
import Dropzone, { DropzoneRef } from "react-dropzone";
import { useTranslation } from "react-i18next";
import Alert from "components/Alert";
import {
  getMessageFromAnonymous,
  isAccount,
  isBot,
  isSameAccountOrBot,
  isSameEntity,
} from "utils/message";
import { isVisitorSelector } from "selectors/user";
import ArticleModal from "../../Knowledge/ArticleModal";
import TypingBubble from "./Bubble/TypingBubble";
import {
  KNOWLEDGEBASE_DELAY,
  KNOWLEDGEBASE_FIRST_DELAY,
} from "constants/messenger/automatic-message";

import {
  Assigner,
  AssignerDescription,
  AssignerImage,
  AssignerMetadata,
  AssignerTitle,
  Back,
  BubbleAvatarContainer,
  BubbleDivider,
  BubbleFakeAvatar,
  BubbleItem,
  BubbleListContainer,
  BubbleListInnerContainer,
  Compose as ComposeDiv,
  DropzoneBackground,
  DropzoneBackgroundContainer,
  DropzoneHiddenInput,
  DropzoneInnerContainer,
  Header,
  InputContainer,
  InputContainerAttachment,
  InputContainerEmoji,
  InputContainerGif,
  InputContainerIconGroup,
  InputContainerSend,
  InputInnerContainer,
  LoadingMoreMessageContainer,
  MultipleAssignerImage,
  textareaPadding,
} from "./index.style";
import { HeaderButton } from "../../../index.style";
import { getProfilingMessage } from "app-types/automaticMessage";
import { SocketService } from "components/Socket/SocketService";
import { isValid } from "utils/email";
import { SendResponse } from "dependencies/message-api";
import { XmlEntities } from "html-entities";
import { shouldTriggerKnowledgeBase } from "utils/automatic-message";
import { Article } from "app-types/knowledge";
import Watermark from "./Watermark";

interface OwnProps {}

interface StateProps {
  widget: Widget;
  ping: Ping;
}

type Props = StateProps & OwnProps & SonicDispatchProp;
type Timeout = ReturnType<typeof setTimeout> | undefined;

const Compose: FunctionComponent<Props> = (props) => {
  const socket = useContext(SocketContext);
  const { t } = useTranslation();
  let socketSubscriptions: Subscription[] = [];

  const [newConversationStarted, setNewConversationStarted] = useState(false);
  const [isLoadingMoreMessages, setIsLoadingMoreMessages] = useState(false);
  const [iframeUrl, setIframeUrl] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showEmojiModal, setShowEmojiModal] = useState(false);
  const [showGIFModal, setShowGIFModal] = useState(false);
  const [isLoading, setIsLoading] = useState(
    props.widget.cards.conversation.selected !== undefined
  );
  const [isTextareaEnabled, setIsTextareaEnabled] = useState(true);
  const [message, setMessage] = useState(
    props.widget.cards.compose.defaultMessage
  );
  const [accountTyping, setAccountTyping] = useState<Account | undefined>(
    undefined
  );
  const typingTimeout = useRef<Timeout>();
  const throttledMessage = useThrottle(message, 1500);
  const lastUserActionAt = useRef(0);
  const lastKnowledgeBaseMessageAt = useRef(0);
  const lastAutomaticMessageTimeout = useRef<Timeout>();
  const lastAutomaticMessagesAt = useRef(0);
  const [textareaHeight, setTextareaHeight] = useState<number>(60);
  const isVisitor = useSelector(isVisitorSelector);
  const dropzoneRef = createRef<DropzoneRef>();

  const [selectedArticle, setSelectedArticle] = useState<Article | undefined>();
  const [showModal, setShowModal] = useState(false);

  const onSocketMessage = (socketMessage: SocketMessage) => {
    if (props.widget.cards.conversation.selected) {
      updateMessages(
        props.widget.cards.conversation.selected.conversation_id,
        socketMessage.timestamp
      );
    }
  };

  const onSocketTyping = (typing: SocketTyping) => {
    if (typing.client && props.widget.cards.conversation.selected) {
      const isAccount = !!typing.client;
      const isAccountTyping = typing.is_typing === "y";
      const isDifferentConversation =
        props.widget.cards.conversation.selected.conversation_id !==
        typing.conversation.conversation_id;

      if (!isAccount || isDifferentConversation) {
        return;
      }

      setAccountTyping(isAccountTyping ? typing.client : undefined);
    }
  };

  const onTyping = () => {
    if (!message) {
      return;
    }

    if (typingTimeout.current !== undefined) {
      clearTimeout(typingTimeout.current);
    }

    if (
      message &&
      props.widget.cards.conversation.selected &&
      props.widget.cards.conversation.selected.conversation_id >= 0
    ) {
      const conversationId =
        props.widget.cards.conversation.selected.conversation_id;
      const userId = props.ping.user.crmhero_user_id;
      const preview = message;

      socket.typing(conversationId, userId, preview, true);

      typingTimeout.current = setTimeout(() => {
        socket.typing(conversationId, userId, preview, false);
      }, 3000);
    }
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    const entities = new XmlEntities();

    const escapedMessage = entities.encodeNonUTF(message);
    const trimmedMessage = escapedMessage.replace(/\s/g, "");
    const messageToSend = escapedMessage.replace(/\n/g, "<br/>");

    if (trimmedMessage.length === 0) {
      return;
    }

    onSend(messageToSend);
  };

  const onSend = (
    message: string,
    attachments?: AttachmentPayload[],
    leaveMessage?: boolean
  ) => {
    const userId = props.ping.user.crmhero_user_id;

    if (!leaveMessage) {
      setMessage("");
    }

    if (userId == null && props.ping.app_config.allow_anonymous_chat) {
      onSendAnonymous(message, attachments);
    } else if (userId == null && !props.ping.app_config.allow_anonymous_chat) {
      setIsTextareaEnabled(false);
      askForEmail(message, attachments);
    } else {
      onSendUser(userId, message, attachments);
    }
  };

  const onSendUser = (
    userId: number,
    message: string,
    attachments?: AttachmentPayload[]
  ) => {
    let conversationId =
      props.widget.cards.conversation.selected !== undefined
        ? props.widget.cards.conversation.selected.conversation_id
        : undefined;

    if (conversationId == null) {
      props.dispatch(
        CallbackLogic.call({
          function: props.widget.callbacks.onNewConversation,
          arguments: [message, attachments],
        })
      );
    }

    props
      .dispatch(
        MessageLogic.send({
          conversationId: conversationId,
          message: message,
          attachments: attachments,
          fakeConversation:
            conversationId &&
            conversationId < 0 &&
            props.widget.cards.conversation.selected
              ? props.widget.cards.conversation.selected
              : undefined,
        } as SendPayload)
      )
      .then((result?: SendResponse) => {
        if (result !== undefined) {
          if (conversationId == null || conversationId < 0) {
            setNewConversationStarted(true);
          }

          conversationId =
            conversationId !== undefined
              ? conversationId
              : result.conversation.conversation_id;

          socket.typing(conversationId, userId, "", false);
          socket.message(userId, message, result.timestamp);
        }
      });

    setIsTextareaEnabled(true);
  };

  const askForEmail = (message: string, attachments?: AttachmentPayload[]) => {
    let messages: Message[] = [];

    if (props.widget.cards.conversation.selected) {
      messages = [...props.widget.cards.conversation.selected.messages];
    }

    messages = [...messages, getMessageFromAnonymous(message, attachments)];

    props.dispatch(MessageLogic.setMessagesStateUpdater(messages)).then(() => {
      setTimeout(() => {
        const extra = t(
          "Widget.Messenger.Conversation.Compose.Bubble.ProfilingBubble.emailRequired"
        );
        const privacyUrl =
          props.ping.app_config.widget_privacy_url !== undefined
            ? "<br><br>" +
              t(
                "Widget.Messenger.Conversation.Compose.Bubble.ProfilingBubble.privacyPolicyAcceptance",
                { privacyUrl: props.ping.app_config.widget_privacy_url }
              )
            : "";

        let emailMessage = getProfilingMessage({
          attribute: "email",
          description: t(
            "Widget.Messenger.Conversation.Compose.Bubble.ProfilingBubble.leaveYourEmail"
          ),
          hint: t(
            "Widget.Messenger.Conversation.Compose.Bubble.ProfilingBubble.emailPlaceholder"
          ),
          extra: `${extra}${privacyUrl}`,
          cast: ProfilingType.String,
          validator: (email: string) => {
            return isValid(email);
          },
          callback: async (
            socket: SocketService,
            email: string,
            message: string,
            attachments?: AttachmentPayload[]
          ) => {
            await props
              .dispatch(
                WidgetLogic.getPing({
                  lead_email: email,
                  ...window.customerly.settings,
                } as UserInformation)
              )
              .then((ping: Ping) => {
                if (ping.websocket.token) {
                  socket.init(
                    ping.websocket.endpoint,
                    ping.websocket.port,
                    ping.websocket.token
                  );
                  onSendUser(ping.user.crmhero_user_id, message, attachments);
                }
              });
          },
        } as Profiling);

        // @ts-ignore
        emailMessage.attachments = attachments;
        emailMessage.content = message;

        messages.push(emailMessage);

        props.dispatch(MessageLogic.setMessagesStateUpdater(messages));
      }, 1500);
    });
  };

  const onSendAnonymous = async (
    message: string,
    attachments?: AttachmentPayload[]
  ) => {
    props
      .dispatch(
        WidgetLogic.getPing({
          force_lead: true,
          ...window.customerly.settings,
        } as UserInformation)
      )
      .then(async (ping: Ping) => {
        await props.dispatch(
          CallbackLogic.call({
            function: props.widget.callbacks.onLeadGenerated,
            arguments: [],
          })
        );

        if (ping.websocket.token) {
          socket.init(
            ping.websocket.endpoint,
            ping.websocket.port,
            ping.websocket.token
          );
          onSendUser(ping.user.crmhero_user_id, message, attachments);
        }
      });
  };

  const onPaste = (e: any) => {
    const items = e.clipboardData.items;
    const reader = new FileReader();

    for (let i = 0; i < items.length; i++) {
      if (items[i].kind === "file") {
        const file = items[i].getAsFile();
        e.preventDefault();

        reader.onload = () => {
          const base64 = reader.result;
          const attachment = {
            filename: file.name,
            filesize: file.size,
            base64: base64,
          } as AttachmentPayload;

          onSend("", [attachment], true);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const onDrop = (acceptedFiles: any) => {
    const reader = new FileReader();

    acceptedFiles.forEach((file: any) => {
      reader.onload = () => {
        const base64 = reader.result;
        const attachment = {
          filename: file.name,
          filesize: file.size,
          base64: base64,
        } as AttachmentPayload;

        onSend("", [attachment]);
      };
      reader.readAsDataURL(file);
    });
  };

  const onDropRejected = () => {
    setShowAlert(true);
  };

  const openDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  const onSeen = () => {
    if (props.widget.cards.conversation.selected) {
      const conversationId =
        props.widget.cards.conversation.selected.conversation_id;
      const userId = props.widget.cards.conversation.selected.user_id;
      const messages = props.widget.cards.conversation.selected.messages;
      const now = utcNow();

      if (messages.length === 0) {
        return;
      }

      const lastMessage = messages[messages.length - 1];
      const conversationMessageId = lastMessage.conversation_message_id;
      const sentByAccount =
        lastMessage.account && lastMessage.account.account_id !== undefined;

      if (sentByAccount && !lastMessage.seen_date) {
        props
          .dispatch(
            MessageLogic.seen({
              conversationId: conversationId,
              conversationMessageId: conversationMessageId,
              timestamp: now,
            } as SeenPayload)
          )
          .then(() => {
            if (conversationId >= 0) {
              socket.seen(conversationId, conversationMessageId, userId, now);
            } else {
              props.dispatch(ConversationLogic.getAll());
            }
          });
      }
    }
  };

  const onLoad = () => {
    const fetchMessages = async () => {
      if (!isVisitor) {
        socketSubscriptions.push(socket.onMessage(onSocketMessage));
        socketSubscriptions.push(socket.onTyping(onSocketTyping));
      }

      if (props.widget.cards.conversation.selected !== undefined) {
        setIsLoading(true);

        await props.dispatch(
          MessageLogic.getAll({
            conversationId:
              props.widget.cards.conversation.selected.conversation_id,
          })
        );
      }

      setIsLoading(false);
    };
    fetchMessages().catch(console.error);
  };

  const onUnload = () => {
    socketSubscriptions.forEach((subscription) => subscription.unsubscribe());
  };

  const onOpenEmail = (url: string) => {
    setIframeUrl(url);
    setShowEmailModal(true);
  };

  const updateMessages = (conversationId: number, timestamp: number) => {
    props.dispatch(
      MessageLogic.getNews({
        conversationId: conversationId,
        timestamp: timestamp,
      } as NewsPayload)
    );
  };

  const onEnterPress = (e: any) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      onSubmit(e).catch(console.error);
    }
  };

  const getPrevious = (
    current: Message,
    index: number
  ): Message | undefined => {
    const previousIndex = index - 1;

    if (
      props.widget.cards.conversation.selected &&
      previousIndex >= 0 &&
      previousIndex < props.widget.cards.conversation.selected.messages.length
    ) {
      return props.widget.cards.conversation.selected.messages[index - 1];
    } else {
      return undefined;
    }
  };

  const getLastAccount = (): Account => {
    let lastAccount: Account = {
      account_id: -1,
      name: props.ping.app.name,
    };

    if (props.widget.cards.conversation.selected) {
      props.widget.cards.conversation.selected.messages.forEach(
        (message: Message) => {
          if (message.account && message.account.account_id > 0) {
            lastAccount = message.account;
          }
        }
      );
    }

    return lastAccount;
  };

  const waitCheckForKnowledge = async () => {
    const delay =
      lastUserActionAt.current === 0
        ? KNOWLEDGEBASE_FIRST_DELAY
        : KNOWLEDGEBASE_DELAY;
    lastUserActionAt.current = utcNow();
    if (lastAutomaticMessageTimeout.current) {
      clearTimeout(lastAutomaticMessageTimeout.current);
    }
    lastAutomaticMessageTimeout.current = setTimeout(() => {
      lastKnowledgeBaseMessageAt.current = utcNow();
      props.dispatch(AutomaticMessageLogic.knowledgeBaseTrigger());
    }, delay);
  };

  const onScroll = (e: any) => {
    const nearTopLimit = 50;
    const isNearTop = e.target.scrollTop <= nearTopLimit;
    const shouldLoadMoreMessages =
      props.widget.cards.conversation.selected &&
      props.widget.cards.conversation.selected.hasMoreMessages;

    if (e.target.scrollTop < 1) {
      e.target.scrollTop = 1;
    }

    if (isNearTop && !isLoadingMoreMessages && shouldLoadMoreMessages) {
      loadMoreMessages();
    }
  };

  const loadMoreMessages = async () => {
    if (props.widget.cards.conversation.selected) {
      setIsLoadingMoreMessages(true);

      const conversation = props.widget.cards.conversation.selected;
      const firstMessage = conversation.messages[0];

      await props.dispatch(
        MessageLogic.getAll({
          conversationId: conversation.conversation_id,
          messagesBeforeId: firstMessage.conversation_message_id,
        })
      );
    }

    setIsLoadingMoreMessages(false);
  };

  useEffect(onSeen, [props.widget.cards.conversation.selected]);

  useEffect(onTyping, [throttledMessage]);

  useEffect(() => {
    onLoad();

    return onUnload;
    // eslint-disable-next-line
  }, [newConversationStarted]);

  useEffect(() => {
    if (
      lastKnowledgeBaseMessageAt.current === 0 && // Checking if automatic message already sent
      shouldTriggerKnowledgeBase(props.ping, props.widget)
    ) {
      waitCheckForKnowledge();
    }

    return () => {
      // eslint-disable-next-line
      lastUserActionAt.current = 0;
      if (lastAutomaticMessageTimeout.current) {
        clearTimeout(lastAutomaticMessageTimeout.current);
      }
    };
    // eslint-disable-next-line
  }, [
    props.widget.cards.conversation.selected &&
      props.widget.cards.conversation.selected.messages.length,
    newConversationStarted,
    throttledMessage,
  ]);

  useEffect(() => {
    const userNotLoggedBlocked =
      props.ping.user.crmhero_user_id == null &&
      !props.ping.app_config.allow_anonymous_chat;
    const areMessagesPresent =
      props.widget.cards.conversation.selected &&
      props.widget.cards.conversation.selected.messages.length > 0;

    if (
      !userNotLoggedBlocked &&
      areMessagesPresent &&
      lastAutomaticMessagesAt.current === 0
    ) {
      lastAutomaticMessagesAt.current = utcNow();
      props.dispatch(AutomaticMessageLogic.officeHoursTrigger());
      props.dispatch(AutomaticMessageLogic.profilingFormTrigger());
    }
    // eslint-disable-next-line
  }, [
    props.widget.cards.conversation.selected &&
      props.widget.cards.conversation.selected.messages,
  ]);

  useEffect(() => {
    if (props.widget.cards.compose.defaultMessage) {
      setMessage(props.widget.cards.compose.defaultMessage);
      props.dispatch(ComposeLogic.setDefaultMessageStateUpdater(""));
    }
    // eslint-disable-next-line
  }, [props.widget.cards.compose.defaultMessage]);

  return (
    <ComposeDiv>
      <Spinner isLoading={isLoading}>
        <Header>
          <Assigner>
            <If condition={getLastAccount().account_id !== -1}>
              <AssignerImage>
                <Avatar accountId={getLastAccount().account_id} size={40} />
              </AssignerImage>
              <AssignerMetadata>
                <AssignerTitle>{getLastAccount().name}</AssignerTitle>
              </AssignerMetadata>
            </If>

            <If condition={getLastAccount().account_id === -1}>
              <MultipleAssignerImage>
                <Loop items={props.ping.active_accounts}>
                  {(item, index) => (
                    <Avatar key={index} accountId={item.account_id} size={40} />
                  )}
                </Loop>
              </MultipleAssignerImage>
              <AssignerMetadata>
                <AssignerTitle>{props.ping.app.name}</AssignerTitle>
                <AssignerDescription>
                  {props.ping.app_config.team_intro}
                </AssignerDescription>
              </AssignerMetadata>
            </If>
          </Assigner>

          <Back
            onClick={() => {
              props.dispatch(NavigatorLogic.goTo(props.widget.previousScreen));
            }}
          >
            <HeaderButton>
              <Icon type={IconType.Back} fill="#fff" size={40} />
            </HeaderButton>
          </Back>
        </Header>

        <BubbleListContainer
          textareaHeight={textareaHeight}
          brandedWidget={props.ping.app_config.branded_widget}
        >
          <BubbleListInnerContainer>
            <Dropzone
              onDrop={onDrop}
              onDropRejected={onDropRejected}
              noClick={true}
              noKeyboard={true}
              ref={dropzoneRef}
              maxSize={4000000}
            >
              {({ getRootProps, getInputProps, isDragActive }) => (
                <DropzoneInnerContainer {...getRootProps()}>
                  <DropzoneHiddenInput {...getInputProps()} />

                  <DropzoneBackgroundContainer isDragging={isDragActive}>
                    <DropzoneBackground>
                      <Icon type={IconType.DropZone} fill="#ffffff" size={70} />
                      <h4>
                        {t(
                          "Widget.Messenger.Conversation.Compose.body.dropYourFiles"
                        )}
                      </h4>
                    </DropzoneBackground>
                  </DropzoneBackgroundContainer>

                  <ScrollToBottom onScroll={onScroll}>
                    <LoadingMoreMessageContainer>
                      <Spinner isLoading={isLoadingMoreMessages} />
                    </LoadingMoreMessageContainer>
                    <Loop
                      items={
                        (props.widget.cards.conversation.selected &&
                          props.widget.cards.conversation.selected.messages) ||
                        []
                      }
                    >
                      {(message: Message, index: number) => (
                        <React.Fragment key={message.conversation_message_id}>
                          <If
                            condition={
                              index > 0 &&
                              !isSameEntity(
                                message,
                                getPrevious(message, index)
                              )
                            }
                          >
                            <BubbleDivider>
                              <DateTime date={message.sent_date} />
                            </BubbleDivider>
                          </If>
                          <BubbleItem
                            isAccount={isAccount(message) || isBot(message)}
                          >
                            <If
                              condition={isAccount(message) || isBot(message)}
                            >
                              <BubbleAvatarContainer>
                                {(isAccount(message) || isBot(message)) &&
                                !isSameAccountOrBot(
                                  message,
                                  getPrevious(message, index)
                                ) ? (
                                  <Avatar
                                    accountId={message.account.account_id}
                                    size={35}
                                  />
                                ) : (
                                  <BubbleFakeAvatar />
                                )}
                              </BubbleAvatarContainer>
                            </If>

                            <Bubble
                              conversation={
                                props.widget.cards.conversation.selected
                              }
                              message={message}
                              onOpenEmail={onOpenEmail}
                              onSelectedArticle={setSelectedArticle}
                              onShowModal={setShowModal}
                            />
                          </BubbleItem>
                        </React.Fragment>
                      )}
                    </Loop>

                    <If condition={!!accountTyping}>
                      <BubbleItem isAccount={true}>
                        <TypingBubble
                          accountId={accountTyping && accountTyping.account_id}
                          name={accountTyping && accountTyping.name}
                        />
                      </BubbleItem>
                    </If>
                  </ScrollToBottom>
                </DropzoneInnerContainer>
              )}
            </Dropzone>
          </BubbleListInnerContainer>

          <Alert showAlert={showAlert} setShowAlert={setShowAlert}>
            {t("Widget.Messenger.Conversation.Compose.body.dropError")}
          </Alert>
        </BubbleListContainer>

        <Modal
          title={"Email"}
          showModal={showEmailModal}
          onClose={() => setShowEmailModal(false)}
        >
          <Iframe url={iframeUrl} />
        </Modal>

        {selectedArticle && (
          <ArticleModal
            selectedArticle={selectedArticle}
            showModal={showModal}
            onClose={() => setShowModal(false)}
          />
        )}

        <EmojiModal
          showEmojiModal={showEmojiModal}
          setShowEmojiModal={setShowEmojiModal}
          message={message}
          setMessage={setMessage}
          onSend={onSend}
          textareaHeight={textareaHeight}
        />

        <GIFModal
          showGIFModal={showGIFModal}
          setShowGIFModal={setShowGIFModal}
          onSend={onSend}
          textareaHeight={textareaHeight}
        />

        <If condition={props.ping.app_config.branded_widget}>
          <Watermark textareaHeight={textareaHeight} />
        </If>

        <InputContainer>
          <form onSubmit={onSubmit}>
            <InputInnerContainer
              textareaHeight={textareaHeight}
              disabled={!isTextareaEnabled}
            >
              <TextareaAutosize
                placeholder={t(
                  "Widget.Messenger.Conversation.Compose.footer.sendAMessage"
                )}
                maxRows={4}
                onHeightChange={(height: number) =>
                  setTextareaHeight(height + textareaPadding * 2 + 1)
                }
                onKeyDown={onEnterPress}
                value={message}
                disabled={!isTextareaEnabled}
                onChange={(e) => setMessage(e.target.value)}
                onPaste={onPaste}
              />
            </InputInnerContainer>
            <InputContainerIconGroup>
              {message.length === 0 && (
                <InputContainerGif
                  isActive={showGIFModal}
                  type="button"
                  onClick={() => {
                    setShowGIFModal(!showGIFModal);
                    setShowEmojiModal(false);
                  }}
                >
                  <Icon type={IconType.Gif} fill="#d8d8d8" size={20} />
                </InputContainerGif>
              )}
              <InputContainerEmoji
                isActive={showEmojiModal}
                type="button"
                onClick={() => {
                  setShowEmojiModal(!showEmojiModal);
                  setShowGIFModal(false);
                }}
              >
                <Icon type={IconType.Emoji} fill="#d8d8d8" size={20} />
              </InputContainerEmoji>
              {message.length === 0 && props.widget.attachmentsAvailable && (
                <InputContainerAttachment isActive={false} onClick={openDialog}>
                  <Icon type={IconType.Attachment} fill="#d8d8d8" size={20} />
                </InputContainerAttachment>
              )}
              {message.length > 0 && (
                <InputContainerSend
                  isActive={true}
                  type="submit"
                  onClick={onSubmit}
                >
                  <Icon type={IconType.New} fill="#d8d8d8" size={20} />
                </InputContainerSend>
              )}
            </InputContainerIconGroup>
          </form>
        </InputContainer>
      </Spinner>
    </ComposeDiv>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  widget: state.widget,
  ping: state.ping,
});

export default connect(mapStateToProps)(Compose);
