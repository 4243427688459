import { Middleware, Reducer } from "redux";

/**
 * Sonic dispatch function. It is basically the classic Redux dispatch function
 * augmented with a Promise-based return value. The Promise result is provided
 * by the `Sonic Middleware`, which should be added to the application.
 */
export interface SonicDispatch {
  /**
   * Dispatches a state updater.
   *
   * @param stateUpdater: the state updater to dispatch
   * @see `StateUpdater`
   * @see `StateUpdaterCreator`
   */ <State, Payload>(
    stateUpdater: StateUpdater<State, Payload>
  ): Promise<void>;

  /**
   * Dispatches a side effect.
   *
   * @param sideEffect: the side effect to dispatch
   * @see `SideEffect`
   * @see `SideEffectCreator`
   */ <State, Dependencies, Payload, Response>(
    sideEffect: SideEffect<State, Dependencies, Payload, Response>
  ): Promise<Response>;
}

/**
 * Extra properties passed to a connected component that
 * can contains the (properly typed) dispatch function.
 * The dispatch function is provided by the `react-redux` library
 */
export interface SonicDispatchProp {
  dispatch: SonicDispatch;
}

/**
 * A reduce-like function that is meant to be used within a state updater.
 * The function takes as input the current state and a payload and returns a new state.
 * Note that it is extremely important that:
 * 1) the function is pureimport { SideEffectBody } from './index';
 *
 * 2) the returned state is a brand new object and not just the old one updated (that is, reference equality should fail)
 */
export type StateUpdaterBody<State, Payload> = (
  state: State,
  payload: Payload
) => State;

/**
 * A `StateUpdater` is a combination of an action that is meant to update the state
 * and a reducer. In particular, the `StateUpdater` combines the two things together as
 * a way to decrease the boilerplace and make things more clear to understand.
 */
export interface StateUpdater<State, Payload> {
  /** A human readable name of the state updater. Useful for logging */
  type: string;

  /** A flag used to check whether an object is a state updater at runtime */
  __state_updater__: boolean;

  /**
   * The payload of the state updater, which is a piece of information that
   * the `StateUpdater` can use to update the state
   */
  payload: Payload;

  /**
   * The reduce function that will actually update the state
   */
  body: StateUpdaterBody<State, Payload>;
}

/**
 * A `StateUpdaterCreator` is nothing more than a function that represents
 * a blueprint for a `StateUpdater`. Using this `StateUpdaterCreator`, it is possible
 * to create the `StateUpdater` to dispatch by passing the payload.
 */
export type StateUpdaterCreator<State, Payload> = (
  payload: Payload
) => StateUpdater<State, Payload>;

/**
 * A `SideEffectContext` is like a very simple dependency injection system
 * for `SideEffect`s. It is basically an object that contains a set of methods
 * and utilities that can be used within a side effect.
 *
 * Applications can customise the context's dependencies by passing
 * to the `createSonicMiddleware` method a custom object. This object is
 * is then available in the side effects through the context.
 */
export interface SideEffectContext<State, Dependencies> {
  /** A method to retrieve the current state of the application */
  getState: () => State;

  /** The dispatch function */
  dispatch: SonicDispatch;

  /** The dependencies passed to the `createSonicMiddleware` function */
  dependencies: Dependencies;
}

/**
 * The function that implements the logic of a `SideEffect`.
 * In a side effect, it is possible to create long running, asyncronous logics
 * that leverage things like network calls or disk access.
 * It is also possible to dispatch state updaters or other side effects and wait
 * for their results by either awaiting them or using promise-based flow control.
 *
 * The `SideEffect` can also access to external methods by using the dependencies, accessible
 * through the context.
 *
 * @see SideEffectContext
 * @see SideEffect
 */
export type SideEffectBody<State, Dependencies, Payload, Response> = (
  context: SideEffectContext<State, Dependencies>,
  payload: Payload
) => Promise<Response>;

/**
 * A `SideEffect` is a piece of logic that implements part of the business logic of
 * your application.
 *
 * @see SideEffectBody
 */
export interface SideEffect<State, Dependencies, Payload, Response> {
  type: string;

  /** A flag used to check whether an object is a side effect at runtime */
  __side_effect__: boolean;

  /** The payload that is passed to the side effect */
  payload: Payload;

  /** The body of the side effect, which is the function that actually implements the side effect */
  body: SideEffectBody<State, Dependencies, Payload, Response>;
}

/**
 * A `SideEffectCreator` is nothing more than a function that represents
 * a blueprint for a `SideEffect`. Using this `SideEffectCreator`, it is possible
 * to create the `SideEffect` to dispatch by passing the payload.
 */
export type SideEffectCreator<State, Dependencies, Payload, Response> = (
  payload: Payload
) => SideEffect<State, Dependencies, Payload, Response>;

/**
 * An utility that checks whether the passed argument is a `SideEffect`
 * @param s the parameter to check
 */
const isSideEffect = <State, Dependencies, Payload, Response>(
  s: any
): s is SideEffect<State, Dependencies, Payload, Response> => {
  return s.type && s.__side_effect__;
};

/**
 * An utility that checks whether the passed argument is a `StateUpdater`
 * @param s the parameter to check
 */
const isStateUpdater = <State, Payload>(
  s: any
): s is StateUpdater<State, Payload> => {
  return s.type && s.__state_updater__;
};

/**
 * A `StateUpdaterCreator` is nothing more than a function that represents
 * a blueprint for a `StateUpdater`. Using this `StateUpdaterCreator`, it is possible
 * to create the `StateUpdater` to dispatch by passing the payload.
 *
 * @param type: the type of the state updater
 * @param body: the body of the state updater, which is the function that creates the new state
 */
export const createStateUpdater = <State, Payload>(
  type: string,
  body: StateUpdaterBody<State, Payload>
): StateUpdaterCreator<State, Payload> => {
  return (payload: Payload) => {
    return {
      __state_updater__: true,
      body,
      payload,
      type,
    };
  };
};

/**
 * A `SideEffectCreator` is nothing more than a function that represents
 * a blueprint for a `SideEffect`. Using this `SideEffectCreator`, it is possible
 * to create the `SideEffect` to dispatch by passing the payload.
 *
 * @param type: the type of the side effect
 * @param body: the body of the side effect, which is the function that implements the business logic
 */
export const createSideEffect = <State, D, Payload, R>(
  type: string,
  body: SideEffectBody<State, D, Payload, R>
): SideEffectCreator<State, D, Payload, R> => {
  return (payload: Payload) => {
    return {
      __side_effect__: true,
      body,
      payload,
      type,
    };
  };
};

/**
 * Creates a reducer that is able to manage `StateUpdater` - based actions.
 *
 * @param initialState the initial state of the application
 * @returns the reducer to pass to the Redux Store
 */
export const createSonicReducer = <State>(
  initialState: State
): Reducer<State, any> => {
  return <Payload>(state: State | undefined, action: any) => {
    const safeState = state || initialState;
    if (isStateUpdater<State, Payload>(action)) {
      return action.body(safeState, action.payload);
    } else {
      return safeState;
    }
  };
};

/**
 * Creates the middleware that manages the `SideEffect` - based actions.
 *
 * @param dependencies The dependencies that are passed to each `SideEffect`
 * @returns A middleware that should be added to the Redux store
 */
export const createSonicMiddleware = <Dependencies>(
  dependencies: Dependencies
): Middleware => {
  return ({ dispatch, getState }) => {
    const context: SideEffectContext<any, Dependencies> = {
      dependencies,
      dispatch,
      getState,
    };

    return (next) => (action) => {
      if (isSideEffect(action)) {
        next(action); // continue to handle redux dev tools
        return action.body(context, action.payload);
      }
      return new Promise<void>((resolve) => {
        next(action);
        resolve();
      });
    };
  };
};
