import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { Widget } from "app-types/widget";
import { SonicDispatchProp } from "sonic";
import { ApplicationState } from "state";
import { connect } from "react-redux";
import { Ping } from "app-types/ping";
import {
  RealtimeButtonAnswer,
  RealtimeButtonIcon,
  RealtimeButtonReject,
  RealtimeButtonsContainer,
  RealtimeButtonTitle,
  RealtimeCallerImage,
  RealtimeCallerName,
  RealtimeContainer,
} from "./index.style";
import Avatar from "components/Avatar";
import Icon, { IconType } from "components/Icon";
import { SocketContext } from "components/Socket/SocketContext";
import { Subscription } from "rxjs";
import { SocketRealtimeCall, SocketRealtimeCancel } from "app-types/socket";
import If from "components/If";
import { useTranslation } from "react-i18next";
import { getWidgetURL } from "utils/url";
import { CallbackLogic } from "logic";

interface OwnProps {}

interface StateProps {
  widget: Widget;
  ping: Ping;
}

type Props = StateProps & OwnProps & SonicDispatchProp;

type Interval = number | undefined;

let playingInterval: Interval = undefined;

const Realtime: FunctionComponent<Props> = (props) => {
  const socket = useContext(SocketContext);
  const { t } = useTranslation("", { useSuspense: false }); // useSuspense because otherwise it crashes.

  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [incomingCall, setIncomingCall] = useState<SocketRealtimeCall>();
  const [audio] = useState(new Audio(`${getWidgetURL()}/sounds/calling.m4r`));

  let socketSubscriptions: Subscription[] = [];

  const onClose = () => {
    stopAudio();

    setIsVisible(false);
    setIncomingCall(undefined);
  };

  const onSocketRealtimeCancel = (realtime: SocketRealtimeCancel) => {
    onClose();
  };

  const onSocketRealtimeCall = (realtime: SocketRealtimeCall) => {
    playAudio();
    socket.realtimeRinging(realtime);

    setIncomingCall(realtime);
    setIsVisible(true);
  };

  const onAccept = () => {
    if (incomingCall) {
      socket.realtimeAccept(incomingCall);
      window.open(incomingCall.url);

      props.dispatch(
        CallbackLogic.call({
          function: props.widget.callbacks.onRealtimeVideoAnswered,
        })
      );

      onClose();
    }
  };

  const onReject = () => {
    if (incomingCall) {
      socket.realtimeReject(incomingCall);

      props.dispatch(
        CallbackLogic.call({
          function: props.widget.callbacks.onRealtimeVideoRejected,
        })
      );

      onClose();
    }
  };

  const playAudio = () => {
    audio.play();

    playingInterval = window.setInterval(() => {
      audio.play();
    }, 3000);
  };

  const stopAudio = () => {
    clearInterval(playingInterval);
    audio.pause();
  };

  const subscribe = () => {
    socketSubscriptions.push(socket.onRealtimeCancel(onSocketRealtimeCancel));
    socketSubscriptions.push(socket.onRealtimeCall(onSocketRealtimeCall));
  };

  const onLoad = () => {
    if (socket.isConnected()) {
      subscribe();
    } else {
      socket.isInitialized() &&
        socketSubscriptions.push(
          socket.onConnect(() => {
            subscribe();
          })
        );
    }
  };

  const onUnload = () => {
    socketSubscriptions.forEach((subscription) => subscription.unsubscribe());
  };

  useEffect(() => {
    onLoad();

    return onUnload;
    // eslint-disable-next-line
  }, [socket.isInitialized()]);

  return (
    <If condition={isVisible}>
      <RealtimeContainer>
        <RealtimeCallerImage>
          <Avatar
            accountId={incomingCall && incomingCall.account.account_id}
            size={250}
          />
        </RealtimeCallerImage>
        <RealtimeCallerName>
          {t("Widget.Realtime.header.caller", {
            name: incomingCall && incomingCall.account.name,
          })}
          <p>
            {t("Widget.Realtime.header.assist", { name: props.ping.app.name })}
          </p>
        </RealtimeCallerName>
        <RealtimeButtonsContainer>
          <RealtimeButtonAnswer onClick={onAccept}>
            <RealtimeButtonIcon>
              <Icon type={IconType.Answer} size={24} fill="#fff" />
            </RealtimeButtonIcon>
            <RealtimeButtonTitle>
              {t("Widget.Realtime.buttons.accept")}
            </RealtimeButtonTitle>
          </RealtimeButtonAnswer>

          <RealtimeButtonReject onClick={onReject}>
            <RealtimeButtonIcon>
              <Icon type={IconType.Decline} size={24} fill="#fff" />
            </RealtimeButtonIcon>
            <RealtimeButtonTitle>
              {t("Widget.Realtime.buttons.reject")}
            </RealtimeButtonTitle>
          </RealtimeButtonReject>
        </RealtimeButtonsContainer>
      </RealtimeContainer>
    </If>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  ping: state.ping,
  widget: state.widget,
});

export default connect(mapStateToProps)(Realtime);
