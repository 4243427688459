import React, { FunctionComponent, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Widget } from "app-types/widget";
import { ApplicationState } from "state";
import { SonicDispatchProp } from "sonic";
import { Ping } from "app-types/ping";
import { Survey } from "app-types/survey";
import { SurveyLogic } from "logic";
import Loop from "components/Loop";
import SurveyBasic from "../index";
import { SurveyQuestionType } from "logic/survey";
import { SurveyButton, SurveyButtonText } from "./index.style";
import Spinner from "components/Spinner";
import If from "components/If";

interface OwnProps {
  survey: Survey;
  onReject: Function;
}

interface StateProps {
  widget: Widget;
  ping: Ping;
}

type Props = StateProps & OwnProps & SonicDispatchProp;

const SurveyButtons: FunctionComponent<Props> = (props) => {
  const [currentQuestionId, setCurrentQuestionId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedChoiceId, setSelectedChoiceId] = useState(0);

  useEffect(() => {
    if (currentQuestionId !== props.survey.next_question.survey_question_id) {
      setCurrentQuestionId(props.survey.next_question.survey_question_id);
      setIsLoading(false);
      setSelectedChoiceId(0);
    }
    // eslint-disable-next-line
  }, [props.survey.next_question]);

  const onClick = async (choiceId: number) => {
    setIsLoading(true);
    setSelectedChoiceId(choiceId);

    await props.dispatch(
      SurveyLogic.submitSurveyAnswer({
        surveyId: props.survey.survey_id,
        questionId: props.survey.next_question.survey_question_id,
        questionType: SurveyQuestionType.ClosedQuestion,
        choiceId: choiceId,
      })
    );
  };

  return (
    <SurveyBasic survey={props.survey} dispatch={props.dispatch}>
      <Loop items={props.survey.next_question.choices || []}>
        {(item) => (
          <SurveyButton
            disabled={isLoading}
            key={item.survey_choice_id}
            onClick={() => onClick(item.survey_choice_id)}
          >
            <Spinner
              isLoading={
                isLoading && selectedChoiceId === item.survey_choice_id
              }
            />
            <If
              condition={
                !isLoading || selectedChoiceId !== item.survey_choice_id
              }
            >
              <SurveyButtonText>{item.value}</SurveyButtonText>
            </If>
          </SurveyButton>
        )}
      </Loop>
    </SurveyBasic>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  widget: state.widget,
  ping: state.ping,
});

export default connect(mapStateToProps)(SurveyButtons);
