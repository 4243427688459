import React, { FunctionComponent } from "react";
import { connect } from "react-redux";
import { Widget } from "app-types/widget";
import { ApplicationState } from "state";
import { SonicDispatchProp } from "sonic";
import { Ping } from "app-types/ping";
import { Survey } from "app-types/survey";
import Avatar from "components/Avatar";
import {
  Assigner,
  AssignerImage,
  Header as HeaderDiv,
  QuestionDescription,
  QuestionMetadata,
  QuestionTitle,
} from "./index.style";

interface OwnProps {
  survey: Survey;
}

interface StateProps {
  widget: Widget;
  ping: Ping;
}

type Props = StateProps & OwnProps & SonicDispatchProp;

const Header: FunctionComponent<Props> = (props) => {
  return (
    <HeaderDiv>
      <Assigner>
        <AssignerImage>
          <Avatar accountId={props.survey.account_id} size={40} />
        </AssignerImage>
        <QuestionMetadata>
          <QuestionTitle>{props.survey.next_question.title}</QuestionTitle>
          <QuestionDescription>
            {props.survey.next_question.subtitle}
          </QuestionDescription>
        </QuestionMetadata>
      </Assigner>
    </HeaderDiv>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  widget: state.widget,
  ping: state.ping,
});

export default connect(mapStateToProps)(Header);
