import styled from "styled-components";
import { Direction } from "app-types/widget";

const Notification = styled.div`
  position: absolute !important;
  background: red !important;
  border-radius: 50% !important;
  line-height: 20px !important;
  font-size: 0.8em !important;
  color: #fff !important;
  min-width: 20px !important;
  display: block !important;
  height: 20px !important;
  z-index: 3 !important;
  text-align: center !important;

  animation-duration: 0.4s !important;
  animation-fill-mode: both !important;
  animation-name: zoomIn !important;

  right: ${(props) =>
    props.theme.direction === Direction.Left ? "0" : "auto"} !important;
  left: ${(props) =>
    props.theme.direction === Direction.Right ? "0" : "auto"} !important;
`;

export { Notification };
