import styled from "styled-components";
import { Button } from "../../index.style";
import { Spinner } from "components/Spinner/index.style";
import { CloseContainer as CloseContainerDiv } from "../index.style";

const CloseContainer = styled(CloseContainerDiv)`
  width: 100% !important;
`;

const Survey = styled.div`
  margin: 0 !important;
  overflow: hidden !important;
  background: ${(props) => props.theme.accentColor} !important;
  display: flex !important;
  flex-direction: column !important;
  height: auto !important;
  -webkit-box-pack: end !important;
  min-height: 200px !important;
  max-height: ${(props) => props.theme.widgetHeight}px !important;
  width: 100% !important;
  border-radius: 16px !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0 0 0 1px, rgba(0, 0, 0, 0.15) 0 5px 30px 0,
    rgba(0, 0, 0, 0.05) 0 3px 3px 0 !important;
  margin-top: 8px !important;

  @media (max-width: 460px) {
    z-index: 2147482999 !important;
    width: 100% !important;
    height: 100% !important;
    bottom: 0 !important;
    border-radius: 0 !important;
    max-height: 100% !important;
    transform: none !important;
  }
`;

const SurveyContainer = styled.div`
  display: flex !important;
  flex-direction: column !important;
  height: 100% !important;
  overflow: auto !important;
  padding: 16px !important;
`;

const SurveyBody = styled.div``;

const SurveyThankyou = styled.div`
  margin: 50px 16px 40px !important;
  color: #282828 !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  text-align: center !important;
`;

const SurveyActionBar = styled.div`
  display: flex !important;
  flex-direction: row !important;
  justify-content: end !important;
  align-items: end !important;
  margin: 16px 0 0 !important;
  width: 100% !important;
`;

const SurveySubmitButton = styled(Button)`
  min-width: 30% !important;
  height: 48px !important;
  padding: 0 16px !important;
  justify-content: center !important;

  ${Spinner} {
    border-left: 1.1em solid ${(props) => props.theme.contrastColor} !important;
    width: 6em !important;
    height: 6em !important;
  }
`;

const SurveySubmitButtonText = styled.span`
  padding-right: 8px !important;
`;

export {
  CloseContainer,
  Survey,
  SurveyContainer,
  SurveyBody,
  SurveyThankyou,
  SurveyActionBar,
  SurveySubmitButton,
  SurveySubmitButtonText,
};
