import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { ProfilingMessage } from "../index.style";
import { Ping, Profiling, ProfilingType } from "app-types/ping";
import { ApplicationState } from "state";
import { connect } from "react-redux";
import {
  CardHeader,
  CardTitle,
  Form,
  FormButton,
  FormInputText,
} from "../../../../index.style";
import { CheckboxButton, CheckboxGroup, Description } from "./index.style";
import Icon, { IconType } from "components/Icon";
import { Attachment } from "app-types/message";
import { SonicDispatchProp } from "sonic";
import If from "components/If";
import { AutomaticMessageLogic, CallbackLogic, ProfilingLogic } from "logic";
import { Conversation } from "app-types/conversation";
import Spinner from "components/Spinner";
import { SocketContext } from "components/Socket/SocketContext";
import { Widget } from "app-types/widget";

interface OwnProps {
  conversation?: Conversation;
  profiling: Profiling;
  html: string;
  attachments?: Attachment[];
}

interface StateProps {
  ping: Ping;
  widget: Widget;
}

type Props = StateProps & OwnProps & SonicDispatchProp;

const ProfilingBubble: FunctionComponent<Props> = (props) => {
  const socket = useContext(SocketContext);

  const [textValue, setTextValue] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const onSendText = async (e: any) => {
    e.preventDefault();

    await onSendAttribute(textValue);
  };

  const onSendBoolean = async (e: any, value: string) => {
    e.preventDefault();

    await onSendAttribute(value);
  };

  const onSendAttribute = async (value: string) => {
    if (value === "") {
      return;
    }

    let conversationId = undefined;
    if (props.conversation !== undefined) {
      conversationId = props.conversation.conversation_id;
    }

    if (props.profiling.callback == null) {
      setIsLoading(true);

      await props.dispatch(
        ProfilingLogic.send({
          attribute: props.profiling.attribute,
          value: value,
          conversationId: conversationId,
        })
      );
      await callCustomCallbacks(value);
      socket.attribute(
        props.ping.user.crmhero_user_id,
        props.profiling.attribute,
        value
      );

      setIsLoading(false);
      setIsDisabled(true);

      await props.dispatch(AutomaticMessageLogic.profilingFormTrigger());
    } else {
      setIsLoading(true);

      await props.profiling.callback(
        socket,
        value,
        props.html,
        props.attachments
      );
      await callCustomCallbacks(value);

      setIsLoading(false);
      setIsDisabled(true);
    }
  };

  const callCustomCallbacks = async (value: string) => {
    await props.dispatch(
      CallbackLogic.call({
        function: props.widget.callbacks.onProfilingQuestionAnswered,
        arguments: [props.profiling, value],
      })
    );

    if (props.profiling.attribute === "email") {
      await props.dispatch(
        CallbackLogic.call({
          function: props.widget.callbacks.onLeadGenerated,
          arguments: [value],
        })
      );
    }
  };

  const onChange = async (e: any) => {
    const currentValue = e.target.value;

    setTextValue(currentValue);

    if (props.profiling.validator == null) {
      setIsValid(true);
    } else {
      setIsValid(props.profiling.validator(currentValue));
    }
  };

  useEffect(() => {
    props.dispatch(
      CallbackLogic.call({
        function: props.widget.callbacks.onProfilingQuestionAsked,
        arguments: [props.profiling],
      })
    );
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <ProfilingMessage isAccount={true}>
        <CardHeader>
          <CardTitle>{props.profiling.description}</CardTitle>
        </CardHeader>

        <If
          condition={
            props.profiling.cast === ProfilingType.String ||
            props.profiling.cast === ProfilingType.Date ||
            props.profiling.cast === ProfilingType.Number ||
            props.profiling.cast === ProfilingType.Option
          }
        >
          <Form onSubmit={onSendText}>
            <FormInputText
              type="text"
              value={textValue}
              placeholder={props.profiling.hint}
              disabled={isDisabled || isLoading}
              onSubmit={onSendText}
              onChange={onChange}
            />
            <FormButton
              onClick={onSendText}
              value="Go"
              disabled={!isValid || isLoading || isDisabled}
            >
              <Spinner isLoading={isLoading}>
                <Icon type={IconType.ArrowRight} fill="#ffffff" size={18} />
              </Spinner>
            </FormButton>
          </Form>
          {props.profiling.extra !== undefined && (
            <Description
              dangerouslySetInnerHTML={{ __html: props.profiling.extra }}
            />
          )}
        </If>

        <If condition={props.profiling.cast === ProfilingType.Boolean}>
          <Form>
            <CheckboxGroup>
              <CheckboxButton
                isTrue={true}
                onClick={(e: any) => {
                  onSendBoolean(e, "true");
                }}
                disabled={isLoading || isDisabled}
              >
                <Spinner isLoading={isLoading}>
                  <Icon type={IconType.True} fill="#ffffff" size={18} />
                </Spinner>
              </CheckboxButton>

              <CheckboxButton
                isTrue={false}
                onClick={(e: any) => {
                  onSendBoolean(e, "false");
                }}
                disabled={isLoading || isDisabled}
              >
                <Spinner isLoading={isLoading}>
                  <Icon type={IconType.False} fill="#ffffff" size={18} />
                </Spinner>
              </CheckboxButton>
            </CheckboxGroup>
          </Form>
          <If condition={!!props.profiling.extra}>
            <Description>{props.profiling.extra}</Description>
          </If>
        </If>
      </ProfilingMessage>
    </React.Fragment>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  ping: state.ping,
  widget: state.widget,
});

export default connect(mapStateToProps)(ProfilingBubble);
