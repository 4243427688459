import styled from "styled-components";
import { CoverUp } from "ui/Widget/animation.style";

const Modal = styled(CoverUp)`
  -webkit-box-align: center !important;
  align-items: center !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  justify-content: center !important;
  min-height: 0 !important;
  left: 0 !important;
  position: fixed !important;
  right: 0 !important;
  top: 0 !important;
  z-index: 2147483000 !important;
  height: 100% !important;
  transform: translateY(
    ${(props) => props.theme.widgetHeight * 1.3}px
  ) !important;
`;

const InnerWrapper = styled.div`
  max-width: 100% !important;
  height: 100% !important;
  max-height: 100% !important;
  width: 100% !important;
`;

const Container = styled.div`
  box-sizing: border-box !important;
  background-color: white !important;
  color: currentcolor !important;
  box-shadow: rgba(42, 59, 71, 0.1) 0 1px 1px,
    rgba(42, 59, 71, 0.25) 0 10px 30px !important;
  display: flex !important;
  flex-direction: column !important;
  min-height: 0 !important;
  border-radius: 4px !important;
  max-height: 100% !important;
  height: 100% !important;
  text-decoration: none !important;
  border-width: initial !important;
  border-style: none !important;
  border-color: initial !important;
  border-image: initial !important;
  outline: none !important;
  position: relative !important;
  overflow: hidden !important;
`;

const Header = styled.div`
  height: 100% !important;
  color: white !important;
  transition: height 160ms ease-out 0s !important;
  background: ${(props) => props.theme.accentColor} !important;
`;

const Title = styled.div`
  color: white !important;
  font-size: 18px !important;
  line-height: ${(props) => props.theme.widgetHeaderHeight}px !important;
  text-align: center !important;
`;

const Close = styled.div`
  z-index: 2147483000 !important;
  position: absolute !important;
  width: 48px !important;
  height: 48px !important;
  top: 0 !important;
  left: 0 !important;
  background-position: center center !important;
  margin: 8px !important;
  cursor: pointer !important;

  * {
    cursor: pointer !important;
  }
`;

const Scrollable = styled.div`
  overflow: auto !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  position: absolute !important;
  top: ${(props) => props.theme.widgetHeaderHeight}px !important;
  display: block !important;
  background: #fff !important;
  height: calc(
    100% - ${(props) => props.theme.widgetHeaderHeight}px
  ) !important;
  width: 100% !important;
`;

const Body = styled.div`
  height: 100% !important;
`;

export {
  Modal,
  Header,
  InnerWrapper,
  Container,
  Body,
  Title,
  Scrollable,
  Close,
};
