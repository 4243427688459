import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

/** The props of the `DateTime` component */
export interface DateTimeProps {
  /** The Date to display. Timestamp of the data in seconds */
  date: number;
}

const DateTime: FunctionComponent<DateTimeProps> = (props) => {
  const { t } = useTranslation();
  const date = new Date(props.date * 1000);
  const monthsList = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];
  const monthString = monthsList[date.getMonth()];
  const day = date.getUTCDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return (
    <React.Fragment>
      {`${day} ${t(
        `DateTime.${monthString}`
      )} ${date.getFullYear()} ${hours}:${minutes}`}
    </React.Fragment>
  );
};

export default DateTime;
