import styled from "styled-components";
import { Button } from "../../../../index.style";
import { Spinner } from "components/Spinner/index.style";

const SurveyButton = styled(Button)`
  text-align: center !important;
  min-height: 48px !important;
  width: 100% !important;

  &:not(:first-child) {
    margin-top: 10px !important;
  }

  ${Spinner} {
    margin: 0 auto !important;
    border-left: 1.1em solid ${(props) => props.theme.contrastColor} !important;
    width: 6em !important;
    height: 6em !important;
  }
`;

const SurveyButtonText = styled.span`
  padding: 10px !important;
  display: block !important;
  width: 100% !important;
`;

export { SurveyButton, SurveyButtonText };
