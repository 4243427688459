import React, { FunctionComponent, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Widget } from "app-types/widget";
import { ApplicationState } from "state";
import { SonicDispatchProp } from "sonic";
import { Ping } from "app-types/ping";

import SurveyBasic from "../index";
import { SurveyLogic } from "logic";
import { SurveyQuestionType } from "logic/survey";
import { Survey } from "app-types/survey";
import {
  SurveyActionBar,
  SurveySubmitButton,
  SurveySubmitButtonText,
} from "../../index.style";
import { SurveyTextarea as SurveyTextareaInput } from "./index.style";
import Icon, { IconType } from "components/Icon";
import { useTranslation } from "react-i18next";
import If from "components/If";
import Spinner from "components/Spinner";

interface OwnProps {
  survey: Survey;
  onReject: Function;
}

interface StateProps {
  widget: Widget;
  ping: Ping;
}

type Props = StateProps & OwnProps & SonicDispatchProp;

const SurveyTextarea: FunctionComponent<Props> = (props) => {
  const [answer, setAnswer] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentQuestionId, setCurrentQuestionId] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    if (currentQuestionId !== props.survey.next_question.survey_question_id) {
      setCurrentQuestionId(props.survey.next_question.survey_question_id);
      setIsLoading(false);
      setAnswer("");
    }
    // eslint-disable-next-line
  }, [props.survey.next_question]);

  const onChangeText = (event: React.BaseSyntheticEvent) => {
    setAnswer(event.target.value);
  };

  const onSubmit = async () => {
    setIsLoading(true);

    await props.dispatch(
      SurveyLogic.submitSurveyAnswer({
        surveyId: props.survey.survey_id,
        questionId: props.survey.next_question.survey_question_id,
        questionType: SurveyQuestionType.OpenQuestion,
        answer: answer,
      })
    );
  };

  return (
    <SurveyBasic survey={props.survey} dispatch={props.dispatch}>
      <SurveyTextareaInput
        placeholder={t("Widget.Notification.SurveyNotification.insertAnswer")}
        rows={10}
        value={answer}
        onChange={onChangeText}
      />

      <SurveyActionBar>
        <SurveySubmitButton onClick={onSubmit} disabled={isLoading}>
          <Spinner isLoading={isLoading} />
          <If condition={!isLoading}>
            <SurveySubmitButtonText>
              {t("Widget.Notification.SurveyNotification.next")}
            </SurveySubmitButtonText>
            <Icon type={IconType.ArrowRight} size={24} fill="#fff" />
          </If>
        </SurveySubmitButton>
      </SurveyActionBar>
    </SurveyBasic>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  widget: state.widget,
  ping: state.ping,
});

export default connect(mapStateToProps)(SurveyTextarea);
