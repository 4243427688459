import React, { FunctionComponent } from "react";
import InAppBubble from "../InAppBubble";
import { VideoMessage } from "../index.style";

interface OwnProps {
  isAccount: boolean;
  html: string;
  videoIdentifier: string;
}

interface StateProps {}

type Props = StateProps & OwnProps;

const YoutubeBubble: FunctionComponent<Props> = (props) => {
  return (
    <React.Fragment>
      <InAppBubble isAccount={props.isAccount} html={props.html} />
      <VideoMessage isAccount={props.isAccount}>
        <iframe
          src={`https://www.youtube.com/embed/${props.videoIdentifier}`}
          title="cly-youtube-player"
          width="100%"
          height="140"
          frameBorder="0"
          allowFullScreen
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        />
      </VideoMessage>
    </React.Fragment>
  );
};

export default YoutubeBubble;
