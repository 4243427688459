import React, { FunctionComponent, useEffect, useRef } from "react";
import { Attachment } from "app-types/message";
import Loop from "components/Loop";
import Icon, { IconType } from "components/Icon";
import {
  AttachmentButton,
  AttachmentElement,
  AttachmentFilename,
  AttachmentFilesize,
  AttachmentIcon,
  AttachmentInfo,
  AttachmentList,
  InAppMessage,
} from "../index.style";
import FileSize from "components/FileSize";
import { WidgetMediaPreviewType } from "app-types/widget";
import { SonicDispatchProp } from "sonic";
import { ApplicationState } from "state";
import { connect } from "react-redux";
import { MediaPreviewLogic } from "logic";

interface OwnProps {
  isAccount: boolean;
  html: string;
  attachments?: Attachment[];
}

interface StateProps {}

type Props = StateProps & OwnProps & SonicDispatchProp;

const InAppBubble: FunctionComponent<Props> = (props) => {
  const innerDiv = useRef<HTMLDivElement>(null);

  const onImgClick = async (e: Event) => {
    const target = e.target as HTMLImageElement;

    if (target) {
      await props.dispatch(
        MediaPreviewLogic.open({
          src: target.src,
          type: WidgetMediaPreviewType.Image,
        })
      );
    }
  };

  useEffect(() => {
    if (innerDiv.current) {
      const imgs = innerDiv.current.querySelectorAll("img");

      imgs.forEach((img: HTMLImageElement) => {
        img.addEventListener("click", onImgClick);
      });

      return () => {
        imgs.forEach((img) => {
          img.removeEventListener("click", onImgClick);
        });
      };
    }
  }, [innerDiv]);

  return (
    <React.Fragment>
      {props.html !== "" && (
        <InAppMessage isAccount={props.isAccount}>
          <div
            dangerouslySetInnerHTML={{ __html: props.html }}
            ref={innerDiv}
          />
        </InAppMessage>
      )}

      {props.attachments && props.attachments.length > 0 && (
        <AttachmentList isEmpty={props.html === ""} isAccount={props.isAccount}>
          <Loop items={props.attachments || []}>
            {(attachment, index) => (
              <AttachmentElement key={index}>
                <AttachmentButton
                  isAccount={props.isAccount}
                  onClick={() => window.open(attachment.path)}
                >
                  <AttachmentIcon isAccount={props.isAccount}>
                    <Icon type={IconType.Download} fill="#fff" size={24} />
                  </AttachmentIcon>
                  <AttachmentInfo>
                    <AttachmentFilename isAccount={props.isAccount}>
                      {attachment.name}
                    </AttachmentFilename>
                    <AttachmentFilesize isAccount={props.isAccount}>
                      {" "}
                      <FileSize size={attachment.size} />
                    </AttachmentFilesize>
                  </AttachmentInfo>
                </AttachmentButton>
              </AttachmentElement>
            )}
          </Loop>
        </AttachmentList>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({});

export default connect(mapStateToProps)(InAppBubble);
