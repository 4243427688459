import styled from "styled-components";
import { Button } from "../../../index.style";
import {
  Close as ModalClose,
  Header as ModalHeader,
  Title as ModalTitle,
} from "components/Modal/index.style";
import {
  ListItem as ListItemCard,
  ListItemAbstract as ListItemAbstractCard,
  ListItemAvatar as ListItemAvatarCard,
  ListItemMessage as ListItemMessageCard,
  ListItemName as ListItemNameCard,
} from "../index.style";

interface ItemProps {
  readonly isUnread: boolean;
}

const NewConversationContainer = styled.div`
  position: absolute !important;
  bottom: 24px !important;
  width: 100% !important;
  text-align: center !important;
`;

const NewConversation = styled.div`
  margin: auto !important;
  max-width: 260px !important;
`;

const NewConversationButton = styled(Button)`
  height: 48px !important;
  width: 100% !important;
  justify-content: center !important;
`;

const NewConversationText = styled.span`
  margin-left: 8px !important;
`;

const List = styled.div``;

const Title = styled(ModalTitle)``;

const Header = styled(ModalHeader)``;

const Back = styled(ModalClose)``;

const ListItemsContainer = styled.div`
  position: absolute !important;
  top: 64px !important;
  right: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  background: white !important;
  border-radius: 8px 8px !important;
  overflow: auto !important;
  box-shadow: 0 4px 20px 0 #00000054 !important;
`;

const ListItems = styled.div`
  display: flex !important;
  flex-direction: column !important;
  background: white !important;
  border-radius: 8px 8px !important;
`;

const ListItem = styled(ListItemCard)`
  padding: 16px !important;

  &:last-child {
    margin-bottom: 80px !important;
  }
`;

const ListItemAvatar = styled(ListItemAvatarCard)<ItemProps>``;

const ListItemMessage = styled(ListItemMessageCard)``;

const ListItemText = styled.div`
  display: flex !important;
  -webkit-box-pack: justify !important;
  justify-content: space-between !important;
`;

const ListItemName = styled(ListItemNameCard)<ItemProps>``;

const ListItemTime = styled.div`
  font-size: 12px !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  font-weight: 300 !important;
  color: #555555 !important;
  text-align: right !important;
  overflow: hidden !important;
`;

const ListItemAbstract = styled(ListItemAbstractCard)<ItemProps>``;

export {
  Back,
  List,
  Header,
  NewConversation,
  NewConversationButton,
  NewConversationContainer,
  NewConversationText,
  Title,
  ListItemsContainer,
  ListItems,
  ListItem,
  ListItemAbstract,
  ListItemTime,
  ListItemName,
  ListItemText,
  ListItemMessage,
  ListItemAvatar,
};
