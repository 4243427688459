const BASE_ENDPOINT = "customerly.io";
export const LANDING_ENDPOINT = `https://www.${BASE_ENDPOINT}`;
export const PICTURES_ENDPOINT = `https://pictures.${BASE_ENDPOINT}`;
export const CUSTOMERLY_COLOR = "#2fb6fa";
export const BOT_IDENTIFIER = -666;
export const DEFAULT_WIDGET_ACCENT_COLOR = "#2589FF";
export const DEFAULT_WIDGET_CONTRAST_COLOR = "#FFFFFF";
export const CONSOLE_ERROR_PREFIX = "[customerly] ";
export const SESSION_DURATION = 3600; // 1h
export const DEBUG_QUERY_PARAMETER = "cly_debug";
