import { createSelector } from "reselect";
import { ApplicationState } from "state";
import { Ping } from "app-types/ping";

const unreadSurveySelector = createSelector(
  (state: ApplicationState): Ping => state.ping,
  (ping: Ping) => {
    return ping.unread.surveys[0];
  }
);

export { unreadSurveySelector };
