import { FunctionComponent, useContext, useEffect } from "react";
import { ApplicationState } from "state";
import { connect, useSelector } from "react-redux";
import { Screen, Widget as WidgetState } from "app-types/widget";
import { Ping } from "app-types/ping";
import { SonicDispatchProp } from "sonic";
import { CliMessage, CliType } from "logic/cli";
import {
  ComposeLogic,
  ConversationLogic,
  EventLogic,
  MessageLogic,
  ProfilingLogic,
  WidgetLogic,
} from "logic";
import { SocketContext } from "components/Socket/SocketContext";
import { Subscription } from "rxjs";
import { isVisitorSelector } from "selectors/user";
import { SocketMessage } from "app-types/socket";
import { AudioType, play } from "utils/audio";
import { NewsPayload } from "logic/messenger/message";
import { utcNowMs } from "utils/date";
import { addToQueue } from "utils/local-storage/api-queues-local-storage";

interface OwnProps {}

interface StateProps {
  widget: WidgetState;
  ping: Ping;
}

type Props = StateProps & OwnProps & SonicDispatchProp;

const Listener: FunctionComponent<Props> = (props) => {
  let socketSubscriptions: Subscription[] = [];
  const socket = useContext(SocketContext);
  const isVisitor = useSelector(isVisitorSelector);

  const onWindowMessage = async (event: any) => {
    const apiMessage: CliMessage = event.data;
    const overrideAppId = { app_id: window.customerly.settings.app_id };

    if (apiMessage.type == null) {
      return;
    }

    switch (apiMessage.type) {
      case CliType.Hide:
        await props.dispatch(WidgetLogic.hide());
        break;
      case CliType.Show:
        await props.dispatch(WidgetLogic.show());
        break;
      case CliType.Update:
        window.customerly.settings = Object.assign(
          {},
          window.customerly.settings,
          apiMessage.payload.settings,
          overrideAppId
        );
        await props.dispatch(
          WidgetLogic.setHotReloadIterationStateUpdater(utcNowMs())
        );
        break;
      case CliType.Open:
        await props.dispatch(WidgetLogic.open(Screen.Home));
        break;
      case CliType.Close:
        await props.dispatch(WidgetLogic.close());
        break;
      case CliType.Logout:
        window.customerly.settings = Object.assign(
          {},
          window.customerly.settings,
          overrideAppId
        );

        delete window.customerly.settings["email"];
        delete window.customerly.settings["name"];
        delete window.customerly.settings["attributes"];
        delete window.customerly.settings["company"];

        await props.dispatch(
          WidgetLogic.setHotReloadIterationStateUpdater(utcNowMs())
        );
        break;
      case CliType.Attribute:
        const attribute = apiMessage.payload.name;
        const value = apiMessage.payload.value;

        if (attribute == null || value == null) {
          console.error(
            '[customerly] Attribute requires 2 parameters. Usage: customerly.attribute("my_attribute", "my_value")'
          );
          return;
        }

        if (!isVisitor) {
          let attributeResponse = await props.dispatch(
            ProfilingLogic.send({
              attribute: attribute,
              value: value,
            })
          );
        } else {
          addToQueue(apiMessage);
        }

        break;
      case CliType.Event:
        const name = apiMessage.payload.name;

        if (name == null) {
          console.error(
            '[customerly] Attribute requires 2 parameters. Usage: customerly.attribute("my_attribute", "my_value")'
          );
          return;
        }

        if (!isVisitor) {
          const eventResponse = await props.dispatch(
            EventLogic.send({
              event: name,
            })
          );
        } else {
          addToQueue(apiMessage);
        }

        break;
      case CliType.ShowNewMessage:
        await props.dispatch(
          ConversationLogic.setSelectedConversationStateUpdater(undefined)
        );
        await props.dispatch(
          ComposeLogic.setDefaultMessageStateUpdater(apiMessage.payload.message)
        );
        await props.dispatch(WidgetLogic.open(Screen.ConversationCompose));
        break;

      default:
        break;
    }
  };

  const onSocketMessage = async (socketMessage: SocketMessage) => {
    if (
      !props.widget.visible &&
      socketMessage.account_id !== undefined && // An account wrote.
      (!props.widget.cards.conversation.selected ||
        socketMessage.conversation.conversation_id !==
          props.widget.cards.conversation.selected.conversation_id) // Same conversation
    ) {
      await props.dispatch(WidgetLogic.show());

      play(AudioType.Receive);

      props.dispatch(
        MessageLogic.getNewsUnread({
          conversationId: socketMessage.conversation.conversation_id,
          timestamp: socketMessage.timestamp,
        } as NewsPayload)
      );
    }
  };

  const onLoad = () => {
    const subscribe = async () => {
      window.addEventListener("message", onWindowMessage);

      window.customerly.consumeQueue();

      if (!isVisitor) {
        socketSubscriptions.push(socket.onMessage(onSocketMessage));
      }
    };

    subscribe().catch(console.error);
  };

  const onUnload = () => {
    socketSubscriptions.forEach((subscription) => subscription.unsubscribe());

    window.removeEventListener("message", onWindowMessage);
  };

  useEffect(() => {
    onLoad();

    return onUnload;
    // eslint-disable-next-line
  }, [props.widget.visible]);

  return null;
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  widget: state.widget,
  ping: state.ping,
});

export default connect(mapStateToProps)(Listener);
