import React, { FunctionComponent } from "react";
import { connect, useSelector } from "react-redux";
import { Widget } from "app-types/widget";
import { ApplicationState } from "state";
import { SonicDispatchProp } from "sonic";
import { Ping } from "app-types/ping";
import { useTranslation } from "react-i18next";
import If from "components/If";
import Icon, { IconType } from "components/Icon";
import { WidgetLogic } from "logic";
import { firstNameSelector } from "selectors/user";
import { Close, Description, Header as HeaderDiv, Title } from "./index.style";

interface OwnProps {}

interface StateProps {
  widget: Widget;
  ping: Ping;
}

type Props = StateProps & OwnProps & SonicDispatchProp;

const Header: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const firstName = useSelector(firstNameSelector);

  const onClose = async () => {
    await props.dispatch(WidgetLogic.toggle());
  };

  return (
    <HeaderDiv>
      <If condition={firstName !== undefined}>
        <Title>
          {t("Widget.Messenger.Home.Header.helloUser", { user: firstName })}
        </Title>
      </If>
      <If condition={firstName == null}>
        <Title>
          {t("Widget.Messenger.Home.Header.helloCompany", {
            company: props.ping.app.name,
          })}
        </Title>
      </If>
      <Description>
        <If condition={!!props.ping.app_config.team_intro}>
          {props.ping.app_config.team_intro}
        </If>
        <If condition={!props.ping.app_config.team_intro}>
          {t("Widget.Messenger.Home.Header.teamIntro")}
        </If>
      </Description>
      <Close onClick={onClose}>
        <Icon type={IconType.Close} fill="#fff" size={24} />
      </Close>
    </HeaderDiv>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  widget: state.widget,
  ping: state.ping,
});

export default connect(mapStateToProps)(Header);
