import React, { FunctionComponent } from "react";
import InAppBubble from "../InAppBubble";
import { VideoMessage } from "../index.style";

interface OwnProps {
  isAccount: boolean;
  html: string;
  videoIdentifier: string;
}

interface StateProps {}

type Props = StateProps & OwnProps;

const VimeoBubble: FunctionComponent<Props> = (props) => {
  return (
    <React.Fragment>
      <InAppBubble isAccount={props.isAccount} html={props.html} />

      <VideoMessage isAccount={props.isAccount}>
        <iframe
          src={`https://player.vimeo.com/video/${props.videoIdentifier}`}
          title="cly-vimeo-player"
          width="100%"
          height="138"
          allowFullScreen
        />
      </VideoMessage>
    </React.Fragment>
  );
};

export default VimeoBubble;
