import React, { FunctionComponent, useEffect, useState } from "react";
import Loop from "components/Loop";
import Spinner from "components/Spinner";
import { GifLogic } from "logic";
import { Widget } from "app-types/widget";
import { ApplicationState } from "state";
import { connect } from "react-redux";
import { SonicDispatchProp } from "sonic";
import { Gif } from "app-types/gif";
import Icon, { IconType } from "components/Icon";
import { useTranslation } from "react-i18next";
import {
  GifContainer,
  GifElement,
  GifElementContainer,
  GifInnerContainer,
  GifResult,
  GifResultContainer,
  GifSearch,
} from "./index.style";
import If from "components/If";

interface OwnProps {
  showGIFModal: boolean;
  textareaHeight: number;
  setShowGIFModal: Function;
  onSend: Function;
}

interface StateProps {
  widget: Widget;
}

type Props = StateProps & OwnProps & SonicDispatchProp;

const GIFModal: FunctionComponent<Props> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [lastSearchedKeyword, setLastSearchedKeyword] = useState("");
  const { t } = useTranslation();

  const onClick = (gif: string) => {
    props.onSend(`<img alt="gif" src="${gif}"/>`);
    props.setShowGIFModal(false);
  };

  const onSearch = async (e: any) => {
    e.preventDefault();

    if (keyword === lastSearchedKeyword) {
      return;
    }

    await search();
  };

  const search = async () => {
    setIsLoading(true);

    setLastSearchedKeyword(keyword);
    await props.dispatch(GifLogic.getAll(keyword));

    setIsLoading(false);
  };

  useEffect(() => {
    if (props.showGIFModal) {
      search();
    }

    // eslint-disable-next-line
  }, [props.showGIFModal]);

  return (
    <React.Fragment>
      <If condition={props.showGIFModal}>
        <GifContainer textareaHeight={props.textareaHeight}>
          <GifInnerContainer>
            <GifSearch>
              <form onSubmit={onSearch}>
                <input
                  type={"text"}
                  placeholder={t(
                    "Widget.Messenger.Conversation.Compose.GIFModal.search"
                  )}
                  onSubmit={onSearch}
                  onChange={(e) => setKeyword(e.target.value)}
                />
                <Icon type={IconType.Search} fill="#282828" size={22} />
              </form>
            </GifSearch>
            <GifResultContainer>
              <GifResult>
                <Spinner isLoading={isLoading}>
                  <Loop items={props.widget.cards.compose.gifs}>
                    {(gif: Gif, index) => (
                      <span
                        key={index}
                        onClick={() => {
                          onClick(gif.imageUrl);
                        }}
                      >
                        <GifElementContainer>
                          <GifElement imageUrl={gif.imageUrl} />
                        </GifElementContainer>
                      </span>
                    )}
                  </Loop>
                </Spinner>
              </GifResult>
            </GifResultContainer>
          </GifInnerContainer>
        </GifContainer>
      </If>
    </React.Fragment>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  widget: state.widget,
});

export default connect(mapStateToProps)(GIFModal);
