import React, { FunctionComponent, useState } from "react";
import { connect } from "react-redux";
import { Widget } from "app-types/widget";
import { ApplicationState } from "state";
import Knowledge from "../Knowledge";
import Calendly from "../Calendly";
import Header from "./Header";
import Conversation from "../Conversation";
import { SonicDispatchProp } from "sonic";
import PoweredBy from "./PoweredBy";
import If from "components/If";
import { Ping } from "app-types/ping";
import ArticleModal from "../Knowledge/ArticleModal";
import { CardContainer, Home as HomeDiv, HomeContainer } from "./index.style";
import { Article } from "app-types/knowledge";

interface OwnProps {}

interface StateProps {
  widget: Widget;
  ping: Ping;
}

type Props = StateProps & OwnProps & SonicDispatchProp;

const Home: FunctionComponent<Props> = (props) => {
  const [selectedArticle, setSelectedArticle] = useState<Article | undefined>();
  const [showModal, setShowModal] = useState(false);

  return (
    <HomeContainer>
      <HomeDiv>
        <Header />

        <CardContainer hasPoweredBy={props.ping.app_config.branded_widget}>
          <If condition={!props.ping.app_config.inbound_conversations_disabled}>
            <Conversation />
          </If>
          <If
            condition={
              props.ping.app_config.widget_knowledge_base_enabled &&
              !!props.ping.knowledge.url
            }
          >
            <Knowledge
              setSelectedArticle={setSelectedArticle}
              setShowModal={setShowModal}
            />
          </If>
          <If
            condition={
              props.ping.app_config.calendly_enabled &&
              !!props.ping.app_config.calendly_url
            }
          >
            <Calendly />
          </If>
          {selectedArticle && (
            <ArticleModal
              selectedArticle={selectedArticle}
              showModal={showModal}
              onClose={() => setShowModal(false)}
            />
          )}
        </CardContainer>
      </HomeDiv>

      <If condition={props.ping.app_config.branded_widget}>
        <PoweredBy />
      </If>
    </HomeContainer>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  widget: state.widget,
  ping: state.ping,
});

export default connect(mapStateToProps)(Home);
