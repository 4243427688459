import styled from "styled-components";
import { darken } from "utils/color";

const SurveyTextbox = styled.input.attrs({
  type: "text",
})`
  padding: 16px !important;
  background-color: #ffffff !important;
  border-radius: 8px !important;
  color: #282828 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  resize: none !important;

  -webkit-transition: box-shadow 0.3s ease-in-out, border 0.3s ease-in-out !important;
  -moz-transition: box-shadow 0.3s ease-in-out, border 0.3s ease-in-out !important;
  -ms-transition: box-shadow 0.3s ease-in-out, border 0.3s ease-in-out !important;
  -o-transition: box-shadow 0.3s ease-in-out, border 0.3s ease-in-out !important;
  outline: none !important;
  border: 1px solid #dddddd !important;

  &:focus {
    box-shadow: 0 0 5px ${(props) => darken(props.theme.accentColor, 20)} !important;
    border: 1px solid ${(props) => darken(props.theme.accentColor, 20)} !important;
  }
`;

export { SurveyTextbox };
