import { createSideEffect, createStateUpdater } from "sonic";
import { ApplicationState } from "state";
import AppTypes from "AppTypes";
import { Conversation } from "app-types/conversation";
import { Screen } from "app-types/widget";
import { ConversationLogic } from "./index";

const setScreenStateUpdater = createStateUpdater(
  "widget/screen",
  (state: ApplicationState, screen: Screen) => {
    return {
      ...state,
      widget: {
        ...state.widget,
        open: true,
        screen: screen,
        previousScreen: state.widget.screen,
      },
    };
  }
);

const goToConversationCompose: AppTypes.AppSideEffectCreator<
  Conversation | undefined,
  void
> = createSideEffect(
  "widget/go/conversation-compose",
  async (context, conversation: Conversation | undefined) => {
    await context.dispatch(
      ConversationLogic.setSelectedConversationStateUpdater(conversation)
    );
    await context.dispatch(setScreenStateUpdater(Screen.ConversationCompose));
  }
);

const goToConversationList: AppTypes.AppSideEffectCreator<
  void,
  void
> = createSideEffect("widget/go/conversation-list", async (context) => {
  await context.dispatch(setScreenStateUpdater(Screen.ConversationList));
});

const goToHome: AppTypes.AppSideEffectCreator<void, void> = createSideEffect(
  "widget/go/home",
  async (context) => {
    await context.dispatch(setScreenStateUpdater(Screen.Home));
  }
);

const goTo: AppTypes.AppSideEffectCreator<Screen, void> = createSideEffect(
  "widget/go/to",
  async (context, screen: Screen) => {
    await context.dispatch(setScreenStateUpdater(screen));
  }
);

export default {
  goTo,
  goToHome,
  goToConversationCompose,
  goToConversationList,
};
