import ApiClient from "./api-client";
import { Survey } from "app-types/survey";

const jsonToSurvey = (jsonSurvey: any) => {
  return jsonSurvey as Survey;
};

class SurveyApi {
  constructor(private apiClient: ApiClient) {}

  public seen = async (surveyId: number, timestamp: number): Promise<void> => {
    await this.apiClient.post(`/surveys/seen`, {
      survey_id: surveyId,
      seen_date: timestamp,
    });
  };

  public reject = async (surveyId: number): Promise<Survey> => {
    const survey = await this.apiClient.post(`/surveys/reject`, {
      survey_id: surveyId,
    });

    return jsonToSurvey(survey);
  };

  public submitOpenAnswer = async (
    surveyId: number,
    questionId: number,
    answer: string
  ): Promise<Survey> => {
    const survey = await this.apiClient.post(`/surveys/submit`, {
      survey_id: surveyId,
      answer: answer,
      // choice_id: null
    });

    return jsonToSurvey(survey);
  };

  public submitClosedAnswer = async (
    surveyId: number,
    questionId: number,
    choiceId: number
  ): Promise<Survey> => {
    const survey = await this.apiClient.post(`/surveys/submit`, {
      survey_id: surveyId,
      // answer: null,
      choice_id: choiceId,
    });

    return jsonToSurvey(survey);
  };
}

export default SurveyApi;
