import { createSideEffect } from "sonic";
import AppTypes from "AppTypes";

import {
  getNextProfilingForm,
  shouldShowKnowledgeBase,
  shouldShowKnowledgeBaseAutoSearch,
  shouldShowKnowledgeBaseAutoSearchResult,
  shouldShowKnowledgeBaseAutoSearchStatic,
  shouldShowOfficeHours,
  shouldShowProfilingForm,
} from "utils/automatic-message";
import {
  isNewerThan,
  isOldConversationRestarted,
  isUser,
  joinMessages,
} from "utils/message";
import {
  stripEmotes,
  stripMultipleSpaces,
  stripPunctuation,
} from "utils/string";
import {
  getKnowledgeMessage,
  getKnowledgeMessageAutoSearchResultMessage,
  getKnowledgeMessageAutoSearchStaticMessage,
  getOfficeHourMessage,
  getProfilingMessage,
} from "app-types/automaticMessage";
import { KnowledgeAutoSearchLogic, MessageLogic } from "../index";
import { Message } from "app-types/message";
import {
  AUTOMATIC_MESSAGES_DEFAULT_DELAY,
  AUTOMATIC_MESSAGES_NEXT_DELAY,
  KNOWLEDGEBASE_OLD_MESSAGES_DELTA,
} from "constants/messenger/automatic-message";

interface SendPayload {
  message: Message;
  delay: number;
}

const officeHoursTrigger: AppTypes.AppSideEffectCreator<
  void,
  void
> = createSideEffect(
  "automaticMessages/officeHours/trigger",
  async (context) => {
    let state = context.getState();
    if (state.widget.cards.conversation.selected) {
      if (
        state.widget.cards.conversation.selected.conversation_id == null ||
        state.widget.cards.conversation.selected.messages.length === 0
      ) {
        return;
      }

      if (shouldShowOfficeHours(state.ping, state.widget)) {
        await context.dispatch(
          send({
            message: getOfficeHourMessage(),
            delay: AUTOMATIC_MESSAGES_DEFAULT_DELAY,
          } as SendPayload)
        );
      }
    }
  }
);

const profilingFormTrigger: AppTypes.AppSideEffectCreator<
  void,
  void
> = createSideEffect(
  "automaticMessages/profilingForm/trigger",
  async (context) => {
    let state = context.getState();
    if (state.widget.cards.conversation.selected) {
      if (
        state.widget.cards.conversation.selected.conversation_id == null ||
        state.widget.cards.conversation.selected.messages.length === 0
      ) {
        return;
      }

      if (shouldShowProfilingForm(state.ping, state.widget)) {
        const nextProfilingForm = getNextProfilingForm(
          state.ping,
          state.widget
        );

        if (nextProfilingForm !== undefined) {
          await context.dispatch(
            send({
              message: getProfilingMessage(nextProfilingForm),
              delay: AUTOMATIC_MESSAGES_DEFAULT_DELAY,
            } as SendPayload)
          );
        }
      }
    }
  }
);

const knowledgeBaseTrigger: AppTypes.AppSideEffectCreator<
  void,
  void
> = createSideEffect(
  "automaticMessages/knowledgeBase/trigger",
  async (context) => {
    let state = context.getState();
    if (state.widget.cards.conversation.selected) {
      if (
        state.widget.cards.conversation.selected.conversation_id == null ||
        state.widget.cards.conversation.selected.messages.length === 0
      ) {
        return;
      }

      let userMessages = state.widget.cards.conversation.selected.messages.filter(
        (message) => isUser(message)
      );

      if (
        isOldConversationRestarted(
          userMessages,
          KNOWLEDGEBASE_OLD_MESSAGES_DELTA
        )
      ) {
        userMessages = userMessages.filter((message) =>
          isNewerThan(message, KNOWLEDGEBASE_OLD_MESSAGES_DELTA)
        );
      }

      const keywords = stripMultipleSpaces(
        stripPunctuation(stripEmotes(joinMessages(userMessages)))
      );
      await context.dispatch(KnowledgeAutoSearchLogic.getArticles(keywords));
      state = context.getState();

      let delay = AUTOMATIC_MESSAGES_DEFAULT_DELAY;

      if (shouldShowKnowledgeBaseAutoSearch(state.ping, state.widget)) {
        if (shouldShowKnowledgeBaseAutoSearchStatic(state.ping, state.widget)) {
          await context.dispatch(
            send({
              message: getKnowledgeMessageAutoSearchStaticMessage(),
              delay: delay,
            } as SendPayload)
          );
          delay += AUTOMATIC_MESSAGES_NEXT_DELAY;
        }

        if (shouldShowKnowledgeBaseAutoSearchResult(state.ping, state.widget)) {
          const knowledgeAutoSearchArticles =
            state.widget.cards.compose.knowledge_autosearch.articles;

          for (const article of knowledgeAutoSearchArticles) {
            await context.dispatch(
              send({
                message: getKnowledgeMessageAutoSearchResultMessage(article),
                delay: delay,
              } as SendPayload)
            );
            delay += AUTOMATIC_MESSAGES_NEXT_DELAY;
          }
        }
      } else if (shouldShowKnowledgeBase(state.ping, state.widget)) {
        await context.dispatch(
          send({
            message: getKnowledgeMessage(),
            delay: AUTOMATIC_MESSAGES_DEFAULT_DELAY,
          } as SendPayload)
        );
      }
    }
  }
);

const send: AppTypes.AppSideEffectCreator<SendPayload, void> = createSideEffect(
  "automaticMessages/send",
  async (context, payload) => {
    const state = context.getState();

    setTimeout(async () => {
      if (state.widget.cards.conversation.selected) {
        let messages = state.widget.cards.conversation.selected.messages;

        messages.push(payload.message);

        await context.dispatch(MessageLogic.setMessagesStateUpdater(messages));
      }
    }, payload.delay);
  }
);

export default {
  officeHoursTrigger,
  profilingFormTrigger,
  knowledgeBaseTrigger,
  send,
};
