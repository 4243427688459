import { keyframes } from "styled-components";

const zoomIn = keyframes`
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
`;

const shake = keyframes`
  0% {
    transform: rotate(0);
  }
  15% {
    transform: rotate(5deg);
  }
  30% {
    transform: rotate(-5deg);
  }
  45% {
    transform: rotate(4deg);
  }
  60% {
    transform: rotate(-4deg);
  }
  75% {
    transform: rotate(2deg);
  }
  85% {
    transform: rotate(-2deg);
  }
  92% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(0);
  }
`;

const rotate = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const pulsate = keyframes`
    0% {
        box-shadow:1px 1px 20px #2fb6fa;
    }
    10% {
        box-shadow:1px 1px 40px #2fb6fa;
    }
    20% {
        box-shadow:1px 1px 50px #2fb6fa;
    }
    30% {
        box-shadow:1px 1px 70px #2fb6fa;
    }
    40% {
        box-shadow:1px 1px 90px #2fb6fa;
    }
    50% {
        box-shadow:1px 1px 90px #2fb6fa;
    }
    60% {
        box-shadow:1px 1px 70px #2fb6fa;
    }
    70% {
        box-shadow:1px 1px 50px #2fb6fa;
    }
    80% {
        box-shadow:1px 1px 40px #2fb6fa;
    }
    90% {
        box-shadow:1px 1px 40px #2fb6fa;
    }
    100% {
        box-shadow:1px 1px 20px #2fb6fa;
    }
`;

export { pulsate, rotate, zoomIn, shake };
