import React, { FunctionComponent } from "react";
import { EmojiMessage } from "../index.style";

interface OwnProps {
  isAccount: boolean;
  message: string;
}

interface StateProps {}

type Props = StateProps & OwnProps;

const EmojiBubble: FunctionComponent<Props> = (props) => {
  return (
    <EmojiMessage isAccount={props.isAccount}>{props.message}</EmojiMessage>
  );
};

export default EmojiBubble;
