import styled from "styled-components";
import { lightColor, lighten, opacitizer } from "utils/color";

interface SurveyRadioProps {
  checked: boolean;
}

const normalLightColor = "#e6dad7";
const normalColor = "#ada3a1";
const toRGBA = (color: string, amount: number): string => {
  return `rgba(${opacitizer(color, amount)})`;
};

const SurveyRadioIcon = styled.div<SurveyRadioProps>`
  background-color: ${(props) =>
    props.checked
      ? toRGBA(props.theme.accentColor, 80)
      : "transparent"} !important;
  width: 16px !important;
  height: 16px !important;
  border-radius: 50% !important;
  border: 1px solid
    ${(props) =>
      props.checked
        ? toRGBA(props.theme.accentColor, 60)
        : normalLightColor} !important;
`;

const SurveyRadio = styled.div<SurveyRadioProps>`
  border: 1px solid
    ${(props) =>
      props.checked
        ? toRGBA(props.theme.accentColor, 30)
        : normalLightColor} !important;
  cursor: pointer !important;
  display: flex !important;
  background-color: ${(props) =>
    props.checked
      ? toRGBA(props.theme.accentColor, 20)
      : lightColor} !important;
  border-radius: 8px !important;
  color: ${(props) =>
    props.checked ? props.theme.accentColor : normalColor} !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  align-items: center !important;
  justify-content: left !important;
  text-align: left !important;
  padding: 16px !important;

  &:not(:first-child) {
    margin-top: 10px !important;
  }

  &:hover {
    background: rgba(
      ${(props) => toRGBA(props.theme.accentColor, 5)}
    ) !important;
    box-shadow: 0 1px 6px 0 ${(props) => toRGBA(props.theme.accentColor, 10)} !important;
    border: 1px solid ${(props) => lighten(props.theme.accentColor, 30)} !important;
    color: ${(props) =>
      props.checked
        ? props.theme.accentColor
        : toRGBA(props.theme.accentColor, 70)} !important;
    transition: background 100ms ease-in-out, border 100ms ease-in-out,
      box-shadow 100ms ease-in-out, color 100ms ease-in-out !important;

    ${SurveyRadioIcon} {
      background-color: ${(props) =>
        props.checked
          ? props.theme.accentColor
          : toRGBA(props.theme.accentColor, 20)} !important;
      border: 1px solid ${(props) => toRGBA(props.theme.accentColor, 30)} !important;
      transition: background 100ms ease-in-out, border 100ms ease-in-out,
        color 100ms ease-in-out !important;
    }
  }

  &:active {
    background-color: ${(props) =>
      lighten(props.theme.colorWhite, 20)} !important;
    background-size: 100% !important;
    transition: background 0s !important;
  }

  * {
    cursor: pointer !important;
  }
`;

const SurveyRadioLabel = styled.div`
  margin-left: 10px !important;
  text-align: left !important;
`;

export { SurveyRadio, SurveyRadioIcon, SurveyRadioLabel };
