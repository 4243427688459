import styled from "styled-components";

const SurveyStarBox = styled.div`
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-evenly !important;
  align-items: center !important;
  padding: 24px 40px !important;
  background-color: #ffffff !important;
  border-radius: 8px !important;

  -webkit-transition: box-shadow 0.3s ease-in-out, border 0.3s ease-in-out !important;
  -moz-transition: box-shadow 0.3s ease-in-out, border 0.3s ease-in-out !important;
  -ms-transition: box-shadow 0.3s ease-in-out, border 0.3s ease-in-out !important;
  -o-transition: box-shadow 0.3s ease-in-out, border 0.3s ease-in-out !important;
  outline: none !important;
  cursor: auto !important;
`;

const SurveyStar = styled.div`
  cursor: pointer !important;

  * {
    cursor: pointer !important;
  }
`;

export { SurveyStar, SurveyStarBox };
