import styled from "styled-components";

interface AvatarProps {
  isRounded: boolean;
  size: number;
}

const Avatar = styled.img<AvatarProps>`
  -webkit-border-radius: ${(props) =>
    props.isRounded ? "50%" : "100%"} !important;
  -moz-border-radius: ${(props) =>
    props.isRounded ? "50%" : "100%"} !important;
  border-radius: ${(props) => (props.isRounded ? "50%" : "100%")} !important;
  width: ${(props) => props.size}px !important;
  height: ${(props) => props.size}px !important;
  display: inline-block !important;
`;

export { Avatar };
