import React, { FunctionComponent } from "react";
import { Widget } from "app-types/widget";
import { Ping } from "app-types/ping";
import { SonicDispatchProp } from "sonic";
import { ApplicationState } from "state";
import { connect } from "react-redux";
import {
  Link,
  Text,
  WatermarkContainer,
  WatermarkInnerContainer,
} from "./index.style";
import Icon, { IconType } from "components/Icon";
import { LANDING_ENDPOINT } from "constants/base";
import { Trans } from "react-i18next";
import { getDomain, getRootDomain } from "utils/domain";

interface OwnProps {
  textareaHeight: number;
}

interface StateProps {
  widget: Widget;
  ping: Ping;
}

type Props = StateProps & OwnProps & SonicDispatchProp;

const Watermark: FunctionComponent<Props> = (props) => {
  const referrerUrl = `${LANDING_ENDPOINT}/add-live-chat/?utm_medium=widget&utm_source=customerly&utm_campaign=powered-by-livechat&utm_content=${
    props.ping.app.app_id
  }&partner=${getRootDomain(getDomain())}`;

  return (
    <WatermarkContainer textareaHeight={props.textareaHeight}>
      <WatermarkInnerContainer>
        <Icon type={IconType.Customerly} fill="#a9a9a9" size={20} />
        <Text>
          <Trans i18nKey="Widget.Messenger.Conversation.Compose.Watermark.watermark">
            Add free
            <Link
              href={referrerUrl}
              color={props.widget.accentColor}
              target="_blank"
              rel="noopener dofollow"
            >
              live chat
            </Link>
            to your website
          </Trans>
        </Text>
      </WatermarkInnerContainer>
    </WatermarkContainer>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  widget: state.widget,
  ping: state.ping,
});

export default connect(mapStateToProps)(Watermark);
