import React, { FunctionComponent } from "react";
import { connect } from "react-redux";
import { Widget } from "app-types/widget";
import { ApplicationState } from "state";
import { SonicDispatchProp } from "sonic";
import { LANDING_ENDPOINT } from "constants/base";
import { Ping } from "app-types/ping";
import { useTranslation } from "react-i18next";
import {
  Link,
  PoweredBy as PoweredByDiv,
  PoweredByContainer,
} from "./index.style";
import { getDomain, getRootDomain } from "utils/domain";

interface OwnProps {}

interface StateProps {
  widget: Widget;
  ping: Ping;
}

type Props = StateProps & OwnProps & SonicDispatchProp;

const PoweredBy: FunctionComponent<Props> = (props) => {
  const referrerUrl = `${LANDING_ENDPOINT}/add-live-chat/?utm_medium=widget&utm_source=customerly&utm_campaign=powered-by-survey&utm_content=${
    props.ping.app.app_id
  }&partner=${getRootDomain(getDomain())}`;
  const { t } = useTranslation();

  return (
    <PoweredByContainer>
      <PoweredByDiv>
        <Link href={referrerUrl} target="_blank" rel="noopener dofollow">
          {t("Widget.Notification.SurveyNotification.PoweredBy.poweredBy")}
        </Link>
      </PoweredByDiv>
    </PoweredByContainer>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  widget: state.widget,
  ping: state.ping,
});

export default connect(mapStateToProps)(PoweredBy);
