import React, { FunctionComponent, useEffect } from "react";
import { Ping } from "app-types/ping";
import { Widget } from "app-types/widget";
import { SonicDispatchProp } from "sonic";
import { getParams } from "utils/url";
import { ApplicationState } from "state";
import { connect } from "react-redux";
import { DEBUG_QUERY_PARAMETER } from "constants/base";
import { DebugLogic } from "logic";
import { getDomain } from "utils/domain";

interface OwnProps {}

interface StateProps {
  widget: Widget;
  ping: Ping;
}

type Props = StateProps & OwnProps & SonicDispatchProp;

const Debug: FunctionComponent<Props> = (props) => {
  const urlParams = getParams();

  useEffect(() => {
    const token = urlParams.get(DEBUG_QUERY_PARAMETER);

    if (token) {
      props
        .dispatch(
          DebugLogic.registerDebugMode({ token: token, domain: getDomain() })
        )
        .then();
    }
  }, []);

  return null;
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  ping: state.ping,
  widget: state.widget,
});

export default connect(mapStateToProps)(Debug);
