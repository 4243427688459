import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { Account } from "app-types/account";
import { Message, Typing } from "app-types/message";
import Loop from "components/Loop";
import {
  ConversationLogic,
  MessageLogic,
  NavigatorLogic,
  WidgetLogic,
} from "logic";
import { SonicDispatchProp } from "sonic";
import { Widget } from "app-types/widget";
import { ApplicationState } from "state";
import { connect } from "react-redux";
import { Ping } from "app-types/ping";
import Spinner from "components/Spinner";
import { SocketContext } from "components/Socket/SocketContext";
import { Subscription } from "rxjs";
import { SocketTyping } from "app-types/socket";
import Avatar from "components/Avatar";
import If from "components/If";
import { store } from "../../../../store";
import { Conversation } from "app-types/conversation";
import { SeenPayload } from "logic/messenger/message";
import Icon, { IconType } from "components/Icon";
import { Close, CloseContainer } from "../index.style";
import {
  Author,
  AuthorName,
  AvatarContainer,
  AvatarWrapper,
  Bubble,
  BubbleContainer,
  BubbleList,
  EmailMessage as EmailMessageDiv,
  Message as MessageDiv,
  MessageNotification as MessageNotificationDiv,
  Reply,
  ReplyContainer,
} from "./index.style";
import { useTranslation } from "react-i18next";
import { utcNow } from "utils/date";
import { AudioType, play } from "utils/audio";

interface OwnProps {
  messages: Message[];
}

interface StateProps {
  ping: Ping;
  widget: Widget;
}

type Props = StateProps & OwnProps & SonicDispatchProp;

const MessageNotification: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const socket = useContext(SocketContext);
  let socketSubscriptions: Subscription[] = [];

  const messageIsPresent = props.messages.length > 0;
  const account: Account | undefined = messageIsPresent
    ? props.messages[0].account
    : undefined;
  const conversationId: number | undefined = messageIsPresent
    ? props.messages[0].conversation_id
    : undefined;
  const conversationMessages: Message[] | undefined = messageIsPresent
    ? props.messages.filter(
        (message) => message.conversation_id === conversationId
      )
    : undefined;
  const lastConversationMessageId: number | undefined =
    messageIsPresent && conversationMessages
      ? conversationMessages[conversationMessages.length - 1]
          .conversation_message_id
      : undefined;
  const [isLoading, setIsLoading] = useState(false);
  const [typingOnConversation, setTypingOnConversation] = useState<
    Typing | undefined
  >(undefined);

  const setSeen = () => {
    const now = utcNow();

    if (
      conversationId !== undefined &&
      lastConversationMessageId !== undefined
    ) {
      props
        .dispatch(
          MessageLogic.seen({
            conversationId: conversationId,
            conversationMessageId: lastConversationMessageId,
            timestamp: now,
          } as SeenPayload)
        )
        .then(() => {
          if (conversationId >= 0) {
            socket.seen(
              conversationId,
              lastConversationMessageId,
              props.ping.user.crmhero_user_id,
              now
            );
          } else {
            props.dispatch(ConversationLogic.getAll());
          }
        });
    }

    setEmptyNotifications();
  };

  const setEmptyNotifications = async () => {
    await props.dispatch(
      WidgetLogic.setPingStateUpdater({
        ...props.ping,
        unread: {
          messages: props.ping.unread.messages,
          surveys: [],
        },
      })
    );

    await props.dispatch(WidgetLogic.setNotificationsMessagesStateUpdater([]));

    if (conversationId) {
      await props.dispatch(
        ConversationLogic.discard({
          conversationIds: [conversationId],
        })
      );
    }
  };

  const onReply = async () => {
    const conversationExists = conversationId !== undefined;
    let conversations: Conversation[] = [];
    let conversation: Conversation | undefined = undefined;

    setIsLoading(true);

    if (conversationExists) {
      conversations = await ConversationLogic.getHydratedAll(store);
      conversation = conversations.find(
        (conversation) => conversation.conversation_id === conversationId
      );
    }

    setSeen();
    props.dispatch(NavigatorLogic.goToConversationCompose(conversation)).then();
  };

  const onSocketTyping = (socketTyping: SocketTyping) => {
    if (socketTyping.client) {
      const isAccount = !!socketTyping.client;
      const isAccountTyping = socketTyping.is_typing === "y";
      const isDifferentConversation =
        conversationId !== socketTyping.conversation.conversation_id;

      if (!isAccount && isDifferentConversation) {
        return;
      }

      if (isAccountTyping) {
        setTypingOnConversation({
          conversationId: socketTyping.conversation.conversation_id,
          account: {
            accountId: socketTyping.client.account_id,
            name: socketTyping.client.name,
          },
        });
      } else {
        setTypingOnConversation(undefined);
      }
    }
  };

  const subscribe = () => {
    socketSubscriptions.push(socket.onTyping(onSocketTyping));
  };

  const onLoad = () => {
    if (socket.isConnected()) {
      subscribe();
    } else {
      socket.isInitialized() &&
        socketSubscriptions.push(socket.onConnect(subscribe));
    }

    play(AudioType.Receive);
  };

  const onUnload = () => {
    socketSubscriptions.forEach((subscription) => subscription.unsubscribe());
  };

  useEffect(() => {
    onLoad();

    return onUnload;
    // eslint-disable-next-line
  }, []);

  return (
    <If condition={props.messages.length > 0}>
      <MessageNotificationDiv>
        <CloseContainer>
          <Close onClick={setEmptyNotifications}>
            <Icon type={IconType.CloseFilled} fill="#000" size={20} />
            <span>{t("Widget.Notification.MessageNotification.close")}</span>
          </Close>
        </CloseContainer>

        <BubbleList>
          <Loop items={props.messages}>
            {(item, index) => (
              <BubbleContainer key={item.conversation_message_id}>
                <If condition={index === 0}>
                  <AvatarContainer>
                    <AvatarWrapper counter={0}>
                      <Avatar size={40} accountId={item.account.account_id} />
                    </AvatarWrapper>
                  </AvatarContainer>
                </If>
                <If condition={item.conversation_id === conversationId}>
                  <Bubble onClick={onReply}>
                    <Author>
                      {t("Widget.Notification.MessageNotification.messageFrom")}
                      <AuthorName>{item.account.name}</AuthorName>
                    </Author>
                    <If condition={!!item.rich_mail_link}>
                      <EmailMessageDiv>
                        <Icon type={IconType.Email} fill="#000" size={100} />
                        <p>
                          {t(
                            "Widget.Notification.MessageNotification.sentViaEmail"
                          )}
                        </p>
                      </EmailMessageDiv>
                    </If>
                    <If condition={!item.rich_mail_link}>
                      <MessageDiv
                        dangerouslySetInnerHTML={{ __html: item.content }}
                      />
                    </If>
                  </Bubble>
                </If>
              </BubbleContainer>
            )}
          </Loop>

          <If condition={!!typingOnConversation}>
            <BubbleContainer>
              <Bubble>
                <Author>
                  <AuthorName>
                    {typingOnConversation && typingOnConversation.account.name}
                  </AuthorName>
                  {t("Widget.Notification.MessageNotification.isTyping", {
                    name: "",
                  })}
                </Author>
                <Icon
                  type={IconType.Typing}
                  fill={props.widget.accentColor}
                  size={45}
                />
              </Bubble>
            </BubbleContainer>
          </If>
        </BubbleList>

        {account && (
          <ReplyContainer>
            <Reply onClick={onReply}>
              <Spinner isLoading={isLoading} />
              <If condition={!isLoading}>
                {t("Widget.Notification.MessageNotification.replyTo", {
                  name: account.name,
                })}
              </If>
            </Reply>
          </ReplyContainer>
        )}
      </MessageNotificationDiv>
    </If>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  widget: state.widget,
  ping: state.ping,
});

export default connect(mapStateToProps)(MessageNotification);
