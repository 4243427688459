import styled from "styled-components";

const Header = styled.div`
  position: relative !important;
  height: auto !important;
  color: white !important;
  transition: height 160ms ease-out 0s !important;
  background: ${(props) => props.theme.accentColor}!important;
  margin: 0 0 16px 0 !important;
`;

const Assigner = styled.div`
  display: flex !important;
`;
const AssignerImage = styled.div`
  margin-right: 16px !important;
  align-items: center !important;
  display: flex !important;

  img {
    border: 4px solid #ffffff66 !important;
  }
`;

const QuestionMetadata = styled.div`
  max-width: 250px !important;
  flex-direction: column !important;
  justify-content: center !important;
  display: flex !important;
`;

const QuestionTitle = styled.div`
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  overflow: hidden !important;
`;

const QuestionDescription = styled.div`
  margin-top: 4px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  overflow: hidden !important;
`;

export {
  Header,
  Assigner,
  AssignerImage,
  QuestionMetadata,
  QuestionTitle,
  QuestionDescription,
};
