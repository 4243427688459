import React, { FunctionComponent } from "react";
import Loop from "components/Loop";
import { useTranslation } from "react-i18next";
import {
  EmojiCategory,
  EmojiContainer,
  EmojiElement,
  EmojiInnerContainer,
} from "./index.style";
import If from "components/If";

interface OwnProps {
  message: string;
  setMessage: Function;
  showEmojiModal: boolean;
  textareaHeight: number;
  setShowEmojiModal: Function;
  onSend: Function;
}

interface StateProps {}

type Props = StateProps & OwnProps;

const EmojiModal: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const availableEmojis = [
    "😀",
    "😃",
    "😆",
    "😅",
    "😂",
    "🤣",
    "😊",
    "😇",
    "🙂",
    "🙃",
    "😉",
    "😌",
    "😍",
    "🥰",
    "😘",
    "😗",
    "😙",
    "️😚",
    "😋",
    "😛",
    "😝",
    "😜",
    "🤪",
    "🤨",
    "🧐",
    "🤓",
    "😎",
    "🤩",
    "🥳",
    "😏",
    "😒",
    "😞",
    "😔",
    "😟",
    "😕",
    "🙁",
    "😣",
    "😖",
    "😫",
    "😩",
    "🥺",
    "😢",
    "😭",
    "😤",
    "😠",
    "😡",
    "🤬",
    "🤯",
    "😳",
    "🥵",
    "🥶",
    "😱",
    "😨",
    "😰",
    "😥",
    "😓",
    "🤗",
    "🤔",
    "🤭",
    "🤫",
    "🤥",
    "😶",
    "😐",
    "😑",
    "😬",
    "🙄",
    "😯",
    "😦",
    "😧",
    "😮",
    "😲",
    "😴",
    "🤤",
    "😪",
    "😵",
    "🤐",
    "🥴",
    "🤢",
    "🤮",
    "🤧",
    "😷",
    "🤒",
    "🤕",
    "🤑",
    "🤠",
    "😈",
    "👿",
    "👹",
    "👺",
    "🤡",
    "💩",
    "👻",
    "💀",
    "☠️",
    "👽",
    "👾",
    "🤖",
    "🎃",
    "😺",
    "😸",
    "😹",
    "😻",
    "😼",
    "😽",
    "🙀",
    "😿",
    "🙌",
    "👏",
    "🤝",
    "👎",
    "👊",
    "✊",
    "🤜",
    "🤛",
    "🤞️",
    "✌️",
    "🤘",
    "👌",
    "👈",
    "👉",
    "👆",
    "☝️",
    "✋",
    "💪",
    "🙏",
    "✍️",
    "💋",
    "👄",
    "🦷",
    "👅",
    "👂",
    "👣",
    "👁",
    "👀",
    "🧠",
    "🗣",
    "👤",
    "👥",
    "👶",
    "👧",
    "👲",
  ];
  const mostUsedEmojis = [
    "😀",
    "😎",
    "😍",
    "🤣",
    "👍",
    "❤️",
    "🙏",
    "✌️",
    "👌️",
  ];

  const onClick = (emoji: string) => {
    if (!props.message) {
      props.onSend(emoji);
    } else {
      props.setMessage(`${props.message} ${emoji}`);
    }

    props.setShowEmojiModal(false);
  };

  return (
    <React.Fragment>
      <If condition={props.showEmojiModal}>
        <EmojiContainer textareaHeight={props.textareaHeight}>
          <EmojiInnerContainer>
            <EmojiCategory>
              {t(
                "Widget.Messenger.Conversation.Compose.EmojiModal.frequentlyUsed"
              )}
            </EmojiCategory>
            <Loop items={mostUsedEmojis}>
              {(emoji, index) => (
                <EmojiElement
                  key={index}
                  role="img"
                  aria-label=""
                  onClick={() => {
                    onClick(emoji);
                  }}
                >
                  {emoji}
                </EmojiElement>
              )}
            </Loop>
            <EmojiCategory>
              {t(
                "Widget.Messenger.Conversation.Compose.EmojiModal.smileysPeople"
              )}
            </EmojiCategory>
            <Loop items={availableEmojis}>
              {(emoji, index) => (
                <EmojiElement
                  key={index}
                  role="img"
                  aria-label=""
                  onClick={() => {
                    onClick(emoji);
                  }}
                >
                  {emoji}
                </EmojiElement>
              )}
            </Loop>
          </EmojiInnerContainer>
        </EmojiContainer>
      </If>
    </React.Fragment>
  );
};

export default EmojiModal;
