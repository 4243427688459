import React, { FunctionComponent } from "react";
import { connect } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { Screen, Widget } from "app-types/widget";
import { ApplicationState } from "state";
import Home from "./Home";
import { SonicDispatchProp } from "sonic";
import { Ping } from "app-types/ping";
import ConversationList from "./Conversation/List";
import ConversationCompose from "./Conversation/Compose";
import { Messenger as MessengerDiv, MessengerContainer } from "./index.style";
import { fadeInUpAnimationName } from "../animation.style";

interface OwnProps {}

interface StateProps {
  widget: Widget;
  ping: Ping;
}

type Props = StateProps & OwnProps & SonicDispatchProp;

const Messenger: FunctionComponent<Props> = (props) => {
  return (
    <CSSTransition
      in={props.widget.open}
      timeout={400}
      classNames={fadeInUpAnimationName}
      appear
      unmountOnExit
    >
      <MessengerDiv>
        <MessengerContainer>
          {props.widget.screen === Screen.Home && <Home />}
          {props.widget.screen === Screen.ConversationList && (
            <ConversationList />
          )}
          {props.widget.screen === Screen.ConversationCompose && (
            <ConversationCompose />
          )}
        </MessengerContainer>
      </MessengerDiv>
    </CSSTransition>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  widget: state.widget,
  ping: state.ping,
});

export default connect(mapStateToProps)(Messenger);
