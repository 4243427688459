import React, { FunctionComponent } from "react";
import { InAppMessage } from "../index.style";
import { useTranslation } from "react-i18next";
import If from "components/If";
import { OfficeHour, OfficeHourNext, ReplyTime } from "app-types/ping";
import { ApplicationState } from "state";
import { connect } from "react-redux";
import { getDateFromTimestamp, getHoursFromDate } from "utils/date";
import { getOfficeHour, OfficeHourType } from "utils/office-hour";

interface OwnProps {
  replyTime: ReplyTime;
  officeHours: OfficeHour[];
  officeHoursNext: OfficeHourNext | undefined;
}

interface StateProps {}

type Props = StateProps & OwnProps;

const OfficeHourBubble: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();

  const selectedOfficeHour = getOfficeHour(
    props.officeHoursNext,
    props.replyTime
  );

  return (
    <React.Fragment>
      <InAppMessage isAccount={true}>
        <If condition={selectedOfficeHour === OfficeHourType.ReplyInMinutes}>
          {t(
            "Widget.Messenger.Conversation.Compose.Bubble.OfficeHourBubble.replyInMinutes"
          )}
        </If>
        <If condition={selectedOfficeHour === OfficeHourType.ReplyInAnHour}>
          {t(
            "Widget.Messenger.Conversation.Compose.Bubble.OfficeHourBubble.replyInAnHour"
          )}
        </If>
        <If condition={selectedOfficeHour === OfficeHourType.ReplyInADay}>
          {t(
            "Widget.Messenger.Conversation.Compose.Bubble.OfficeHourBubble.replyInADay"
          )}
        </If>
        <If condition={selectedOfficeHour === OfficeHourType.DuringWeekends}>
          {t(
            "Widget.Messenger.Conversation.Compose.Bubble.OfficeHourBubble.duringWeekends",
            {
              from:
                props.officeHoursNext &&
                getHoursFromDate(
                  getDateFromTimestamp(props.officeHoursNext.start_utc)
                ),
              to:
                props.officeHoursNext &&
                getHoursFromDate(
                  getDateFromTimestamp(props.officeHoursNext.end_utc)
                ),
            }
          )}
        </If>
        <If condition={selectedOfficeHour === OfficeHourType.DuringWeekdays}>
          {t(
            "Widget.Messenger.Conversation.Compose.Bubble.OfficeHourBubble.duringWeekdays",
            {
              from:
                props.officeHoursNext &&
                getHoursFromDate(
                  getDateFromTimestamp(props.officeHoursNext.start_utc)
                ),
              to:
                props.officeHoursNext &&
                getHoursFromDate(
                  getDateFromTimestamp(props.officeHoursNext.end_utc)
                ),
            }
          )}
        </If>
        <If condition={selectedOfficeHour === OfficeHourType.WillBackToday}>
          {t(
            "Widget.Messenger.Conversation.Compose.Bubble.OfficeHourBubble.willBackToday",
            {
              from:
                props.officeHoursNext &&
                getHoursFromDate(
                  getDateFromTimestamp(props.officeHoursNext.start_utc)
                ),
            }
          )}
        </If>
        <If condition={selectedOfficeHour === OfficeHourType.WillBackTomorrow}>
          {t(
            "Widget.Messenger.Conversation.Compose.Bubble.OfficeHourBubble.willBackTomorrow",
            {
              from:
                props.officeHoursNext &&
                getHoursFromDate(
                  getDateFromTimestamp(props.officeHoursNext.start_utc)
                ),
            }
          )}
        </If>
        <If condition={selectedOfficeHour === OfficeHourType.WillBackOn}>
          {t(
            "Widget.Messenger.Conversation.Compose.Bubble.OfficeHourBubble.willBackOn",
            {
              day: t(
                `Widget.Messenger.Conversation.Compose.Bubble.OfficeHourBubble.day${
                  props.officeHoursNext &&
                  getDateFromTimestamp(props.officeHoursNext.start_utc).getDay()
                }`
              ),
              from:
                props.officeHoursNext &&
                getHoursFromDate(
                  getDateFromTimestamp(props.officeHoursNext.start_utc)
                ),
            }
          )}
        </If>
      </InAppMessage>
    </React.Fragment>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  ping: state.ping,
});

export default connect(mapStateToProps)(OfficeHourBubble);
