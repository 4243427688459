// jsonTo declarations

import { OfficeHourNext, ReplyTime } from "./ping";

export const jsonToPredicate = (jsonPredicate: any): Predicate => {
  return jsonPredicate as Predicate;
};

export const predicateToCondition = (predicate: Predicate): Condition => {
  return predicate as Condition;
};

// Predicate declarations

export type Predicate = Operation | Condition;

export interface PredicateAbstract {
  type: PredicateType;
}

export enum PredicateType {
  Operation = "operation",
  Condition = "condition",
}

export interface PredicateMeta {
  office_hours_next: OfficeHourNext;
  reply_time: ReplyTime;
}

// Operation declarations

export interface Operation extends PredicateAbstract {
  operator: OperationOperator;
  predicates: Predicate[];
}

export enum OperationOperator {
  And = "and",
  Or = "or",
}

// Condition declarations

export type Condition =
  | ConditionOfficeHours
  | ConditionPage
  | ConditionStats
  | ConditionPlatform;

export interface ConditionAbstract extends PredicateAbstract {
  operation: ConditionOperation;
  value: any;
  target: ConditionTargetBackend | ConditionTargetFrontend;
}

type ConditionOperation =
  | ConditionOperationBase
  | ConditionStateOperation
  | ConditionTagOperation
  | ConditionTypeOperation
  | ConditionPageOperation
  | ConditionStatsOperation
  | ConditionOfficeHoursOperation
  | ConditionPlatformOperation;

export enum ConditionOperationBase {
  PrefixStateDate = "date",
  PrefixPageTime = "time",
  PrefixPageUrlParameter = "url_parameter",
  PrefixPageUrl = "url",
  PrefixPageDomain = "domain",
  PrefixStatsSession = "session",
  PrefixStatsTotal = "total",
  PrefixStatsPageViews = "page_views",
  PrefixStatsViews = "views",
  PrefixPlatformDevice = "device",

  SuffixDeviceMobile = "mobile",
  SuffixDeviceDesktop = "desktop",

  GreaterThanX = "greater_than",
  MoreThanXDaysAgo = "more_than",
  MoreThanXHoursAgo = "more_than_hours",
  LessThanX = "less_than",
  LessThanXDaysAgo = "less_than",
  LessThanXHoursAgo = "less_than_hours",
  MoreThanXSeconds = "more_than_seconds",
  LessThanXSeconds = "less_than_seconds",
  HasAnyValue = "has_any_value",
  IsUnknown = "is_unknown",
  Equal = "equal_to",
  NotEqual = "not_equal",
  StartsWith = "starts_with",
  EndsWith = "ends_with",
  Contains = "contains",
  NotContains = "does_not_contain",
  OnDayXDaysAgo = "exactly",
  AfterDayX = "after",
  OnDayX = "on",
  BeforeDayX = "before",
  Is = "is",
  IsNot = "is_not",
  IsTrue = "is_true",
  IsFalse = "is_false",
}

export enum ConditionTargetBackend {
  State = "state",
  Type = "type",
  Tag = "tag",
}

export enum ConditionTargetFrontend {
  Page = "page",
  Stats = "stats",
  OfficeHours = "office_hours",
  Platform = "platform",
}

export const ConditionTarget = {
  ...ConditionTargetBackend,
  ...ConditionTargetFrontend,
};
export type ConditionTarget = typeof ConditionTarget;

// Conditions operations declarations

export enum ConditionStateOperation {
  HasAnyValue = ConditionOperationBase.HasAnyValue,
  AnyOpIsUnknown = ConditionOperationBase.IsUnknown,
  BoolIsTrue = ConditionOperationBase.IsTrue,
  BoolIsFalse = ConditionOperationBase.IsFalse,
  NumberGreaterThanX = ConditionOperationBase.GreaterThanX,
  NumberLessThanX = ConditionOperationBase.LessThanX,
  NumberEqual = ConditionOperationBase.Equal,
  NumberNotEqual = ConditionOperationBase.NotEqual,
  DateMoreThanXDaysAgo = ConditionOperationBase.PrefixStateDate +
    "_" +
    ConditionOperationBase.MoreThanXDaysAgo,
  DateLessThanXDaysAgo = ConditionOperationBase.PrefixStateDate +
    "_" +
    ConditionOperationBase.LessThanXDaysAgo,
  DateMoreThanXHoursAgo = ConditionOperationBase.PrefixStateDate +
    "_" +
    ConditionOperationBase.MoreThanXHoursAgo,
  DateLessThanXHoursAgo = ConditionOperationBase.PrefixStateDate +
    "_" +
    ConditionOperationBase.LessThanXHoursAgo,
  DateOnDayXDaysAgo = ConditionOperationBase.PrefixStateDate +
    "_" +
    ConditionOperationBase.OnDayXDaysAgo,
  DateBeforeDayX = ConditionOperationBase.PrefixStateDate +
    "_" +
    ConditionOperationBase.BeforeDayX,
  DateAfterDayX = ConditionOperationBase.PrefixStateDate +
    "_" +
    ConditionOperationBase.AfterDayX,
  DateOnDayX = ConditionOperationBase.PrefixStateDate +
    "_" +
    ConditionOperationBase.OnDayX,
  StringIs = ConditionOperationBase.Is,
  StringIsNot = ConditionOperationBase.IsNot,
  StringStartsWith = ConditionOperationBase.StartsWith,
  StringEndsWith = ConditionOperationBase.EndsWith,
  StringContains = ConditionOperationBase.Contains,
  StringNotContains = ConditionOperationBase.NotContains,
}

export enum ConditionTagOperation {
  TagIs = ConditionOperationBase.Is,
  TagIsNot = ConditionOperationBase.IsNot,
}

export enum ConditionTypeOperation {
  Is = ConditionOperationBase.Is,
}

export enum ConditionPageOperation {
  TimeMoreThanSeconds = ConditionOperationBase.PrefixPageTime +
    "_" +
    ConditionOperationBase.MoreThanXSeconds,
  TimeLessThanSeconds = ConditionOperationBase.PrefixPageTime +
    "_" +
    ConditionOperationBase.LessThanXSeconds,
  UrlParameterIs = ConditionOperationBase.PrefixPageUrlParameter +
    "_" +
    ConditionOperationBase.Is,
  UrlParameterIsNot = ConditionOperationBase.PrefixPageUrlParameter +
    "_" +
    ConditionOperationBase.IsNot,
  UrlIs = ConditionOperationBase.PrefixPageUrl +
    "_" +
    ConditionOperationBase.Is,
  UrlIsNot = ConditionOperationBase.PrefixPageUrl +
    "_" +
    ConditionOperationBase.IsNot,
  UrlContains = ConditionOperationBase.PrefixPageUrl +
    "_" +
    ConditionOperationBase.Contains,
  UrlNotContains = ConditionOperationBase.PrefixPageUrl +
    "_" +
    ConditionOperationBase.NotContains,
  DomainIs = ConditionOperationBase.PrefixPageDomain +
    "_" +
    ConditionOperationBase.Is,
  DomainIsNot = ConditionOperationBase.PrefixPageDomain +
    "_" +
    ConditionOperationBase.IsNot,
}

export enum ConditionStatsOperation {
  SessionPageViewsGreaterThanX = ConditionOperationBase.PrefixStatsSession +
    "_" +
    ConditionOperationBase.PrefixStatsPageViews +
    "_" +
    ConditionOperationBase.GreaterThanX,
  SessionPageViewsLessThanX = ConditionOperationBase.PrefixStatsSession +
    "_" +
    ConditionOperationBase.PrefixStatsPageViews +
    "_" +
    ConditionOperationBase.LessThanX,
  TotalPageViewsGreaterThanX = ConditionOperationBase.PrefixStatsTotal +
    "_" +
    ConditionOperationBase.PrefixStatsPageViews +
    "_" +
    ConditionOperationBase.GreaterThanX,
  TotalPageViewsLessThanX = ConditionOperationBase.PrefixStatsTotal +
    "_" +
    ConditionOperationBase.PrefixStatsPageViews +
    "_" +
    ConditionOperationBase.LessThanX,
  SessionViewsGreaterThanX = ConditionOperationBase.PrefixStatsSession +
    "_" +
    ConditionOperationBase.PrefixStatsViews +
    "_" +
    ConditionOperationBase.GreaterThanX,
  SessionViewsLessThanX = ConditionOperationBase.PrefixStatsSession +
    "_" +
    ConditionOperationBase.PrefixStatsViews +
    "_" +
    ConditionOperationBase.LessThanX,
  TotalViewsGreaterThanX = ConditionOperationBase.PrefixStatsTotal +
    "_" +
    ConditionOperationBase.PrefixStatsViews +
    "_" +
    ConditionOperationBase.GreaterThanX,
  TotalViewsLessThanX = ConditionOperationBase.PrefixStatsTotal +
    "_" +
    ConditionOperationBase.PrefixStatsViews +
    "_" +
    ConditionOperationBase.LessThanX,
}

export enum ConditionOfficeHoursOperation {
  Is = ConditionOperationBase.Is,
  IsNot = ConditionOperationBase.IsNot,
}

export enum ConditionPlatformOperation {
  DeviceIsMobile = ConditionOperationBase.PrefixPlatformDevice +
    "_" +
    ConditionOperationBase.Is +
    "_" +
    ConditionOperationBase.SuffixDeviceMobile,
  DeviceIsDesktop = ConditionOperationBase.PrefixPlatformDevice +
    "_" +
    ConditionOperationBase.Is +
    "_" +
    ConditionOperationBase.SuffixDeviceDesktop,
}

// Conditions declarations

export interface ConditionOfficeHours extends ConditionAbstract {}

export interface ConditionPage extends ConditionAbstract {
  key?: string;
}

export interface ConditionStats extends ConditionAbstract {}

export interface ConditionPlatform extends ConditionAbstract {}
