export const LS_S = [
  "S",
  "g",
  "A",
  "j",
  "F",
  "D",
  "J",
  "h",
  "y",
  "?",
  "&",
  "b",
  "7",
  "$",
  "-",
  "e",
]; // LocalStorage Secret
export const LOCALSTORAGE_NAMESPACE = "customerly_messenger";
export const LOCALSTORAGE_I18NEXT = "i18next";
export const LOCALSTORAGE_TRIGGERS = "triggers";
export const LOCALSTORAGE_STATS = "stats";
export const LOCALSTORAGE_STATS_PAGES = "pages";
export const LOCALSTORAGE_FAKE_MESSAGE = "fake_messages";
export const LOCALSTORAGE_FAKE_MESSAGE_CONVERSATIONS = "conversations";
export const LOCALSTORAGE_QUEUES = "queues";
