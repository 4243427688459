import { getDomain } from "./domain";
import Cookies from "universal-cookie";

const setCookie = (key: string, value: string): void => {
  const cookies = new Cookies(document.cookie);
  const domain = getDomain(),
    domainSplit = domain.split("."),
    domainLength = domainSplit.length;
  const isLocalhost = domain.toLowerCase() === "localhost";

  let expirationDate = new Date();
  expirationDate.setFullYear(expirationDate.getFullYear() + 1);

  const iterationStart = isLocalhost ? 1 : 2;
  for (let i = iterationStart; i <= domainLength; i++) {
    cookies.set(key, value, {
      path: "/",
      domain: domainSplit.slice(-i).join("."),
      expires: expirationDate,
      sameSite: "lax",
    });

    const assertInserted = getCookie(key) === value;
    if (assertInserted) {
      return;
    }
  }
};

const getCookie = (key: string): string => {
  const cookies = new Cookies(document.cookie);

  return cookies.get(key);
};

export { getCookie, setCookie };
