import {
  Callbacks,
  Cards,
  ComposeCard,
  ConversationCard,
  Direction,
  KnowledgeCard,
  Screen,
  Widget,
} from "app-types/widget";
import { Ping, Profiling } from "app-types/ping";
import { DEFAULT_WIDGET_ACCENT_COLOR } from "constants/base";
import { Article } from "app-types/knowledge";

export interface ApplicationState {
  ping: Ping;
  widget: Widget;
}

export const initialState: ApplicationState = {
  ping: {} as Ping,
  widget: {
    initialized: false,
    debug: false,
    open: false,
    cards: {
      compose: {
        defaultMessage: "",
        gifs: [],
        knowledge_autosearch: {
          articles: [],
        },
      } as ComposeCard,
      knowledge: {
        articles: [],
      } as KnowledgeCard,
      conversation: {
        conversations: [],
        selected: undefined,
      } as ConversationCard,
    } as Cards,
    locale: "en",
    screen: Screen.Home,
    previousScreen: Screen.Home,

    direction: Direction.Right,
    accentColor: DEFAULT_WIDGET_ACCENT_COLOR,
    visible: true,
    visibleOnMobile: true,
    autodetectLocale: true,
    screenshotAvailable: true,
    attachmentsAvailable: true,
    singleConversation: false,

    callbacks: {
      onChatOpened: () => {},
      onChatClosed: () => {},
      onProfilingQuestionAsked: (profiling: Profiling) => {},
      onProfilingQuestionAnswered: (profiling: Profiling, answer: any) => {},
      onLeadGenerated: (email: string) => {},
      onNewConversation: () => {},
      onRealtimeVideoAnswered: () => {},
      onRealtimeVideoRejected: () => {},
      onHelpCenterArticleOpened: (article: Article) => {},
    } as Callbacks,
  } as Widget,
};
