import {
  getItem,
  getKeysStartingWith,
  removeItem,
  setItem,
} from "./local-storage";
import { LOCALSTORAGE_STATS } from "constants/local-storage";
import {
  emptyStatsObject,
  StatsObject,
  StatsPage,
  StatsType,
} from "app-types/stats";

const getStatsObject = (): StatsObject | undefined => {
  const item = getItem(LOCALSTORAGE_STATS);

  return isStatsObjectValid(item) ? item : undefined;
};

const getOrNewStatsObject = () => {
  const statsObject = getStatsObject();

  return statsObject ? statsObject : emptyStatsObject;
};

const isStatsObjectValid = (object: any) => {
  const keys = object && Object.keys(object);

  return keys && keys.includes("total") && keys.includes("session");
};

const setStatsObject = (statsObject: StatsObject): void => {
  return setItem(LOCALSTORAGE_STATS, statsObject);
};

const getPage = (path: string, type: StatsType): StatsPage | undefined => {
  const statsObject = getStatsObject();

  switch (type) {
    case StatsType.Total:
      return statsObject?.total.pages[path];
    case StatsType.Session:
      return statsObject?.session?.pages[path];
    default:
      return undefined;
  }
};

const cleanOldLocalStorageStats = () => {
  const keys = getKeysStartingWith(`${LOCALSTORAGE_STATS}/`);

  keys.forEach((key) => {
    removeItem(key);
  });
};

export {
  getPage,
  getStatsObject,
  setStatsObject,
  getOrNewStatsObject,
  cleanOldLocalStorageStats,
};
