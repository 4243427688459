import { createSelector } from "reselect";
import { ApplicationState } from "state";
import { Direction, Widget, WidgetPosition } from "app-types/widget";

interface Theme {
  position: WidgetPosition;
  direction: Direction;
  accentColor: string;
  contrastColor: string;
  widgetHeight: number;
  widgetWidth: number;
  widgetHeaderHeight: number;
}

const themeSelector = createSelector(
  (state: ApplicationState): Widget => state.widget,
  (widget: Widget) =>
    ({
      position: widget.position,
      direction: widget.direction,
      accentColor: widget.accentColor,
      contrastColor: widget.contrastColor,
      widgetHeight: 600,
      widgetWidth: 360,
      widgetHeaderHeight: 64,
    } as Theme)
);

export { themeSelector };
