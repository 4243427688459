import React, { FunctionComponent, useEffect, useRef } from "react";
import { Widget } from "app-types/widget";
import { Ping } from "app-types/ping";
import { SonicDispatchProp } from "sonic";
import { ApplicationState } from "state";
import { connect } from "react-redux";
import { TriggerLogic } from "logic";
import { getPredicateMeta } from "utils/predicate";
import { TriggerComputePayload } from "logic/trigger";

interface OwnProps {}

interface StateProps {
  widget: Widget;
  ping: Ping;
}

type Props = StateProps & OwnProps & SonicDispatchProp;

const Trigger: FunctionComponent<Props> = (props) => {
  const launchedTriggers = useRef<boolean>(false);

  useEffect(() => {
    launchedTriggers.current = false;
  }, [props.widget.hotReloadIteration]);

  useEffect(() => {
    if (!launchedTriggers.current) {
      launchedTriggers.current = true;
      const triggers = TriggerLogic.getAvailable(props.ping);
      const predicateMeta = getPredicateMeta(props.ping);

      for (const trigger of triggers) {
        props.dispatch(
          TriggerLogic.compute({
            trigger: trigger,
            predicateMeta: predicateMeta,
          } as TriggerComputePayload)
        );
      }
    }
  });

  return null;
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  ping: state.ping,
  widget: state.widget,
});

export default connect(mapStateToProps)(Trigger);
