import enFallbackTranslation from ".cache/locales/en/translation.json";
import { ReadCallback } from "i18next";

const localResources: { [locale: string]: any } = {
  en: {
    translation: enFallbackTranslation,
  },
};

const FileSystemBackend = {
  type: "backend",
  init: function (services?: any, options?: any) {},
  read: function (language: string, namespace: string, callback: ReadCallback) {
    if (localResources[language]) {
      callback(null, localResources[language][namespace]);
    } else {
      // Types in callback does not permit undefined
      // @ts-ignore
      callback(null, undefined);
    }
  },
};

export { FileSystemBackend };
