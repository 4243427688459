import ApiClient from "./api-client";

class DebugApi {
  constructor(private apiClient: ApiClient) {}

  public registerInstall = async (
    token: string,
    domain: string
  ): Promise<void> => {
    await this.apiClient.post("/debug/register-install", {
      token: token,
      domain: domain,
    });
  };
}

export default DebugApi;
