import styled from "styled-components";
import { Ul } from "../../index.style";
import { CardTitle } from "../index.style";

const CardTitleRestricted = styled(CardTitle)`
  width: 48% !important;
`;

const ArticleSearchResult = styled.div`
  padding: 8px 0 !important;
  color: gray !important;
  text-align: center !important;
`;

const ArticleSearchList = styled(Ul)``;

const ArticleSearchItem = styled.div`
  cursor: pointer !important;
  border-top: 1px solid #8080801a !important;
  padding: 8px !important;

  * {
    cursor: pointer !important;
  }

  &:hover {
    background: #f7f7f7 !important;
  }

  &:last-child {
    border-bottom: none !important;
  }
`;

const ArticleSearchTitle = styled.span`
  font-weight: 500 !important;
`;

const ArticleSearchDescription = styled.span`
  font-size: 12px !important;
  color: #898989 !important;
`;

const ArticleSearchSeeMore = styled.a`
  text-decoration: none !important;
  text-align: center !important;
  color: ${(props) => props.theme.accentColor} !important;
`;

export {
  ArticleSearchResult,
  ArticleSearchList,
  ArticleSearchItem,
  ArticleSearchTitle,
  ArticleSearchDescription,
  ArticleSearchSeeMore,
  CardTitleRestricted,
};
