import styled from "styled-components";

interface IframeProps {
  isLoading: boolean;
}

const Iframe = styled.iframe<IframeProps>`
  width: 100% !important;
  height: 100% !important;
  border: 0 !important;
  display: ${(props) => (props.isLoading ? "none" : "block")} !important;
`;

export { Iframe };
