import { createStateUpdater } from "sonic";
import AppTypes from "AppTypes";

const setDefaultMessageStateUpdater = createStateUpdater(
  "compose/set-default-message",
  (state: AppTypes.AppState, defaultMessage: string) => {
    return {
      ...state,
      widget: {
        ...state.widget,
        cards: {
          ...state.widget.cards,
          compose: {
            ...state.widget.cards.compose,
            defaultMessage: defaultMessage,
          },
        },
      },
    };
  }
);

export default {
  setDefaultMessageStateUpdater,
};
