import React, { FunctionComponent, useState } from "react";
import Spinner from "../Spinner";
import { Iframe as IframeElement } from "./index.style";

interface OwnProps {
  url: string;
}

interface StateProps {}

type Props = StateProps & OwnProps;

const Iframe: FunctionComponent<Props> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const onLoad = () => {
    // Delay to let rendering to complete
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  return (
    <React.Fragment>
      <IframeElement
        title="cly-iframe"
        isLoading={isLoading}
        src={props.url}
        onLoad={onLoad}
      />
      <Spinner isLoading={isLoading} />
    </React.Fragment>
  );
};

export default Iframe;
