import styled from "styled-components";
import { Direction } from "app-types/widget";
import {
  getWidgetBottomPositionDesktop,
  getWidgetBottomPositionMobile,
  getWidgetSidePositionDesktop,
  getWidgetSidePositionMobile,
} from "utils/theme";

const Close = styled.button`
  cursor: pointer !important;
  background: #f6f6f6 !important;
  padding: 4px 8px !important;
  border-radius: 16px !important;
  border: 1px solid #e6e6e6 !important;
  font-size: 14px !important;
  color: #6f6f6f !important;
  display: flex !important;
  align-items: center !important;

  * {
    cursor: pointer !important;
  }

  svg {
    fill: #bfbfbf !important;
  }

  span {
    margin-left: 4px !important;
    line-height: 20px !important;
  }
`;

const CloseContainer = styled.div`
  position: absolute !important;
  right: ${(props) =>
    props.theme.direction === Direction.Left ? "auto" : 0} !important;
  left: ${(props) =>
    props.theme.direction === Direction.Left ? 0 : "auto"} !important;
  top: -32px !important;
  width: 80% !important;
  display: flex !important;
  justify-content: ${(props) =>
    props.theme.direction === Direction.Left
      ? "flex-end"
      : "flex-start"} !important;
`;

const Notification = styled.div`
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #455a64 !important;
  line-height: 20px !important;
  position: fixed !important;
  z-index: 2147482996 !important;
  bottom: ${(props) =>
    72 + getWidgetBottomPositionMobile(props.theme.position)}px !important;

  width: 360px !important;
  border-radius: 10px !important;

  right: ${(props) =>
    props.theme.direction === Direction.Right
      ? `${getWidgetSidePositionMobile(props.theme.position)}px`
      : "auto"} !important;
  left: ${(props) =>
    props.theme.direction === Direction.Left
      ? `${getWidgetSidePositionMobile(props.theme.position)}px`
      : "auto"} !important;

  @media (min-width: 460px) {
    bottom: ${(props) =>
      72 + getWidgetBottomPositionDesktop(props.theme.position)}px !important;

    right: ${(props) =>
      props.theme.direction === Direction.Right
        ? `${getWidgetSidePositionDesktop(props.theme.position)}px`
        : "auto"} !important;
    left: ${(props) =>
      props.theme.direction === Direction.Left
        ? `${getWidgetSidePositionDesktop(props.theme.position)}px`
        : "auto"} !important;
  }
`;

export { Notification, CloseContainer, Close };
