import styled from "styled-components";

interface CardContainerProps {
  hasPoweredBy: boolean;
}

const HomeContainer = styled.div``;

const Home = styled.div``;

const CardContainer = styled.div<CardContainerProps>`
  margin: ${(props) =>
    props.hasPoweredBy ? "0 16px 64px" : "0 16px 16px"} !important;
`;

export { HomeContainer, Home, CardContainer };
