import { Widget } from "app-types/widget";
import { Ping, Profiling, ProfilingType, User } from "app-types/ping";
import {
  KnowledgeAutoSearchResultTypeMessage,
  KnowledgeAutoSearchStaticTypeMessage,
  KnowledgeTypeMessage,
  OfficeHourTypeMessage,
} from "app-types/automaticMessage";
import { isAccount, isBot, isOldConversationRestarted } from ".//message";
import { isValid } from "./email";
import i18n from "i18next";
import { KNOWLEDGEBASE_OLD_MESSAGES_DELTA } from "constants/messenger/automatic-message";

const shouldTriggerKnowledgeBase = (ping: Ping, widget: Widget): boolean => {
  if (!widget.cards.conversation.selected) {
    return false;
  }

  const accountMessages = widget.cards.conversation.selected.messages.filter(
    (message) => isAccount(message)
  );
  const userNotLoggedBlocked =
    ping.user.crmhero_user_id == null && !ping.app_config.allow_anonymous_chat;
  const areMessagesPresent =
    widget.cards.conversation.selected &&
    widget.cards.conversation.selected.messages.length > 0;

  return (
    (accountMessages.length === 0 ||
      isOldConversationRestarted(
        widget.cards.conversation.selected.messages,
        KNOWLEDGEBASE_OLD_MESSAGES_DELTA
      )) &&
    !userNotLoggedBlocked &&
    areMessagesPresent &&
    ping.app_config.widget_knowledge_base_enabled
  );
};

const shouldShowKnowledgeBaseAutoSearch = (
  ping: Ping,
  widget: Widget
): boolean => {
  if (!widget.cards.conversation.selected) {
    return false;
  }

  const articles = widget.cards.compose.knowledge_autosearch.articles;

  return ping.app_config.widget_knowledge_base_enabled && articles.length > 0;
};

const shouldShowKnowledgeBaseAutoSearchStatic = (
  ping: Ping,
  widget: Widget
): boolean => {
  if (!widget.cards.conversation.selected) {
    return false;
  }

  const autoSearchStaticMessages = widget.cards.conversation.selected.messages.filter(
    (message) =>
      isBot(message) && message.type === KnowledgeAutoSearchStaticTypeMessage
  );

  return (
    ping.app_config.widget_knowledge_base_enabled &&
    ping.app_config.autosearch_enabled &&
    autoSearchStaticMessages.length === 0
  );
};

const shouldShowKnowledgeBaseAutoSearchResult = (
  ping: Ping,
  widget: Widget
): boolean => {
  if (!widget.cards.conversation.selected) {
    return false;
  }

  const autoSearchResultMessages = widget.cards.conversation.selected.messages.filter(
    (message) =>
      isBot(message) && message.type === KnowledgeAutoSearchResultTypeMessage
  );

  return (
    ping.app_config.widget_knowledge_base_enabled &&
    ping.app_config.autosearch_enabled &&
    autoSearchResultMessages.length === 0
  );
};

const shouldShowOfficeHours = (ping: Ping, widget: Widget): boolean => {
  if (!widget.cards.conversation.selected) {
    return false;
  }

  const messagesFromAccountOrBot = widget.cards.conversation.selected.messages.filter(
    (message) =>
      isAccount(message) ||
      (isBot(message) && message.type === OfficeHourTypeMessage)
  );

  return messagesFromAccountOrBot.length === 0;
};

const shouldShowKnowledgeBase = (ping: Ping, widget: Widget): boolean => {
  if (!widget.cards.conversation.selected) {
    return false;
  }

  const messagesFromBot = widget.cards.conversation.selected.messages.filter(
    (message) => isBot(message) && message.type === KnowledgeTypeMessage
  );

  return (
    ping.app_config.widget_knowledge_base_enabled &&
    !!ping.knowledge.url &&
    messagesFromBot.length === 0
  );
};

const shouldShowProfilingForm = (ping: Ping, widget: Widget): boolean => {
  if (!widget.cards.conversation.selected) {
    return false;
  }

  const messagesFromAccount = widget.cards.conversation.selected.messages.filter(
    (message) => isAccount(message)
  );

  return (
    messagesFromAccount.length === 0 &&
    getNextProfilingForm(ping, widget) !== undefined
  );
};

const getNextProfilingForm = (
  ping: Ping,
  widget: Widget
): Profiling | undefined => {
  const getPossibleProfilingAttributes = (user: User) => {
    const attributes = Object.keys(user);
    const bannedAttributes = ["attributes", "crmhero_user_id"];

    return attributes.filter(
      (attribute) => bannedAttributes.indexOf(attribute) === -1
    );
  };
  const getPossibleProfilingCustomAttributes = (user: User) => {
    return Object.keys(user.attributes);
  };

  const profilingsWithEmail: Profiling[] = [
    {
      attribute: "email",
      description: i18n.t(
        "Widget.Messenger.Conversation.Compose.Bubble.ProfilingBubble.leaveYourEmail"
      ),
      hint: i18n.t(
        "Widget.Messenger.Conversation.Compose.Bubble.ProfilingBubble.emailPlaceholder"
      ),
      cast: ProfilingType.String,
      validator: (email: string) => {
        return isValid(email);
      },
    } as Profiling,
    ...ping.profilings,
  ];

  for (let profiling of profilingsWithEmail) {
    const possibleProfilingAttributes = getPossibleProfilingAttributes(
      ping.user
    );
    const possibleProfilingCustomAttributes = getPossibleProfilingCustomAttributes(
      ping.user
    );

    if (possibleProfilingAttributes.indexOf(profiling.attribute) > 0) {
      if (!ping.user[profiling.attribute]) {
        return profiling;
      }
    } else {
      if (
        possibleProfilingCustomAttributes.indexOf(profiling.attribute) === -1
      ) {
        return profiling;
      }

      if (
        ping.user.attributes[profiling.attribute] === "" ||
        ping.user.attributes[profiling.attribute] == null ||
        ping.user.attributes[profiling.attribute] === null
      ) {
        return profiling;
      }
    }
  }

  return undefined;
};

export {
  shouldTriggerKnowledgeBase,
  shouldShowOfficeHours,
  shouldShowKnowledgeBase,
  shouldShowKnowledgeBaseAutoSearch,
  shouldShowKnowledgeBaseAutoSearchStatic,
  shouldShowKnowledgeBaseAutoSearchResult,
  shouldShowProfilingForm,
  getNextProfilingForm,
};
