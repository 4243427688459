import styled from "styled-components";
import { Direction } from "app-types/widget";
import { zoomIn } from "../keyframes.style";
import {
  getWidgetBottomPositionDesktop,
  getWidgetBottomPositionMobile,
  getWidgetSidePositionDesktop,
  getWidgetSidePositionMobile,
} from "utils/theme";

const LauncherContainer = styled.div`
  bottom: ${(props) =>
    getWidgetBottomPositionMobile(props.theme.position)}px !important;

  position: fixed !important;
  width: 60px !important;
  height: 60px !important;
  cursor: pointer !important;
  display: block !important;

  animation-duration: 0.4s !important;
  animation-fill-mode: both !important;
  animation-name: ${zoomIn} !important;

  right: ${(props) =>
    props.theme.direction === Direction.Right
      ? `${getWidgetSidePositionMobile(props.theme.position)}px`
      : "auto"} !important;
  left: ${(props) =>
    props.theme.direction === Direction.Left
      ? `${getWidgetSidePositionMobile(props.theme.position)}px`
      : "auto"} !important;

  z-index: 10000 !important;

  * {
    cursor: pointer !important;
  }

  @media (min-width: 460px) {
    bottom: ${(props) =>
      getWidgetBottomPositionDesktop(props.theme.position)}px !important;

    right: ${(props) =>
      props.theme.direction === Direction.Right
        ? `${getWidgetSidePositionDesktop(props.theme.position)}px`
        : "auto"} !important;
    left: ${(props) =>
      props.theme.direction === Direction.Left
        ? `${getWidgetSidePositionDesktop(props.theme.position)}px`
        : "auto"} !important;
  }
`;

const Launcher = styled.div`
  -webkit-transition: transform 0.2s ease-in-out !important;
  -moz-transition: transform 0.2s ease-in-out !important;
  -ms-transition: transform 0.2s ease-in-out !important;
  -o-transition: transform 0.2s ease-in-out !important;
  transition: transform 0.2s ease-in-out !important;

  &:hover {
    transform: scale(1.05) !important;
  }
`;

export { LauncherContainer, Launcher };
