enum Day {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

const utcNow = (): number => {
  const now = new Date();

  return Math.floor(now.getTime() / 1000);
};

const utcNowMs = (): number => {
  const now = new Date();

  return Math.floor(now.getTime());
};

const getToday = (): Date => {
  return new Date();
};

const getTomorrow = (): Date => {
  const date = new Date(getToday());
  date.setDate(date.getDate() + 1);

  return date;
};

const getDateFromTimestamp = (timestamp: number): Date => {
  const date = new Date(0);
  date.setUTCSeconds(timestamp);

  return date;
};

function getHoursFromDate(date: Date): string {
  // @ts-ignore
  return date
    .toLocaleTimeString()
    .match(/\d{1,2}:\d{2}|[AMP]+/g)
    .join(" ")
    .toLowerCase();
}

export {
  Day,
  utcNow,
  utcNowMs,
  getTomorrow,
  getToday,
  getHoursFromDate,
  getDateFromTimestamp,
};
