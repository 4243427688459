import { WidgetSetting } from "app-types/widget";

export interface CliMessage {
  type: string;
  payload: any;
}

export enum CliType {
  Attribute = "attribute",
  Event = "event",
  Update = "update",
  Logout = "logout",
  Show = "show",
  Hide = "hide",
  Open = "open",
  Close = "close",
  ShowNewMessage = "showNewMessage",
}

export class Cli {
  public settings: WidgetSetting;

  public queue: any;

  constructor() {
    this.settings =
      (window.customerly && window.customerly.settings) ||
      window.customerlySettings;
    this.queue = (window.customerly && window.customerly.queue) || [];
  }

  public consumeQueue = () => {
    this.queue.forEach((elements: any) => {
      const cliMessage = {
        type: elements[0],
        payload: {},
      } as CliMessage;

      switch (elements[0]) {
        case CliType.Hide:
        case CliType.Show:
        case CliType.Open:
        case CliType.Close:
        case CliType.Logout:
          break;
        case CliType.Update:
          cliMessage.payload = {
            settings: elements[1] || {},
          };
          break;
        case CliType.Attribute:
          cliMessage.payload = {
            name: elements[1],
            value: elements[2],
          };
          break;
        case CliType.Event:
          cliMessage.payload = {
            name: elements[1],
          };
          break;
        case CliType.ShowNewMessage:
          cliMessage.payload = {
            message: elements[1],
          };
          break;
      }

      window.postMessage(cliMessage, "*");
    });
    this.queue = [];
  };

  event = (name: string) => {
    this.send(CliType.Event, { name: name });
  };

  attribute = (name: string, value: any) => {
    this.send(CliType.Attribute, { name: name, value: value });
  };

  show = () => {
    this.send(CliType.Show);
  };

  hide = () => {
    this.send(CliType.Hide);
  };

  open = () => {
    this.send(CliType.Open);
  };

  close = () => {
    this.send(CliType.Close);
  };

  logout = () => {
    this.send(CliType.Logout);
  };

  update = (settings: any) => {
    this.send(CliType.Update, { settings: settings });
  };

  showNewMessage = (message: any) => {
    this.send(CliType.ShowNewMessage, { message: message });
  };

  private send = (type: string, payload: any = null) => {
    window.postMessage({ type: type, payload: payload } as CliMessage, "*");
  };
}
