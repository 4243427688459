import ApiClient from "./api-client";
import { Gif, jsonToGif } from "app-types/gif";

class GifApi {
  constructor(private apiClient: ApiClient) {}

  /** Returns all the gifs that matches a keyword */
  public getAll = async (keyword: string): Promise<Gif[]> => {
    const response = await this.apiClient.post("/gifs", {
      keyword: keyword,
    });

    return response.map((gif: any) => jsonToGif(gif));
  };
}

export default GifApi;
