import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";

import Messenger from "./Messenger";
import Launcher from "./Launcher";
import Listener from "./Listener";
import Realtime from "./Realtime";
import { connect, useSelector } from "react-redux";
import {
  Direction,
  UserInformation,
  Widget as WidgetState,
} from "app-types/widget";
import { WidgetContainer } from "./index.style";
import { ApplicationState } from "state";
import { WidgetLogic } from "logic";
import { SonicDispatchProp } from "sonic";
import If from "components/If";
import { isMobile } from "utils/platform";
import { Ping } from "app-types/ping";
import { autodetectLocale } from "utils/intl";
import { isEmpty } from "utils/object";
import { I18nextProvider, initReactI18next } from "react-i18next";
import { SocketContext } from "components/Socket/SocketContext";
import internationalization from "i18next";
import Backend from "i18next-chained-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import HttpBackend from "i18next-http-backend";
import Notification from "./Notification";
import { getContrastColor } from "utils/color";
import { themeSelector } from "selectors/theme";
import { ThemeProvider } from "styled-components";
import { getWidgetURL } from "utils/url";
import { DEFAULT_WIDGET_ACCENT_COLOR } from "constants/base";
import {
  LOCALSTORAGE_I18NEXT,
  LOCALSTORAGE_NAMESPACE,
} from "constants/local-storage";
import { getReferrers } from "utils/marketing";
import { isWorkingHour } from "utils/office-hour";
import Trigger from "./Trigger";
import Stats from "./Stats";
import MediaPreview from "./MediaPreview";
import { FileSystemBackend } from "../../i18n";
import { getNotDiscardedMessages } from "utils/message";
import Debug from "ui/Widget/Debug";

interface OwnProps {}

interface StateProps {
  widget: WidgetState;
  ping: Ping;
}

type Props = StateProps & OwnProps & SonicDispatchProp;

const Widget: FunctionComponent<Props> = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [afterPing, setAfterPing] = useState(false);
  const socket = useContext(SocketContext);
  const theme = useSelector(themeSelector);

  useEffect(() => {
    const setUp = () => {
      const ifNotUndefined = (element: any): any => {
        return element !== undefined;
      };

      // @ts-ignore
      window.customerly.settings.accentColor = [
        window.customerly.settings.accentColor,
        window.customerly.settings.color,
        props.ping.app_config?.widget_color,
        props.widget.accentColor,
        DEFAULT_WIDGET_ACCENT_COLOR,
      ].find(ifNotUndefined);
      // @ts-ignore
      window.customerly.settings.contrastColor = [
        window.customerly.settings.contrastColor,
        window.customerly.settings.text_color,
        getContrastColor(window.customerly.settings.accentColor),
      ].find(ifNotUndefined);
      // @ts-ignore
      window.customerly.settings.autodetectLocale = [
        window.customerly.settings.autodetectLocale,
        window.customerly.settings.autodetect_language,
        props.widget.autodetectLocale,
      ].find(ifNotUndefined);
      // @ts-ignore
      window.customerly.settings.visible = [
        window.customerly.settings.visible,
        window.customerly.settings.widget_visible,
        props.widget.visible,
      ].find(ifNotUndefined);
      // @ts-ignore
      window.customerly.settings.visibleOnMobile = [
        window.customerly.settings.visibleOnMobile,
        window.customerly.settings.widget_visible_mobile,
        props.widget.visibleOnMobile,
      ].find(ifNotUndefined);
      // @ts-ignore
      window.customerly.settings.attachmentsAvailable = [
        window.customerly.settings.attachmentsAvailable,
        window.customerly.settings.attachments_available,
        props.widget.attachmentsAvailable,
      ].find(ifNotUndefined);
      // @ts-ignore
      window.customerly.settings.screenshotAvailable = [
        window.customerly.settings.screenshotAvailable,
        window.customerly.settings.screenshot_available,
        props.widget.screenshotAvailable,
      ].find(ifNotUndefined);
    };

    const initialLoading = async () => {
      setUp();

      setAfterPing(false);

      await props.dispatch(
        WidgetLogic.setWidgetStateUpdater({
          ...props.widget,
          ...window.customerly.settings,
          initialized: true,
        })
      );

      let userInformation = window.customerly.settings as UserInformation;
      let marketingReferrers = getReferrers();

      for (let key in marketingReferrers) {
        if (marketingReferrers.hasOwnProperty(key)) {
          if (!userInformation.attributes) {
            userInformation.attributes = {};
          }

          userInformation.attributes[key] = marketingReferrers[key];
        }
      }

      await props.dispatch(WidgetLogic.getPing(userInformation));

      setAfterPing(true);
    };

    initialLoading().catch(console.error);
    // eslint-disable-next-line
  }, [props.widget.hotReloadIteration]);

  useEffect(() => {
    const afterPingCallback = async () => {
      const isDevelopment = process.env.NODE_ENV === "development";
      const backends = isDevelopment
        ? [HttpBackend]
        : [LocalStorageBackend, FileSystemBackend, HttpBackend];
      const backendOptions = isDevelopment
        ? [
            {
              loadPath: `${getWidgetURL()}/locales/{{lng}}/{{ns}}.json`,
            },
          ]
        : [
            {
              prefix: `${LOCALSTORAGE_NAMESPACE}/${LOCALSTORAGE_I18NEXT}/`,
              expirationTime: 30 * 24 * 60 * 60 * 1000,
              defaultVersion: process.env.REACT_APP_VERSION,
              store: window.localStorage,
            },
            {},
            {
              loadPath: `${getWidgetURL()}/locales/{{lng}}/{{ns}}.json`,
            },
          ];

      const updateWidget = async (
        locale: string,
        accentColor: string,
        direction: Direction
      ) => {
        await props
          .dispatch(
            WidgetLogic.setWidgetStateUpdater({
              ...props.widget,
              locale: locale,
              direction: direction,
              accentColor: accentColor,
              contrastColor: getContrastColor(accentColor),
              notifications: {
                ...props.widget.notifications,
                messages: props.widget.notifications
                  ? props.widget.notifications.messages
                  : getNotDiscardedMessages(props.ping.unread.messages),
              },
              mediaPreview: {
                isVisible: false,
              },
            })
          )
          .catch(console.error);
      };
      const initLocalization = async (locale: string) => {
        internationalization
          .use(Backend)
          .use(initReactI18next)
          .init({
            backend: {
              backends: backends,
              backendOptions: backendOptions,
            },
            fallbackLng: "en",
            lng: locale,
            debug: false,
            react: {
              useSuspense: false,
            },
            interpolation: {
              escapeValue: false, // not needed for react as it escapes by default
            },
          })
          .catch(console.error);
      };

      const locale = props.widget.autodetectLocale
        ? autodetectLocale(props.ping.app_config.default_language)
        : props.ping.app_config.default_language;
      let direction =
        props.ping.app_config.widget_position === 0
          ? Direction.Left
          : Direction.Right;

      if (
        window.customerly.settings.direction === Direction.Left ||
        window.customerly.settings.direction === Direction.Right
      ) {
        direction = window.customerly.settings.direction;
      }
      const accentColor =
        window.customerly.settings.accentColor === DEFAULT_WIDGET_ACCENT_COLOR
          ? props.ping.app_config.widget_color
          : window.customerly.settings.accentColor;
      window.customerly.settings.accentColor = accentColor;

      await updateWidget(locale, accentColor, direction);
      await initLocalization(locale);

      if (props.ping.websocket.token) {
        socket.init(
          props.ping.websocket.endpoint,
          props.ping.websocket.port,
          props.ping.websocket.token
        );
      }

      setIsLoading(false);
    };

    !isEmpty(props.ping) &&
      !!afterPing &&
      afterPingCallback().catch(console.error);
    // eslint-disable-next-line
  }, [afterPing]);

  const shouldShowWidget = (): boolean => {
    if (props.ping.user && props.ping.user.spam) {
      return false;
    }

    if (!props.widget.visible) {
      return false;
    }

    if (isMobile() && !props.widget.visibleOnMobile) {
      return false;
    }

    if (props.ping.app_config == null) {
      return false;
    }

    if (
      props.ping.unread.surveys.length > 0 ||
      props.ping.unread.messages.length > 0
    ) {
      return true;
    }

    if (
      !props.ping.app_config.widget_visible_lead &&
      !props.ping.user.is_user
    ) {
      return false;
    }

    if (!props.ping.app_config.widget_visible_user && props.ping.user.is_user) {
      return false;
    }

    const isOutOfOffice = !isWorkingHour(
      props.ping.app_config.office_hours_next,
      props.ping.app_config.reply_time
    );

    return (
      !isOutOfOffice ||
      (isOutOfOffice && props.ping.app_config.widget_visible_out_office_hour)
    );
  };

  return (
    <If condition={!isLoading}>
      <ThemeProvider theme={theme}>
        <SocketContext.Provider value={socket}>
          <Debug />
          <Listener />
          <If condition={shouldShowWidget()}>
            <I18nextProvider i18n={internationalization}>
              <WidgetContainer>
                <Realtime />
                <Stats />
                <Trigger />
                <Messenger />
                <Notification />
                <Launcher />
                <MediaPreview />
              </WidgetContainer>
            </I18nextProvider>
          </If>
        </SocketContext.Provider>
      </ThemeProvider>
    </If>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  widget: state.widget,
  ping: state.ping,
});

export default connect(mapStateToProps)(Widget);
