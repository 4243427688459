import React, { FunctionComponent, useState } from "react";
import { Widget, WidgetMediaPreviewType } from "app-types/widget";
import { SonicDispatchProp } from "sonic";
import { ApplicationState } from "state";
import { connect } from "react-redux";
import { Ping } from "app-types/ping";
import If from "components/If";
import {
  Close,
  MediaPreviewImage,
  MediaPreviewImageContainer,
  MediaPreviewOverlay,
} from "./index.style";
import { MediaPreviewLogic } from "logic";
import Icon, { IconType } from "components/Icon";
import Spinner from "components/Spinner";
import { useTranslation } from "react-i18next";

interface OwnProps {}

interface StateProps {
  widget: Widget;
  ping: Ping;
}

type Props = StateProps & OwnProps & SonicDispatchProp;

const MediaPreview: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);

  const onClose = () => {
    setIsImageLoaded(false);
    props.dispatch(MediaPreviewLogic.close());
  };

  return (
    <React.Fragment>
      <If condition={props.widget.mediaPreview.isVisible}>
        <MediaPreviewOverlay onClick={onClose} />
        <If
          condition={
            props.widget.mediaPreview.type === WidgetMediaPreviewType.Image
          }
        >
          <MediaPreviewImageContainer>
            <Spinner isLoading={!isImageLoaded} />

            <Close onClick={onClose} color={props.widget.accentColor}>
              <Icon type={IconType.CloseFilled} fill="#000" size={20} />
              <span>{t("Widget.Notification.MessageNotification.close")}</span>
            </Close>
            <MediaPreviewImage
              src={props.widget.mediaPreview.src}
              show={isImageLoaded}
              onLoad={() => setIsImageLoaded(true)}
            />
          </MediaPreviewImageContainer>
        </If>
      </If>
    </React.Fragment>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  ping: state.ping,
  widget: state.widget,
});

export default connect(mapStateToProps)(MediaPreview);
