import { Account } from "./account";
import { Predicate } from "./predicate";
import { getRandomConversationMessageId, Message } from "./message";
import { utcNow } from "utils/date";
import { User } from "./user";

// Trigger declarations

export interface Trigger {
  trigger_id: number;
  creator: Account;
  sender: Account;
  status: TriggerStatus;
  created_at: number;
  is_repeatable: number;

  events: TriggerEvent[];
  conditions: Predicate;
  actions: TriggerAction[];
}

export interface LocalTrigger {
  trigger_id: number;
  sent: boolean;
  sent_at: number;
  conversation_id?: number;
}

// TriggerEvent declarations

export type TriggerEvent =
  | TriggerEventWidgetLoad
  | TriggerEventCssSelector
  | TriggerEventLeaveIntent;

export interface TriggerEventAbstract {
  type: TriggerEventType;
}

export enum TriggerStatus {
  Draft = 0,
  Stopped = 1,
  Active = 2,
  StoppedByUsage = 3,
}

export enum TriggerEventType {
  WidgetLoad = "widget_load",
  CssSelector = "css_selector",
  LeaveIntent = "leave_intent",
}

export interface TriggerEventWidgetLoad extends TriggerEventAbstract {
  delay: number;
}

export interface TriggerEventLeaveIntent extends TriggerEventAbstract {}

export enum TriggerEventCssSelectorAction {
  Click = "click",
  Hover = "hover",
}

export interface TriggerEventCssSelector extends TriggerEventAbstract {
  delay: number;
  action: TriggerEventCssSelectorAction;
  selector: string;
}

// TriggerAction declarations

export type TriggerAction = TriggerActionMessage;

export interface TriggerActionAbstract {
  type: TriggerActionType;
}

export interface HashedContent {
  content: string;
  hash: string;
}

export enum TriggerActionType {
  Message = "message",
}

export interface TriggerActionMessage extends TriggerActionAbstract {
  default_content: HashedContent;
  contents: TriggerLocalizedMessage[];
  delay: number;
}

export interface TriggerLocalizedMessage {
  locale: string;
  content: HashedContent;
}

export const getMessageFromTriggerActionMessage = (
  content: HashedContent,
  sender: Account,
  conversationId: number
): Message => {
  return {
    abstract: "",
    account: sender,
    assigner: null,
    assigner_id: null,
    assigner_type: null,
    attachments: [],
    close_conversation: false,
    content: content.content,
    content_hash: content.hash,
    conversation_id: conversationId,
    conversation_message_id: getRandomConversationMessageId(),
    discarded: false,
    in_app: true,
    mail_raw: null,
    mail_sent: false,
    rich_mail: false,
    rich_mail_link: "",
    rich_mail_token: "",
    seen_date: undefined,
    sent_date: utcNow(),
    subject: "",
    type: 0,
    user: {} as User,
  } as Message;
};
