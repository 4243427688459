import styled from "styled-components";

interface ItemProps {
  readonly isUnread: boolean;
}

const ListContainer = styled.div`
  display: flex !important;
  flex-direction: column !important;
`;

const ListItem = styled.div`
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  align-content: stretch !important;
  position: relative !important;
  outline-offset: -5px !important;
  padding: 16px 8px !important;
  cursor: pointer !important;

  &:hover {
    background: #f7f7f7 !important;
  }

  &:before {
    content: "" !important;
    position: absolute !important;
    height: 100% !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    border-top: 2px solid transparent !important;
  }

  &:after {
    content: "" !important;
    cursor: pointer !important;
    position: absolute !important;
    height: 100% !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    border-top: 1px solid rgb(238, 238, 238) !important;
  }
`;

const ListItemAvatar = styled.div<ItemProps>`
  height: 44px !important;

  img {
    width: 40px !important;
    height: 40px !important;
    border: 3px solid
      ${(props) => (!props.isUnread ? "#f1f1f1" : props.theme.accentColor)} !important;
  }
`;

const ListItemMessage = styled.div`
  display: flex !important;
  -webkit-box-pack: justify !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  margin-left: 16px !important;
  width: 100% !important;
`;

const ListItemName = styled.div<ItemProps>`
  font-weight: 300 !important;
  color: ${(props) =>
    !props.isUnread ? "#555555" : props.theme.accentColor} !important;
  font-size: 14px !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
`;

const ListItemAbstract = styled.div<ItemProps>`
  font-size: 14px !important;
  white-space: nowrap !important;
  font-weight: 300 !important;
  text-overflow: ellipsis !important;
  color: ${(props) =>
    !props.isUnread ? "#282828" : props.theme.accentColor} !important;
  overflow: hidden !important;
  max-width: 70% !important;
`;

const ListItemTyping = styled.div`
  font-size: 14px !important;
  white-space: nowrap !important;
  font-weight: 300 !important;
  text-overflow: ellipsis !important;
  color: ${(props) => props.theme.accentColor} !important;
  overflow: hidden !important;
  max-width: 70% !important;

  svg {
    display: inline-block !important;
  }
`;

const Team = styled.div`
  img {
    margin-right: -24px !important;
    border: 4px solid #ffffff66 !important;
  }
`;

const WelcomeMessage = styled.div``;

const NewConversationContainer = styled.div`
  margin-top: 16px !important;
`;

export {
  ListContainer,
  ListItem,
  ListItemAbstract,
  ListItemTyping,
  ListItemAvatar,
  ListItemMessage,
  ListItemName,
  Team,
  WelcomeMessage,
  NewConversationContainer,
};
