import styled from "styled-components";
import { darkColor } from "utils/color";

const Article = styled.div`
  padding: 16px !important;
`;

const Title = styled.h1`
  line-height: 20px !important;
  color: #282828 !important;
  text-decoration: none !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  text-align: left !important;
`;

const TitleLink = styled.a`
  color: #282828 !important;
  text-decoration: none !important;
  margin-bottom: 15px !important;

  &:hover {
    color: ${(props) => props.theme.accentColor} !important;
  }
`;

const Body = styled.div`
  * {
    color: ${darkColor} !important;
    display: inline-block !important;
  }

  u {
    text-decoration: underline !important;
  }

  em,
  i {
    font-style: italic !important;
  }

  b,
  strong {
    font-weight: bold !important;
  }

  img,
  iframe {
    max-width: 100% !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-top: 0.7em !important;
    margin-bottom: 0.3em !important;
  }

  p {
    margin: 0.5em 0 !important;
  }

  h1 {
    font-size: 1.8em !important;
    line-height: 1.2em !important;
  }

  h2 {
    font-size: 1.5em !important;
  }

  h3 {
    font-size: 1.17em !important;
  }

  h4 {
    font-size: 1em !important;
  }

  h5 {
    font-size: 0.83em !important;
  }

  a {
    color: ${(props) => props.theme.accentColor} !important;
    display: inline-block !important;

    &:hover {
      color: ${darkColor} !important;
    }

    * {
      cursor: pointer !important;
    }
  }

  ul {
    margin-block-start: 1em !important;
    margin-block-end: 1em !important;
    margin-inline-start: 0 !important;
    margin-inline-end: 0 !important;
    padding-inline-start: 40px !important;

    li {
      display: list-item !important;
      list-style-type: disc !important;
    }
  }

  .ratcode {
    margin: 20px 0 !important;
    padding: 20px !important;
    background: #333d54 !important;
    color: #d8d8d8 !important;
  }
`;

const Metadata = styled.div`
  align-items: center !important;
  display: flex !important;
  margin: 16px 0 !important;
  justify-content: center !important;
`;

const AvatarCreator = styled.div`
  height: 25px !important;

  img {
    height: 25px !important;
    width: 25px !important;
  }
`;

const NameCreator = styled.div`
  margin-left: 8px !important;
  color: #a7a7a7 !important;
`;

const Feedback = styled.div`
  background: #f1f1f1 !important;
  border-radius: 8px !important;
  padding: 8px 16px !important;
  text-align: center !important;
`;

const FeedbackButton = styled.div`
  background: none !important;
  border: none !important;
  height: 50px !important;
  width: 50px !important;
  font-size: 30px !important;
  cursor: pointer !important;
  -webkit-transition: -webkit-transform 0.16s
      cubic-bezier(0.65, 0.61, 0.18, 1.8) 0.02s,
    -webkit-filter 0.32s linear !important;
  transition: -webkit-transform 0.16s cubic-bezier(0.65, 0.61, 0.18, 1.8) 0.02s,
    -webkit-filter 0.32s linear !important;
  transition: transform 0.16s cubic-bezier(0.65, 0.61, 0.18, 1.8) 0.02s,
    filter 0.32s linear !important;

  &:hover {
    -webkit-transform: scale(1.2) !important;
    transform: scale(1.2) !important;
    -webkit-transition: -webkit-transform 0.04s !important;
    transition: -webkit-transform 0.04s !important;
    transition: transform 0.04s !important;
  }
`;

export {
  Article,
  Title,
  TitleLink,
  Body,
  Metadata,
  AvatarCreator,
  NameCreator,
  Feedback,
  FeedbackButton,
};
