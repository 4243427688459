import io from "socket.io-client";
import { fromEvent, Observable, Subscription } from "rxjs";
import {
  SocketAttribute,
  SocketMessage,
  SocketRealtimeAccept,
  SocketRealtimeCall,
  SocketRealtimeReject,
  SocketRealtimeRinging,
  SocketSeen,
  SocketTopic,
  SocketTyping,
} from "app-types/socket";
import { utcNow } from "utils/date";

export class SocketService {
  private socket: SocketIOClient.Socket = {} as SocketIOClient.Socket;

  private initialized: boolean = false;

  private token: string = "";

  public init(url: string, port: number, token: string): SocketService {
    if (token === this.token) {
      return this;
    }

    this.socket = io(`${url}:${port}`, {
      secure: true,
      transports: ["websocket", "polling"],
      forceNew: true,
      reconnectionDelay: 15000,
      reconnectionDelayMax: 60000,
      reconnectionAttempts: 5,
      randomizationFactor: 0.5,
      timeout: 25e3,
      query: `token=${token}`,
    });
    this.token = token;

    this.initialized = true;

    return this;
  }

  public typing(
    conversationId: number,
    userId: number,
    preview: string,
    isTyping: boolean
  ): void {
    this.send(SocketTopic.Typing, {
      conversation: {
        conversation_id: conversationId,
        user_id: userId,
        is_note: false,
      },
      is_typing: isTyping ? "y" : "n",
      typing_preview: isTyping ? preview : "",
    } as SocketTyping);
  }

  public seen(
    conversationId: number,
    conversationMessageId: number,
    userId: number,
    timestamp?: number
  ): void {
    if (timestamp == null) {
      timestamp = utcNow();
    }

    this.send(SocketTopic.Seen, {
      conversation: {
        conversation_message_id: conversationMessageId,
        conversation_id: conversationId,
        user_id: userId,
      },
      seen_date: timestamp,
    } as SocketSeen);
  }

  public message(userId: number, message: string, timestamp?: number): void {
    if (timestamp == null) {
      timestamp = utcNow();
    }

    this.send(SocketTopic.Message, {
      timestamp: timestamp,
      user_id: userId,
      message: message,
      conversation: {
        is_note: false,
      },
    } as SocketMessage);
  }

  public attribute(userId: number, name: string, value: string): void {
    this.send(SocketTopic.Attribute, {
      user_id: userId,
      name: name,
      value: value,
    } as SocketAttribute);
  }

  public realtimeRinging(call: SocketRealtimeCall): void {
    this.send(SocketTopic.RealtimeRinging, call as SocketRealtimeRinging);
  }

  public realtimeReject(call: SocketRealtimeCall): void {
    this.send(SocketTopic.RealtimeReject, call as SocketRealtimeReject);
  }

  public realtimeAccept(call: SocketRealtimeCall): void {
    this.send(SocketTopic.RealtimeAccept, call as SocketRealtimeAccept);
  }

  public onTyping(closure: any): Subscription {
    const observable: Observable<SocketTyping> = this.onEvent(
      SocketTopic.Typing
    );

    return observable.subscribe(closure);
  }

  public onMessage(closure: any): Subscription {
    const observable: Observable<SocketMessage> = this.onEvent(
      SocketTopic.Message
    );

    return observable.subscribe(closure);
  }

  public onRealtimeCancel(closure: any): Subscription {
    const observable: Observable<SocketMessage> = this.onEvent(
      SocketTopic.RealtimeCancel
    );

    return observable.subscribe(closure);
  }

  public onRealtimeCall(closure: any): Subscription {
    const observable: Observable<SocketMessage> = this.onEvent(
      SocketTopic.RealtimeCall
    );

    return observable.subscribe(closure);
  }

  public onConnect(closure: any): Subscription {
    const observable: Observable<SocketMessage> = this.onEvent(
      SocketTopic.Connect
    );

    return observable.subscribe(closure);
  }

  public onEvent(topic: SocketTopic): Observable<any> {
    return fromEvent(this.socket, topic);
  }

  public send(topic: SocketTopic, payload: Object): void {
    this.socket.emit(topic, payload);
  }

  public isInitialized(): boolean {
    return this.initialized;
  }

  public isConnected(): boolean {
    return !!this.socket.connected;
  }

  public disconnect(): void {
    this.socket.disconnect();
  }
}
