const getDomain = (): string => {
  return window.location.hostname;
};

const getRootDomain = (domain: string): string => {
  const splitDomain = domain.split("."),
    domainLen = splitDomain.length;

  //if there is a subdomain
  if (domainLen > 2) {
    domain = splitDomain[domainLen - 2] + "." + splitDomain[domainLen - 1];

    //check to see if it's using a .uk.com
    if (
      splitDomain[domainLen - 1] === "com" &&
      splitDomain[domainLen - 2] === "uk"
    ) {
      domain = splitDomain[domainLen - 3] + "." + domain;
    }

    //check to see if it's using a Country Code Top Level Domain (ccTLD) (i.e. ".me.uk")
    if (
      (splitDomain[domainLen - 2].length === 2 ||
        splitDomain[domainLen - 2].length === 3) &&
      splitDomain[domainLen - 1].length === 2
    ) {
      //this is using a ccTLD
      domain = splitDomain[domainLen - 3] + "." + domain;
    }
  }
  return domain;
};

export { getDomain, getRootDomain };
