import UAParser from "ua-parser-js";

const isMobile = (): boolean => {
  const uaParser = new UAParser(navigator.userAgent);
  const devices = ["mobile", "tablet", "wearable", "embedded"];
  const deviceType = uaParser.getDevice().type;
  return !!(deviceType && devices.includes(deviceType));
};

const isDesktop = (): boolean => !isMobile();

export { isMobile, isDesktop };
