import styled from "styled-components";
import { ContainerModal } from "../index.style";

interface EmojiContainerProps {
  textareaHeight: number;
}

const EmojiContainer = styled(ContainerModal)<EmojiContainerProps>`
  margin-bottom: ${(props) => props.textareaHeight}px !important;
  z-index: 10 !important;
`;

const EmojiInnerContainer = styled.div`
  padding: 16px !important;
  height: 130px !important;
  overflow: auto !important;
`;

const EmojiCategory = styled.div`
  color: #b7b7b7 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
`;

const EmojiElement = styled.span`
  width: 23px !important;
  line-height: 1.07 !important;
  display: inline-table !important;
  text-align: justify !important;
  cursor: pointer !important;
  vertical-align: middle !important;
  font-size: 23px !important;
  font-family: "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji,
    "Segoe UI Symbol", "Android Emoji", EmojiSymbols, sans-serif !important;
  padding: 6px !important;
  transition: transform 100ms ease-out 60ms !important;

  &:hover {
    transform: scale(1.5) !important;
    transition: transform 100ms ease-out 60ms !important;
  }
`;

export { EmojiContainer, EmojiInnerContainer, EmojiCategory, EmojiElement };
