import React, { FunctionComponent, useEffect, useState } from "react";
import { NavigatorLogic } from "logic";
import { SonicDispatchProp } from "sonic";
import { Widget } from "app-types/widget";
import { ApplicationState } from "state";
import { connect, useSelector } from "react-redux";
import { Ping } from "app-types/ping";
import If from "components/If";
import Icon, { IconType } from "components/Icon";
import { Close, CloseContainer } from "../index.style";
import {
  Author,
  AuthorName,
  AvatarContainer,
  AvatarWrapper,
  Bubble,
  BubbleContainer,
  BubbleList,
  Message as MessageDiv,
  Reply,
  ReplyContainer,
} from "../MessageNotification/index.style";
import { markAsRead, shouldShow } from "utils/welcome-message";

import { useTranslation } from "react-i18next";
import { isUserSelector } from "selectors/user";
import { WelcomeMessageNotification as WelcomeMessageNotificationDiv } from "./index.style";
import Avatar from "components/Avatar";
import Loop from "components/Loop";
import { AudioType, play } from "utils/audio";

interface OwnProps {}

interface StateProps {
  ping: Ping;
  widget: Widget;
}

type Props = StateProps & OwnProps & SonicDispatchProp;

const WelcomeMessageNotification: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const isUser = useSelector(isUserSelector);
  const [hidden, setHidden] = useState(false);

  const onReply = async () => {
    setHidden(true);

    props.dispatch(NavigatorLogic.goToConversationCompose(undefined));
  };

  const getWelcomeMessage = () => {
    const message = isUser
      ? props.ping.app_config.welcome_message_users
      : props.ping.app_config.welcome_message_visitors;

    return !!message ? message : "";
  };

  const isWelcomeMessageAvailable = () => {
    return getWelcomeMessage() !== "";
  };

  useEffect(() => {
    if (shouldShow(isUser)) {
      if (isWelcomeMessageAvailable()) {
        play(AudioType.Receive);
      }
      markAsRead(isUser);
    }
  });

  return (
    <If
      condition={isWelcomeMessageAvailable() && shouldShow(isUser) && !hidden}
    >
      <WelcomeMessageNotificationDiv>
        <CloseContainer>
          <Close onClick={() => setHidden(true)}>
            <Icon type={IconType.CloseFilled} fill="#000" size={20} />
            <span>{t("Widget.Notification.MessageNotification.close")}</span>
          </Close>
        </CloseContainer>

        <BubbleList>
          <BubbleContainer>
            <AvatarContainer>
              <Loop items={props.ping.active_accounts.slice(0, 3)}>
                {(item, index) => (
                  <AvatarWrapper key={index} counter={index}>
                    <Avatar size={40} accountId={item.account_id} />
                  </AvatarWrapper>
                )}
              </Loop>
            </AvatarContainer>
            <Bubble>
              <Author>
                {t("Widget.Notification.MessageNotification.messageFrom")}
                <AuthorName>{props.ping.app.name}</AuthorName>
              </Author>
              <MessageDiv
                dangerouslySetInnerHTML={{ __html: getWelcomeMessage() }}
              />
            </Bubble>
          </BubbleContainer>
        </BubbleList>

        <ReplyContainer>
          <Reply onClick={onReply}>
            {t("Widget.Notification.MessageNotification.replyTo", {
              name: props.ping.app.name,
            })}
          </Reply>
        </ReplyContainer>
      </WelcomeMessageNotificationDiv>
    </If>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  widget: state.widget,
  ping: state.ping,
});

export default connect(mapStateToProps)(WelcomeMessageNotification);
