import ApiClient from "./api-client";
import { Conversation, jsonToConversation } from "app-types/conversation";
import { getCookie } from "utils/cookie";

class ConversationApi {
  constructor(private apiClient: ApiClient) {}

  /** Returns all the conversations */
  public getAll = async (): Promise<Conversation[]> => {
    const response = await this.apiClient.post("/conversations/retrieve", {
      lead_hash: getCookie("customerly_lead_hash"),
    });

    return response.map((conversation: any) =>
      jsonToConversation(conversation)
    );
  };

  public discard = async (conversationIds: number[]): Promise<void> => {
    await this.apiClient.post("/conversations/discard", {
      conversation_ids: conversationIds,
    });
  };
}

export default ConversationApi;
