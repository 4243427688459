import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { connect, useSelector } from "react-redux";
import { Widget } from "app-types/widget";
import { ApplicationState } from "state";
import Spinner from "components/Spinner";
import If from "components/If";
import Loop from "components/Loop";
import { SonicDispatchProp } from "sonic";
import { Ping } from "app-types/ping";
import { ConversationLogic, NavigatorLogic } from "logic";
import { isEmpty } from "utils/object";
import Avatar from "components/Avatar";
import Icon, { IconType } from "components/Icon";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardButton,
  CardButtonIcon,
  CardButtonTitle,
  CardHeader,
  CardTitle,
  Link,
} from "../index.style";
import {
  ListContainer,
  ListItem,
  ListItemAbstract,
  ListItemAvatar,
  ListItemMessage,
  ListItemName,
  ListItemTyping,
  NewConversationContainer,
  Team,
  WelcomeMessage,
} from "./index.style";
import { isUserSelector, isVisitorSelector } from "selectors/user";
import { Subscription } from "rxjs";
import { SocketContext } from "components/Socket/SocketContext";
import { SocketMessage, SocketTyping } from "app-types/socket";
import { AudioType, play } from "utils/audio";
import { Typing } from "app-types/message";

interface OwnProps {}

interface StateProps {
  widget: Widget;
  ping: Ping;
}

type Props = StateProps & OwnProps & SonicDispatchProp;

const Conversation: FunctionComponent<Props> = (props) => {
  let socketSubscriptions: Subscription[] = [];
  const socket = useContext(SocketContext);

  const maxConversations = 2;
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const isUser = useSelector(isUserSelector);
  const isVisitor = useSelector(isVisitorSelector);
  const [typingOnConversation, setTypingOnConversation] = useState<
    Typing | undefined
  >(undefined);

  const onSocketMessage = (socketMessage: SocketMessage) => {
    play(AudioType.Receive);

    props.dispatch(ConversationLogic.getAll());
  };

  const onSocketTyping = (socketTyping: SocketTyping) => {
    if (socketTyping.client) {
      const isAccount = !!socketTyping.client;
      const isAccountTyping = socketTyping.is_typing === "y";

      if (!isAccount) {
        return;
      }

      if (isAccountTyping) {
        setTypingOnConversation({
          conversationId: socketTyping.conversation.conversation_id,
          account: {
            accountId: socketTyping.client.account_id,
            name: socketTyping.client.name,
          },
        });
      } else {
        setTypingOnConversation(undefined);
      }
    }
  };

  const onLoad = () => {
    const fetchConversations = async () => {
      if (!isVisitor) {
        socketSubscriptions.push(socket.onMessage(onSocketMessage));
        socketSubscriptions.push(socket.onTyping(onSocketTyping));
      }

      setIsLoading(true);
      await props.dispatch(ConversationLogic.getAll());

      setIsLoading(false);
    };

    fetchConversations().catch(console.error);
  };

  const onUnload = () => {
    socketSubscriptions.forEach((subscription) => subscription.unsubscribe());
  };

  useEffect(() => {
    onLoad();

    return onUnload;
    // eslint-disable-next-line
  }, []);

  return (
    <Card>
      <Spinner isLoading={isLoading}>
        <CardHeader>
          <CardTitle>
            {t("Widget.Messenger.Home.Conversation.header.yourConversations")}
          </CardTitle>
          <If
            condition={
              props.widget.cards.conversation.conversations.length >
              maxConversations
            }
          >
            <Link
              as="button"
              onClick={() =>
                props.dispatch(NavigatorLogic.goToConversationList())
              }
            >
              {t("Widget.Messenger.Home.Conversation.header.seeAll")}
            </Link>
          </If>
        </CardHeader>

        <If
          condition={props.widget.cards.conversation.conversations.length > 0}
        >
          <ListContainer>
            <Loop
              items={props.widget.cards.conversation.conversations.slice(
                0,
                maxConversations
              )}
            >
              {(item) => (
                <ListItem
                  key={item.conversation_id}
                  onClick={() =>
                    props.dispatch(NavigatorLogic.goToConversationCompose(item))
                  }
                >
                  <ListItemAvatar isUnread={item.unread}>
                    {!isEmpty(item.last_account || {}) ? (
                      <Avatar
                        accountId={
                          item.last_account && item.last_account.account_id
                        }
                        size={50}
                      />
                    ) : (
                      <Avatar userId={item.user_id} size={50} />
                    )}
                  </ListItemAvatar>

                  <ListItemMessage>
                    <ListItemName isUnread={item.unread}>
                      {!isEmpty(item.last_account || {})
                        ? item.last_account && item.last_account.name
                        : t("Widget.Messenger.Home.Conversation.body.You")}
                    </ListItemName>
                    {(!typingOnConversation ||
                      (typingOnConversation &&
                        typingOnConversation.conversationId !==
                          item.conversation_id)) && (
                      <ListItemAbstract
                        isUnread={item.unread}
                        dangerouslySetInnerHTML={{
                          __html: item.last_message_abstract,
                        }}
                      />
                    )}
                    {!!typingOnConversation &&
                      typingOnConversation.conversationId ===
                        item.conversation_id && (
                        <ListItemTyping>
                          <Icon
                            type={IconType.Typing}
                            fill={props.widget.accentColor}
                            size={15}
                          />
                          {t(
                            "Widget.Notification.MessageNotification.isTyping",
                            {
                              name:
                                typingOnConversation &&
                                typingOnConversation.account.name,
                            }
                          )}
                        </ListItemTyping>
                      )}
                  </ListItemMessage>
                </ListItem>
              )}
            </Loop>
          </ListContainer>
        </If>

        <If
          condition={props.widget.cards.conversation.conversations.length === 0}
        >
          <Team>
            <Loop items={props.ping.active_accounts}>
              {(item, index) => (
                <Avatar key={index} accountId={item.account_id} size={50} />
              )}
            </Loop>

            <WelcomeMessage>
              <If condition={isUser}>
                {props.ping.app_config.welcome_message_users}
              </If>
              <If condition={!isUser}>
                {props.ping.app_config.welcome_message_visitors}
              </If>
            </WelcomeMessage>
          </Team>
        </If>

        <If
          condition={
            !props.widget.singleConversation ||
            (props.widget.singleConversation &&
              props.widget.cards.conversation.conversations.length === 0)
          }
        >
          <NewConversationContainer>
            <CardButton
              type="button"
              onClick={() =>
                props.dispatch(
                  NavigatorLogic.goToConversationCompose(undefined)
                )
              }
            >
              <CardButtonIcon>
                <Icon type={IconType.New} size={24} fill="#fff" />
              </CardButtonIcon>
              <CardButtonTitle>
                {t("Widget.Messenger.Home.Conversation.footer.startNew")}
              </CardButtonTitle>
            </CardButton>
          </NewConversationContainer>
        </If>
      </Spinner>
    </Card>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  widget: state.widget,
  ping: state.ping,
});

export default connect(mapStateToProps)(Conversation);
