import React from "react";
import ReactDOM from "react-dom";
import Customerly from "./Customerly";
import { Cli } from "./logic/cli";

ReactDOM.render(
  <Customerly />,
  document.getElementById("customerly-container")
);

window.customerly = new Cli();
