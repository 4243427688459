import ApiClient from "./api-client";
import ConversationApi from "./conversation-api";
import DebugApi from "./debug-api";
import EventApi from "./event-api";
import GifApi from "./gif-api";
import KnowledgeApi from "./knowledge-api";
import MessageApi from "./message-api";
import PingApi from "./ping-api";
import ProfilingApi from "./profiling-api";
import SurveyApi from "./survey-api";

const apiClient = new ApiClient();

export default {
  api: {
    conversation: new ConversationApi(apiClient),
    debug: new DebugApi(apiClient),
    event: new EventApi(apiClient),
    gif: new GifApi(apiClient),
    knowledge: new KnowledgeApi(apiClient),
    message: new MessageApi(apiClient),
    ping: new PingApi(apiClient),
    profiling: new ProfilingApi(apiClient),
    survey: new SurveyApi(apiClient),
  },
};
