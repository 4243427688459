import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { connect, useSelector } from "react-redux";
import { Widget } from "app-types/widget";
import { ApplicationState } from "state";
import { SonicDispatchProp } from "sonic";
import MessageNotification from "./MessageNotification";
import { Message } from "app-types/message";
import If from "components/If";
import { Survey } from "app-types/survey";
import SurveyNotification from "./SurveyNotification";
import WelcomeMessageNotification from "./WelcomeMessageNotification";
import { SocketMessage } from "app-types/socket";
import { AudioType, play } from "utils/audio";
import { MessageLogic } from "logic";
import { SocketContext } from "components/Socket/SocketContext";
import { Subscription } from "rxjs";
import { NewsPayload } from "logic/messenger/message";

interface OwnProps {}

interface StateProps {
  widget: Widget;
}

type Props = StateProps & OwnProps & SonicDispatchProp;

const Notification: FunctionComponent<Props> = (props) => {
  let socketSubscriptions: Subscription[] = [];
  const socket = useContext(SocketContext);
  const [shouldTrigger, setShouldTrigger] = useState(false);

  const selectUnreadMessages = useSelector<ApplicationState, Message[]>(
    (state) => state.widget.notifications.messages
  );
  const selectUnreadSurveys = useSelector<ApplicationState, Survey[]>(
    (state) => state.ping.unread.surveys
  );
  const showMessageNotification = selectUnreadMessages.length !== 0;
  const showSurveyNotification = selectUnreadSurveys.length > 0;

  const onSocketMessage = (socketMessage: SocketMessage) => {
    if (
      socketMessage.account_id !== undefined && // An account wrote.
      (!props.widget.cards.conversation.selected ||
        socketMessage.conversation.conversation_id !==
          props.widget.cards.conversation.selected.conversation_id) // Same conversation
    ) {
      play(AudioType.Receive);

      props.dispatch(
        MessageLogic.getNewsUnread({
          conversationId: socketMessage.conversation.conversation_id,
          timestamp: socketMessage.timestamp,
        } as NewsPayload)
      );
    }
  };

  const onLoad = () => {
    const subscribe = async () => {
      if (!shouldTrigger) {
        setTimeout(() => setShouldTrigger(true), 1500);
      }

      if (socket.isConnected()) {
        socketSubscriptions.push(socket.onMessage(onSocketMessage));
      } else {
        socket.isInitialized() &&
          socketSubscriptions.push(
            socket.onConnect(() => {
              socketSubscriptions.push(socket.onMessage(onSocketMessage));
            })
          );
      }
    };

    subscribe().catch(console.error);
  };

  const onUnload = () => {
    socketSubscriptions.forEach((subscription) => subscription.unsubscribe());
  };

  useEffect(() => {
    onLoad();

    return onUnload;
    // eslint-disable-next-line
  }, [socket.isInitialized()]);

  return (
    <If condition={!props.widget.open}>
      {showMessageNotification && shouldTrigger && (
        <MessageNotification messages={selectUnreadMessages} />
      )}
      {!showMessageNotification && showSurveyNotification && shouldTrigger && (
        <SurveyNotification survey={selectUnreadSurveys[0]} />
      )}
      {!showMessageNotification && !showSurveyNotification && shouldTrigger && (
        <WelcomeMessageNotification />
      )}
    </If>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  widget: state.widget,
});

export default connect(mapStateToProps)(Notification);
