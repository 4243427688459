import React, { FunctionComponent, useEffect } from "react";
import CSSTransition from "react-transition-group/CSSTransition";
import { Alert as AlertDiv, Wrapper } from "./index.style";
import { fadeInDownAnimationName } from "ui/Widget/animation.style";

/** The props of the `Alert` component */
export interface AlertProps {
  showAlert: boolean;
  setShowAlert: Function;
  visibleFor?: number;
}

/**
 * Simple alert component that allows to show a popup for `visibleFor` milliseconds.
 */
const Alert: FunctionComponent<AlertProps> = (props) => {
  const visibleFor = props.visibleFor !== undefined ? props.visibleFor : 3000;

  useEffect(() => {
    if (props.showAlert) {
      setTimeout(() => props.setShowAlert(false), visibleFor);
    }
    // eslint-disable-next-line
  }, [props.showAlert]);

  return (
    <CSSTransition
      in={props.showAlert}
      timeout={0}
      classNames={fadeInDownAnimationName}
      appear
      unmountOnExit
    >
      <Wrapper>
        <AlertDiv>{props.children}</AlertDiv>
      </Wrapper>
    </CSSTransition>
  );
};

export default Alert;
