import React, { FunctionComponent } from "react";
import { Provider } from "react-redux";

import { store } from "./store";
import Widget from "./ui/Widget";

const Customerly: FunctionComponent = (props) => {
  return (
    <Provider store={store}>
      <Widget />
    </Provider>
  );
};

export default Customerly;
