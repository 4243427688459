import styled from "styled-components";
import { opacitizer } from "utils/color";

const normalLightColor = "#e8e8e8";
const normalDarkColor = "#868d90";
const toRGBA = (color: string, amount: number): string => {
  return `rgba(${opacitizer(color, amount)})`;
};

const SurveySelectElement = styled.select`
  font-size: 16px !important;
  font-weight: 600 !important;
  background-color: #ffffff !important;
  border-radius: 8px !important;
  color: ${normalDarkColor} !important;
  -webkit-box-pack: center !important;
  -webkit-justify-content: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  text-align: center !important;
  padding: 16px !important;
  cursor: pointer !important;
  border: 1px solid ${normalLightColor} !important;

  &:hover {
    box-shadow: 0 1px 6px 0 ${(props) => toRGBA(props.theme.accentColor, 10)} !important;
    border: 1px solid ${(props) => toRGBA(props.theme.accentColor, 30)} !important;
    transition: background 100ms ease-in-out, border 100ms ease-in-out,
      box-shadow 100ms ease-in-out, color 100ms ease-in-out !important;
  }
`;

export { SurveySelectElement };
