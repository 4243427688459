import { LocalTrigger } from "app-types/trigger";
import { getItem, setItem } from "./local-storage";
import { LOCALSTORAGE_TRIGGERS } from "constants/local-storage";

const setLocalTrigger = (
  trigger_id: number,
  localTrigger: LocalTrigger
): void => {
  setItem(`${LOCALSTORAGE_TRIGGERS}/${trigger_id}`, localTrigger);
};

const getLocalTrigger = (trigger_id: number): LocalTrigger => {
  return getItem(`${LOCALSTORAGE_TRIGGERS}/${trigger_id}`) as LocalTrigger;
};

const setTriggerConversationId = (
  trigger_id: number,
  conversationId: number
): void => {
  const localTrigger = getLocalTrigger(trigger_id);
  localTrigger.conversation_id = conversationId;
  setLocalTrigger(trigger_id, localTrigger);
};

export { setLocalTrigger, getLocalTrigger, setTriggerConversationId };
