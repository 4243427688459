import { createSideEffect, createStateUpdater } from "sonic";
import AppTypes from "AppTypes";
import { Message } from "app-types/message";
import { Conversation } from "app-types/conversation";
import { ApplicationState } from "state";
import { isEmpty, unique } from "utils/object";
import { ConversationLogic, WidgetLogic } from "../index";
import { SendResponse } from "dependencies/message-api";
import {
  getFakeConversation,
  getTriggerIdFromTriggerFakeConversation,
  removeFakeConversation,
} from "utils/local-storage/fake-message-local-storage";
import { setTriggerConversationId } from "utils/local-storage/trigger-local-storage";
import { seenFakeConversation } from "utils/fake-message";
import { removeUnreadMessages } from "utils/conversation";
import { DEFAULT_MESSAGE_PER_PAGE } from "constants/messenger/compose";

export interface NewsPayload {
  conversationId?: number;
  timestamp: number;
}

export interface AttachmentPayload {
  base64: string;
  filename: string;
  filesize: number;
}

export interface SendPayload {
  conversationId?: number;
  message: string;
  attachments: AttachmentPayload[];
  fakeConversation?: Conversation;
}

export interface SendFakeConversationPayload {
  conversation: Conversation;
}

export interface SeenPayload {
  conversationId: number;
  conversationMessageId: number;
  timestamp: number;
}

export interface GetAllPayload {
  conversationId: number;
  messagesBeforeId?: number;
}

const setMessagesStateUpdater = createStateUpdater(
  "messages/set",
  (state: AppTypes.AppState, messages: Message[]) => {
    return {
      ...state,
      widget: {
        ...state.widget,
        cards: {
          ...state.widget.cards,
          conversation: {
            ...state.widget.cards.conversation,
            selected: {
              ...(state.widget.cards.conversation.selected as Conversation),
              messages: messages as Message[],
            },
          },
        },
      },
    };
  }
);

const setHasMoreMessagesStateUpdater = createStateUpdater(
  "messages/has-more-messages",
  (state: AppTypes.AppState, hasMoreMessages: boolean) => {
    return {
      ...state,
      widget: {
        ...state.widget,
        cards: {
          ...state.widget.cards,
          conversation: {
            ...state.widget.cards.conversation,
            selected: {
              ...(state.widget.cards.conversation.selected as Conversation),
              hasMoreMessages: hasMoreMessages,
            },
          },
        },
      },
    };
  }
);

const setUnreadMessagesStateUpdater = createStateUpdater(
  "widget/ping/unread/messages",
  (state: ApplicationState, messages: Message[]) => {
    return {
      ...state,
      ping: {
        ...state.ping,
        unread: {
          ...state.ping.unread,
          messages: messages,
        },
      },
    };
  }
);

const getAll: AppTypes.AppSideEffectCreator<
  GetAllPayload,
  void
> = createSideEffect(
  "messages/getAll",
  async (context, getAllPayload: GetAllPayload) => {
    try {
      const isBackendConversation = getAllPayload.conversationId >= 0;
      const state = context.getState();

      if (isBackendConversation) {
        const messages = await context.dependencies.api.message.getAll(
          getAllPayload.conversationId,
          getAllPayload.messagesBeforeId,
          DEFAULT_MESSAGE_PER_PAGE
        );

        let allMessages: Message[];

        if (
          state.widget.cards.conversation.selected &&
          getAllPayload.messagesBeforeId &&
          getAllPayload.messagesBeforeId > 0
        ) {
          allMessages = state.widget.cards.conversation.selected.messages;
          allMessages = messages.concat(allMessages);
        } else {
          allMessages = messages;
        }

        await context.dispatch(setMessagesStateUpdater(allMessages));
        await context.dispatch(
          setHasMoreMessagesStateUpdater(
            messages.length === DEFAULT_MESSAGE_PER_PAGE
          )
        );
      } else {
        const fakeConversation = getFakeConversation(
          getAllPayload.conversationId
        );

        if (fakeConversation) {
          await context.dispatch(
            setMessagesStateUpdater(fakeConversation.messages)
          );
        }
      }
    } catch (error) {
      await context.dispatch(setMessagesStateUpdater(error));
    }
  }
);

const getNews: AppTypes.AppSideEffectCreator<
  NewsPayload,
  void
> = createSideEffect(
  "messages/getNews",
  async (context, newsPayload: NewsPayload) => {
    try {
      let newMessages: Message[] = await context.dependencies.api.message.getNews(
        newsPayload.conversationId,
        newsPayload.timestamp
      );
      let existingMessages: Message[] = [];
      const state = context.getState();

      if (state.widget.cards.conversation.selected) {
        existingMessages = state.widget.cards.conversation.selected.messages;
      }

      let computedMessages = existingMessages;
      for (let newMessage of newMessages) {
        if (
          isEmpty(
            computedMessages.filter(
              (message: Message) =>
                message.conversation_message_id ===
                newMessage.conversation_message_id
            )
          )
        ) {
          computedMessages.push(newMessage);
        }
      }

      await context.dispatch(setMessagesStateUpdater(computedMessages));
    } catch (error) {
      await context.dispatch(setMessagesStateUpdater(error));
    }
  }
);

const getNewsUnread: AppTypes.AppSideEffectCreator<
  NewsPayload,
  void
> = createSideEffect(
  "messages/getNewsUnread",
  async (context, newsPayload: NewsPayload) => {
    try {
      let newMessages: Message[] = await context.dependencies.api.message.getNews(
        newsPayload.conversationId,
        newsPayload.timestamp
      );
      const state = context.getState();

      let unreadExistingMessages: Message[] = state.ping.unread.messages;
      const unreadNewMessages = unreadExistingMessages.concat(newMessages);
      const uniqueUnreadNewMessages = unique(
        unreadNewMessages,
        (message: Message) => message.conversation_message_id
      );

      await context.dispatch(
        setUnreadMessagesStateUpdater(uniqueUnreadNewMessages)
      );

      const notificationsMessages = state.widget.notifications.messages;
      const notificationsNewMessages = notificationsMessages.concat(
        newMessages
      );
      const uniqueNotificationsNewMessages = unique(
        notificationsNewMessages,
        (message: Message) => message.conversation_message_id
      );

      await context.dispatch(
        WidgetLogic.setNotificationsMessagesStateUpdater(
          uniqueNotificationsNewMessages
        )
      );
    } catch (error) {
      await context.dispatch(setUnreadMessagesStateUpdater(error));
    }
  }
);

const send: AppTypes.AppSideEffectCreator<
  SendPayload,
  SendResponse | undefined
> = createSideEffect(
  "messages/send",
  async (context, sendPayload: SendPayload) => {
    try {
      const {
        conversation,
        message,
        timestamp,
      } = await context.dependencies.api.message.send(
        sendPayload.conversationId,
        sendPayload.message,
        sendPayload.attachments,
        sendPayload.fakeConversation
      );

      const isNewConversation = sendPayload.conversationId == null;
      const isFakeConversation =
        sendPayload.conversationId &&
        sendPayload.conversationId < 0 &&
        sendPayload.fakeConversation;

      if (isNewConversation || isFakeConversation) {
        if (isFakeConversation) {
          // @ts-ignore
          const trigger_id = getTriggerIdFromTriggerFakeConversation(
            sendPayload.conversationId
          );
          if (trigger_id) {
            setTriggerConversationId(trigger_id, conversation.conversation_id);
          }
          // @ts-ignore
          removeFakeConversation(sendPayload.conversationId);
        }

        const conversations: Conversation[] =
          context.getState().widget.cards.conversation.conversations || [];

        await context.dispatch(
          ConversationLogic.setConversationsStateUpdater(
            conversations.concat(conversation)
          )
        );
        await context.dispatch(
          ConversationLogic.setSelectedConversationStateUpdater(conversation)
        );
      }

      return { conversation, message, timestamp } as SendResponse;
    } catch (error) {}
  }
);

const seen: AppTypes.AppSideEffectCreator<SeenPayload, void> = createSideEffect(
  "messages/seen",
  async (context, seenPayload: SeenPayload) => {
    try {
      const unreadMessages = context.getState().ping.unread.messages;

      context.dispatch(
        setUnreadMessagesStateUpdater(
          removeUnreadMessages(
            unreadMessages,
            seenPayload.conversationId,
            seenPayload.conversationMessageId
          )
        )
      );

      if (seenPayload.conversationId < 0) {
        seenFakeConversation(
          seenPayload.conversationId,
          seenPayload.conversationMessageId,
          seenPayload.timestamp
        );
      } else {
        return await context.dependencies.api.message.seen(
          seenPayload.conversationId,
          seenPayload.conversationMessageId,
          seenPayload.timestamp
        );
      }
    } catch (error) {}
  }
);

export default {
  setUnreadMessagesStateUpdater,
  setMessagesStateUpdater,
  getAll,
  getNews,
  getNewsUnread,
  send,
  seen,
};
