import React, { FunctionComponent } from "react";
import Knowledge from "../../../../Knowledge";
import { KnowledgeMessage } from "../index.style";
import { Ping } from "app-types/ping";
import { ApplicationState } from "state";
import { connect } from "react-redux";
import If from "components/If";

interface OwnProps {
  isAccount: boolean;
  onSelectedArticle: Function;
  onShowModal: Function;
}

interface StateProps {
  ping: Ping;
}

type Props = StateProps & OwnProps;

const KnowledgeBubble: FunctionComponent<Props> = (props) => {
  return (
    <React.Fragment>
      <If
        condition={
          props.ping.app_config.widget_knowledge_base_enabled &&
          !!props.ping.knowledge.url
        }
      >
        <KnowledgeMessage>
          <Knowledge
            setSelectedArticle={props.onSelectedArticle}
            setShowModal={props.onShowModal}
          />
        </KnowledgeMessage>
      </If>
    </React.Fragment>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => ({
  ping: state.ping,
});

export default connect(mapStateToProps)(KnowledgeBubble);
