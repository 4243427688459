import styled from "styled-components";
import { HeaderButton } from "../../../index.style";

const Header = styled.div`
  position: relative !important;
  padding: 16px !important;
`;

const Title = styled.h1`
  color: #ffffff !important;
  margin: 0 !important;
  padding: 0 48px 8px 0 !important;
  word-break: break-word !important;
`;

const Description = styled.p`
  color: #ffffff !important;
  margin: 0 !important;
`;

const Close = styled(HeaderButton)`
  text-align: center !important;
  cursor: pointer !important;
  position: absolute !important;
  top: 8px !important;
  right: 8px !important;

  svg {
    margin: 2px auto !important;
    width: 28px !important;
    height: 28px !important;
  }
`;

export { Header, Title, Description, Close };
