import styled from "styled-components";

const ScrollToBottomContainer = styled.div`
  height: calc(100% - 20px) !important;
  padding: 10px !important;
  overflow: auto !important;
  position: relative !important;
`;

export { ScrollToBottomContainer };
